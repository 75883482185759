export const initState = {
  errorMessage: '',
  isLoading: false,
  byPlace: [],
  byUser: [],
};

const reviewReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'REVIEWS_BY_PLACE_IS_LOADING':
    case 'REVIEWS_BY_PLACE_SUCCESS':
    case 'REVIEWS_BY_PLACE_FAILURE':
      return {
        ...state,
        ...payload,
      };
    case 'REVIEWS_BY_USER_IS_LOADING':
    case 'REVIEWS_BY_USER_SUCCESS':
    case 'REVIEWS_BY_USER_FAILURE':
      return {
        ...state,
        ...payload,
      };
    case 'DELETE_REVIEW_IS_LOADING':
    case 'DELETE_REVIEW_SUCCESS':
    case 'DELETE_REVIEW_FAILURE':
      return {
        ...state,
        ...payload,
      };
    case 'UNDO_DELETE_REVIEW_IS_LOADING':
    case 'UNDO_DELETE_REVIEW_SUCCESS':
    case 'UNDO_DELETE_REVIEW_FAILURE':
      return {
        ...state,
        ...payload,
      };
    case 'RESET_REVIEWS_BY_PLACE':
      return {
        ...state,
        errorMessage: '',
        isLoading: false,
        byPlace: [],
      };
    default:
      return state;
  }
};

export default reviewReducer;

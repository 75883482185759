import { HttpService } from './http-base.service';

type ListPlacesArgs = {
  query?: string,
  category?: number,
  services?: string[],
  location?: string,
  page?: number,
  per_page?: number,
  sortBy: 'name' | 'category' | 'date',
  orderBy: 'ASC' | 'DESC',
};

type CreateBusinessArgs = {
  name: string,
  address: string,
  address2?: string,
  city?: string,
  province?: string,
  country?: string,
  postalCode?: string,
  phone?: string,
  url?: string,
  categoryId: number,
  services?: string[],
  otherService?: string,
};

export default class AccessFromHomeService extends HttpService {
  constructor(baseURL) {
    if (!AccessFromHomeService.instance) {
      super(baseURL);
      AccessFromHomeService.instance = this;
    }

    return AccessFromHomeService.instance;
  }

  getBusinesses(params: ListPlacesArgs) {
    const config = { params };

    return this.get('/access-from-home', config)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  addBusiness(params: CreateBusinessArgs) {
    const data = params;

    return this.post('/access-from-home', data)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  categories() {
    return this.get('/access-from-home/categories')
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  services() {
    return this.get('/access-from-home/services')
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TagButton from './TagButton';

const useStyles = makeStyles((theme) => ({
  customAccordion: {
    border: 'none',
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: '0',
    },
  },
  customAccordionSummary: {
    border: 'none',
    padding: 0,
    alignItems: 'center',
    borderBottom: 'none',
    borderTop: 'none',
  },
  categoryIcon: {
    marginRight: theme.spacing(1),
    color: '#424246',
  },
}));

const CustomAccordion = ({
  category,
  tags,
  selectedTags,
  updateSelectedTags,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const countSelectedTags = (categoryTags) => {
    const selectedCount = categoryTags.filter((tag) =>
      selectedTags.includes(tag.key)
    ).length;
    return selectedCount > 0 ? `+${selectedCount}` : '';
  };

  return (
    <Accordion className={classes.customAccordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.customAccordionSummary}
      >
        <img
          src={`/images/categories/${category}.svg`}
          alt={category}
          className={classes.categoryIcon}
        />
        <Typography variant="h6">{t(`categories.${category}`)}</Typography>
        {countSelectedTags(tags) && (
          <Typography
            variant="body2"
            style={{
              marginLeft: 'auto',
              marginRight: '8px',
              position: 'relative',
              top: '2px',
            }}
          >
            {countSelectedTags(tags)}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {tags.map((tag, j) => {
            const selected = selectedTags.includes(tag.key);
            return (
              <TagButton
                displayName={t(`tags.${tag.key}`)}
                onClick={() => updateSelectedTags(tag.key)}
                selected={selected}
                key={j}
              />
            );
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;

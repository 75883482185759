import { Button, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  tagRoot: {
    margin: theme.spacing(0.5),
    borderRadius: 20,
  },
}));

const TagButton = ({ displayName, selected, ...props }) => {
  const classes = useStyles();
  const variant = selected ? 'contained' : 'outlined';
  const color = selected ? 'primary' : 'default';
  return (
    <Button
      variant={variant}
      color={color}
      classes={{
        root: classes.tagRoot,
      }}
      size="medium"
      {...props}
    >
      {displayName}
    </Button>
  );
};

export default TagButton;

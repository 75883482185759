const initState = {
  searchQuery: '',
  searchResults: [],
  nextPageToken: null,
  location: {
    name: '',
    latitude: 5,
    longitude: -25,
  },
  country: null,
};

const searchReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'UPDATE_SEARCH_LOCATION':
    case 'RESET_SEARCH_LOCATION':
    case 'UPDATE_SEARCH_QUERY':
    case 'UPDATE_SEARCH_IS_LOADING':
    case 'UPDATE_SEARCH_SUCCESS':
    case 'UPDATE_SEARCH_FAILURE':
    case 'CLEAR_SEARCH':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default searchReducer;

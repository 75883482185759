import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 110,
  },
  img: {
    marginBottom: 10,
  },
  large: {
    width: 80,
  },
  medium: {
    width: 70,
  },
}));

const RatingButton = ({ rating, variant, title, selected, ...props }) => {
  const classes = useStyles();
  const img = `${rating + (selected ? '' : '-alt')}.svg`;
  return (
    <ButtonBase className={classes.container} disableTouchRipple {...props}>
      <img
        alt=""
        src={`/images/filterIcons/${img}`}
        className={clsx(classes.img, classes[variant])}
      />
      <Typography variant="h5" color="primary">
        {title}
      </Typography>
    </ButtonBase>
  );
};

export default RatingButton;

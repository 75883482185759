import config from '../../utils/config';

const initState = {
  themeMode: 'light',
  environment: config.environment,
  apiHost: config.apiHost,
};

const appReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_THEME':
      return {
        ...state,
        themeMode: payload,
      };
    default:
      return state;
  }
};

export default appReducer;

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Apple as AppleIcon, Facebook as FacebookIcon } from '../icons';
import { authActions } from '../store/actions';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  facebookIcon: {
    backgroundColor: theme.palette.facebook,
    color: theme.palette.white,
  },
  facebookIconDisabled: {
    backgroundColor: '#ccc',
  },
  facebook: {
    color: theme.palette.facebook,
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.facebook}`,
  },
  apple: {
    fontFamily: 'SF Pro Text',
    color: theme.palette.black,
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.black}`,
    marginBottom: theme.spacing(2),
  },
}));

const ThirdPartyButton = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { className, text, variant, fbAuth, appleAuth, disabled, ...other } =
    props;

  const handleClick = (event) => {
    event.preventDefault();

    switch (variant) {
      case 'facebook':
        dispatch(authActions.fbAuth());
        break;
      case 'apple':
        dispatch(authActions.appleAuth());
        break;
    }
  };

  return (
    <Button
      className={clsx(classes[variant], className)}
      size="large"
      variant="outlined"
      onClick={handleClick}
      disabled={disabled}
      {...other}
    >
      {{
        facebook: (
          <FacebookIcon
            className={clsx(
              classes.icon,
              classes.facebookIcon,
              disabled && classes.facebookIconDisabled
            )}
          />
        ),
        apple: <AppleIcon className={classes.icon} />,
      }[variant] || null}
      {text}
    </Button>
  );
};

ThirdPartyButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['facebook', 'apple']).isRequired,
};

export default ThirdPartyButton;

import { HttpService } from './http-base.service';

export default class FlagService extends HttpService {
  constructor(baseURL) {
    if (!FlagService.instance) {
      super(baseURL);
      FlagService.instance = this;
    }

    return FlagService.instance;
  }

  list() {
    return this.get('/api/flags')
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }
}

import { colors, primaryBlue, secondaryOrange } from './colors';

const white = '#FFFFFF';
const black = '#000000';
// custom one offs
const facebook = '#3B5998';

const accessible = '#28A745';
const notAccessible = '#B5000D';
const partiallyAccessible = '#EDB106';

export const lightPalette = {
  black,
  white,
  facebook,
  accessible,
  notAccessible,
  partiallyAccessible,
  primary: {
    contrastText: white,
    dark: primaryBlue[900],
    main: primaryBlue[600],
    light: primaryBlue[100],
    lightest: primaryBlue[50],
  },
  secondary: {
    contrastText: white,
    dark: secondaryOrange[800],
    main: secondaryOrange[500],
    light: secondaryOrange[100],
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
    lightest: colors.green[50],
  },
  info: {
    contrastText: white,
    dark: primaryBlue[900],
    main: primaryBlue[600],
    light: primaryBlue[400],
    lightest: primaryBlue[50],
  },
  warning: {
    contrastText: white,
    dark: secondaryOrange[900],
    main: secondaryOrange[600],
    light: secondaryOrange[400],
    lightest: secondaryOrange[50],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
    lightest: colors.red[50],
  },
  text: {
    primary: colors.grey[900],
    secondary: colors.grey[800],
    link: primaryBlue[600],
    navLink: colors.grey[600],
    pinText: colors.grey[900],
  },
  grey: {
    dark: colors.grey[900],
    main: colors.grey[600],
    light: colors.grey[400],
    lighter: colors.grey[300],
    lightest: colors.grey[50],
  },
  background: {
    default: '#F4F6F8',
    main: '#FFF',
    paper: white,
    hover: colors.grey[200],
  },
  border: {
    main: colors.grey[300],
  },
  icon: colors.grey[800],
  divider: colors.grey[200],
};

export const darkPalette = {
  ...lightPalette,
  text: {
    ...lightPalette.text,
    secondary: '#8C8F9A',
    navLink: '#FFF',
    pinText: '#ADB0BB',
  },
  background: {
    ...lightPalette.background,
    main: '#23282F',
    hover: '#373B42',
  },
  border: {
    main: '#33383F',
  },
};

export default lightPalette;

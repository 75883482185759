import { HttpService } from './http-base.service';

export default class TagService extends HttpService {
  constructor(baseURL) {
    if (!TagService.instance) {
      super(baseURL);
      TagService.instance = this;
    }

    return TagService.instance;
  }

  list() {
    return this.get('/api/tags')
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }
}

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import React from 'react';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(0, 0.75, 0.5, 0),
  },
  label: {
    lineHeight: '14px',
  },
  verifiedIcon: {
    color: theme.palette.secondary.main,
    width: '20px',
    marginRight: '6px',
    padding: '0px 2px',
  },
}));

const Flag = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CheckCircle className={classes.verifiedIcon} />
      <Typography variant="body2" color="secondary" className={classes.label}>
        {children}
      </Typography>
    </div>
  );
};

export default Flag;

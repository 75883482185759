import { serverErrorsl10n, successMessagesl10n } from '../../l10n/common';
import * as api from '../../services/api';
import { track } from '../../utils';

export const createAccount = ({
  name,
  username,
  email,
  password,
  recaptcha,
}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'CREATE_ACCOUNT_IS_LOADING',
      payload: {
        isLoading: true,
        errorMessage: '',
      },
    });

    try {
      const response = await api.userService.create({
        name,
        username,
        email,
        password,
      });

      track.setUser({ email });
      track.setTag('sign_up', 'email');
      // google analytics
      track.analytics({
        category: 'sign_up',
        data: {
          email,
          method: 'email',
        },
      });

      dispatch({
        type: 'CREATE_ACCOUNT_SUCCESS',
        payload: {
          isLoading: false,
          thirdPartyAuth: '0',
          isAuthenticated: true,
          ...response,
        },
      });
    } catch (error) {
      dispatch({
        type: 'CREATE_ACCOUNT_FAILURE',
        payload: {
          isLoading: false,
          errorMessage:
            serverErrorsl10n.en[error.message] ||
            serverErrorsl10n.en.GENERIC_ERROR,
        },
      });
    }
  };
};

export const forgotPassword = ({ email }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'FORGOT_PASSWORD_IS_LOADING',
      payload: {
        isLoading: true,
        errorMessage: '',
      },
    });

    try {
      await api.userService.forgotPassword({ email });

      dispatch({
        type: 'FORGOT_PASSWORD_SUCCESS',
        payload: {
          isLoading: false,
          successMessage: successMessagesl10n.en.forgotPassword,
        },
      });
    } catch (error) {
      dispatch({
        type: 'FORGOT_PASSWORD_FAILURE',
        payload: {
          isLoading: false,
          errorMessage:
            serverErrorsl10n.en[error.message] ||
            serverErrorsl10n.en.GENERIC_ERROR,
        },
      });
    }
  };
};

export const editProfile = ({ username }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'EDIT_PROFILE_IS_LOADING',
      payload: {
        isLoading: true,
        errorMessage: '',
      },
    });

    try {
      const response = await api.userService.updateUserName({ username });
      dispatch({
        type: 'EDIT_PROFILE_SUCCESS',
        payload: {
          isLoading: false,
          ...response,
        },
      });
    } catch (error) {
      dispatch({
        type: 'EDIT_PROFILE_FAILURE',
        payload: {
          isLoading: false,
          errorMessage:
            serverErrorsl10n.en[error.message] ||
            serverErrorsl10n.en.GENERIC_ERROR,
        },
      });
    }
  };
};

export const changePassword = ({ newPassword }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'CHANGE_PASSWORD_IS_LOADING',
      payload: {
        isLoading: true,
        errorMessage: '',
      },
    });

    try {
      await api.userService.update({ newPassword });

      dispatch({
        type: 'CHANGE_PASSWORD_SUCCESS',
        payload: {
          isLoading: false,
          successMessage: successMessagesl10n.en.changePassword,
        },
      });
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;

      dispatch({
        type: 'CHANGE_PASSWORD_FAILURE',
        payload: {
          isLoading: false,
          errorMessage: errorMessage,
        },
      });
    }
  };
};

export const changePasswordWithToken = ({
  id,
  token,
  newPassword,
  newPasswordConfirm,
  recaptcha,
}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'CHANGE_PASSWORD_WITH_TOKEN_IS_LOADING',
      payload: {
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      },
    });

    try {
      await api.userService.changePasswordWithToken({
        id,
        token,
        newPassword,
        newPasswordConfirm,
        recaptcha,
      });

      dispatch({
        type: 'CHANGE_PASSWORD_WITH_TOKEN_SUCCESS',
        payload: {
          isLoading: false,
          successMessage: successMessagesl10n.en.changePassword,
          errorMessage: '',
        },
      });
    } catch (error) {
      dispatch({
        type: 'CHANGE_PASSWORD_WITH_TOKEN_FAILURE',
        payload: {
          isLoading: false,
          successMessage: '',
          errorMessage:
            serverErrorsl10n.en[error.message] ||
            serverErrorsl10n.en.GENERIC_ERROR,
        },
      });
    }
  };
};

export const getUserDetails = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'USER_DETAILS_IS_LOADING',
      payload: {
        isLoading: true,
        errorMessage: '',
      },
    });

    try {
      const response = await api.userService.current();
      dispatch({
        type: 'USER_DETAILS_SUCCESS',
        payload: {
          isLoading: false,
          ...response,
        },
      });
    } catch (error) {
      dispatch({
        type: 'USER_DETAILS_FAILURE',
        payload: {
          isLoading: false,
          errorMessage:
            serverErrorsl10n.en[error.message] ||
            serverErrorsl10n.en.GENERIC_ERROR,
        },
      });
    }
  };
};

export const uploadAvatar = (filename: string, image: Blob) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPLOAD_USER_AVATAR_IS_LOADING',
      payload: {
        isLoading: true,
        errorMessage: '',
      },
    });

    try {
      const response = await api.userService.uploadAvatar(filename, image);

      dispatch({
        type: 'UPLOAD_USER_AVATAR_SUCCESS',
        payload: {
          isLoading: false,
          ...response,
        },
      });
    } catch (error) {
      dispatch({
        type: 'UPLOAD_USER_AVATAR_ERROR',
        payload: {
          isLoading: false,
          errorMessage:
            serverErrorsl10n.en[error.message] ||
            serverErrorsl10n.en.GENERIC_ERROR,
        },
      });
    }
  };
};

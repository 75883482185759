import { Button, CssBaseline, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccountMenu,
  Dialog,
  Gallery,
  Header,
  Map as MapComponent,
} from '../components';
import { useDialogContext } from '../context';
import {
  flagActions,
  labelActions,
  ratingActions,
  tagActions,
} from '../store/actions';

const drawerWidthXs = '85%';
const drawerWidthSm = '55%';
const drawerWidthMd = '40%';
const drawerWidthLg = '30%';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.down('sm')]: {
      width: drawerWidthXs,
    },
    [theme.breakpoints.up('sm')]: {
      width: drawerWidthSm,
    },
    [theme.breakpoints.up('md')]: {
      width: drawerWidthMd,
    },
    [theme.breakpoints.up('lg')]: {
      width: drawerWidthLg,
    },
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: theme.palette.background.main,
    paddingTop: 73,
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: drawerWidthXs,
    },
    [theme.breakpoints.up('sm')]: {
      width: drawerWidthSm,
    },
    [theme.breakpoints.up('md')]: {
      width: drawerWidthMd,
    },
    [theme.breakpoints.up('lg')]: {
      width: drawerWidthLg,
    },
  },
  drawerBorder: {
    borderRight: `1px solid ${theme.palette.border.main}`,
  },
  drawerButton: {
    background: theme.palette.background.main,
    position: 'absolute',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.3, 0.5),
    borderLeft: 0,
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    zIndex: 12,
    minWidth: 0,
    '&:hover': {
      background: theme.palette.background.hover,
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    height: '100vh',
    flexGrow: 1,
    paddingTop: 73,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      marginLeft: `-${drawerWidthXs}`,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: `-${drawerWidthSm}`,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: `-${drawerWidthMd}`,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: `-${drawerWidthLg}`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: theme.mixins.toolbar,
  paragraph: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const MapLayout = ({ children }) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.app);
  const tags = useSelector((state) => state.tags.categories);
  const ratings = useSelector((state) => state.ratings.values);
  const labels = useSelector((state) => state.labels.values);
  const flags = useSelector((state) => state.flags.values);
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { successMessage, errorMessage } = useSelector((state) => state.auth);
  const prevDialog = useRef();
  const { dialogState } = useDialogContext();

  useEffect(() => {
    const hasMessageAndClose =
      !dialogState.isVisible && (successMessage || errorMessage);
    if (hasMessageAndClose) {
      dispatch({ type: 'CLEAR_MESSAGING' });
    }
  }, [dialogState, successMessage, errorMessage, dispatch]);

  useEffect(() => {
    const hasMessageChanged = dialogState.current !== prevDialog.current;
    const hasMessageAndDialogChanged =
      hasMessageChanged && (successMessage || errorMessage);
    if (hasMessageChanged) {
      prevDialog.current = dialogState.current;
    }
    if (hasMessageAndDialogChanged) {
      dispatch({ type: 'CLEAR_MESSAGING' });
    }
  }, [dialogState, dispatch, errorMessage, successMessage]);

  useEffect(() => {
    if (!tags.length) {
      dispatch(tagActions.getTags());
    }
  }, [dispatch, tags.length]);

  useEffect(() => {
    if (!ratings.length) {
      dispatch(ratingActions.getRatings());
    }
  }, [dispatch, ratings.length]);

  useEffect(() => {
    // disable label calls for now
    if (false && !labels.length) {
      dispatch(labelActions.getLabels());
    }
  }, [dispatch, labels.length]);

  useEffect(() => {
    if (!flags.length) {
      dispatch(flagActions.getFlags());
    }
  }, [dispatch, flags.length]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root} environment={appState.environment}>
      <CssBaseline />
      <Header />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
          paperAnchorDockedLeft: classes.drawerBorder,
        }}
      >
        {children}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Button
          classes={{
            root: classes.drawerButton,
          }}
          onClick={open ? handleDrawerClose : handleDrawerOpen}
          variant="contained"
          aria-label={open ? 'close drawer' : 'open drawer'}
        >
          {open ? (
            <ChevronLeftIcon fontSize="large" color="primary" />
          ) : (
            <ChevronRightIcon fontSize="large" color="primary" />
          )}
        </Button>
        <AccountMenu />
        <MapComponent />
        <Dialog />
        <Gallery />
      </main>
    </div>
  );
};

export default MapLayout;

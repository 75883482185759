import { Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import validate from 'validate.js';
import { accountActions } from '../../store/actions';
import { config, constraints, track } from '../../utils';

const useStyles = makeStyles((theme) => ({
  caption: {
    margin: theme.spacing(2, 0),
  },
  button: {
    margin: theme.spacing(2, 0),
  },
  messageContainer: {
    marginTop: theme.spacing(2),
  },
}));

const ChangeView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const recaptchaRef = React.createRef();
  const [formState, setFormState] = useState({
    isValid: true,
    values: {
      ...queryParams,
    },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    track.screen('ChangePasswordView');
  }, []);

  useEffect(() => {
    const errors = validate(
      formState.values,
      constraints.changePasswordWithToken
    );
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleFormSubmit = (token) => {
    const { values } = formState;
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      values.recaptcha = token;
    }
    dispatch(accountActions.changePasswordWithToken(values));
  };

  const handleReCaptcha = (event) => {
    event.preventDefault();
    recaptchaRef.current.execute();
  };

  return (
    <>
      <Typography className={classes.caption}>
        Everyone forgets sometimes. Pick a new password below. Password must be
        at least 8 characters and must include at least one upper case letter,
        one lower case letter, and one numeric digit.
      </Typography>
      <form onSubmit={handleReCaptcha}>
        <TextField
          margin="dense"
          id="newPassword"
          name="newPassword"
          label="New Password"
          type="password"
          fullWidth
          error={hasError('newPassword')}
          helperText={
            hasError('newPassword') ? formState.errors.newPassword[0] : null
          }
          onChange={handleChange}
          value={formState.values.newPassword || ''}
          aria-label="required"
        />
        <TextField
          margin="dense"
          id="newPasswordConfirm"
          name="newPasswordConfirm"
          label="Confirm Password"
          type="password"
          fullWidth
          error={hasError('newPasswordConfirm')}
          helperText={
            hasError('newPasswordConfirm')
              ? formState.errors.newPasswordConfirm[0]
              : null
          }
          onChange={handleChange}
          value={formState.values.newPasswordConfirm || ''}
          aria-label="required"
        />
        <Button
          className={classes.button}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={!formState.isValid}
          fullWidth
        >
          Reset Password
        </Button>
      </form>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={config.recaptcha.siteKey}
        onChange={handleFormSubmit}
      />
    </>
  );
};

export default ChangeView;

const initState = {
  ratings: [],
  labels: [],
  tags: [],
  flags: [],
};

const filterReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'UPDATE_FILTERS':
      return {
        ...state,
        ...payload,
      };
    case 'CLEAR_FILTERS':
      return {
        ratings: [],
        tags: [],
        labels: [],
        flags: [],
      };
    case 'APPLY_FILTERS':
      return state;
    default:
      return state;
  }
};

export default filterReducer;

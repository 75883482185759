import MarkerClusterer from '@google/markerclustererplus';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import Option from './option';

const env = runtimeEnv();

export default {
  environment: env.REACT_APP_HOST_ENV || 'local',
  host: env.REACT_APP_HOST,
  apiHost: env.REACT_APP_API_HOST,
  wpApiHost: env.REACT_APP_WP_API_HOST,
  appId: env.REACT_APP_APP_ID,
  google: {
    apiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY,
    cluster: {
      gridSize: Option.risky(() =>
        Number(env.REACT_APP_GOOGLE_MAPS_CLUSTER_GRID_SIZE)
      ).getOrElse(() => 60),
      minimumClusterSize: Option.risky(() =>
        Number(env.REACT_APP_GOOGLE_MAPS_CLUSTER_MIN_CLUSTER_SIZE)
      ).getOrElse(() => 10),
      batchSize: Option.risky(() =>
        Number(env.REACT_APP_GOOGLE_MAPS_CLUSTER_BATCH_SIZE)
      ).getOrElse(() => MarkerClusterer.BATCH_SIZE),
      batchSizeIE: Option.risky(() =>
        Number(env.REACT_APP_GOOGLE_MAPS_CLUSTER_BATCH_SIZE_IE)
      ).getOrElse(() => MarkerClusterer.BATCH_SIZE_IE),
    },
  },
  facebook: {
    apiVersion: env.REACT_APP_FACEBOOK_API_VERSION,
    appId: env.REACT_APP_FACEBOOK_APP_ID,
  },
  firebase: {
    apiKey: env.REACT_APP_FIREBASE_API_KEY,
    authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: env.REACT_APP_FIREBASE_APP_ID,
    measurementId: env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  },
  recaptcha: {
    siteKey: env.REACT_APP_RECAPTCHA_KEY,
  },
  apple: {
    clientId: env.REACT_APP_APPLE_CLIENT_ID,
    scopes: env.REACT_APP_APPLE_SCOPES,
    redirectUrl: env.REACT_APP_APPLE_REDIRECT_URI,
  },
  directory: {
    resultsPerPage: 12,
  },
};

import { ratingsl10n, serverErrorsl10n } from '../../l10n/common';
import * as api from '../../services/api';

export const getRatings = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'GET_RATINGS_IS_LOADING',
      payload: {
        isRatingLoading: true,
      },
    });

    try {
      const response = await api.ratingService.list();

      const ratings = response
        .sort((a, b) => b.weight - a.weight)
        .map((rating) => ({
          key: rating.key,
          displayName: ratingsl10n.en[rating.key],
        }))
        .filter((rating) => rating.key !== 'unrated');

      dispatch({
        type: 'GET_RATINGS_SUCCESS',
        payload: {
          isRatingLoading: false,
          values: ratings,
        },
      });
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;

      dispatch({
        type: 'GET_RATINGS_SUCCESS_FAILURE',
        payload: {
          isRatingLoading: false,
          ratingErrorMsg: errorMessage,
        },
      });
    }
  };
};

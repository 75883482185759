import * as Sentry from '@sentry/browser';
import { analytics as firebaseAnalytics } from './firebase';

export const error = (_error, tags) => {
  Sentry.withScope((scope) => {
    if (!!tags) {
      scope.setTags(tags);
    }
    scope.setExtra('error', _error);
    Sentry.captureException(_error);
  });
};

/**
 * Log a custom event with optional params.
 *
 * @param {Object} arg
 * @param {String} arg.category Event name must not conflict with any Reserved Events.
 * @param {Object} arg.data Parameters to be sent and displayed with the event.
 */
export const analytics = ({ category, data = {} }) => {
  Sentry.addBreadcrumb({
    level: Sentry.Severity.Info,
    category,
    data,
  });

  firebaseAnalytics.logEvent(category, data);
};

/**
 * Sets the current screen name, which specifies the current visual context in your app.
 *
 * @param {String} screen
 */
export const screen = (_screen) => {
  // Set & override the MainActivity screen name
  firebaseAnalytics.setCurrentScreen(_screen, _screen);
};

/**
 * Updates user context information for future events.
 *
 * @param {Object} arg
 * @param {String} arg.id
 * @param {String} arg.email
 */
export const setUser = ({ id, email }) => {
  Sentry.setUser({ id, email: email });

  if (id && email) {
    firebaseAnalytics.setUserId(id);
    firebaseAnalytics.setUserProperty('email', email);
  }
};

/**
 * Set key:value that will be sent as tags data with the event.
 *
 * @param {String} key String key of tag
 * @param {String} value String value of tag
 */
export const setTag = (key, value) => {
  Sentry.setTag(key, value);

  firebaseAnalytics.logEvent(key, { value });
};

/**
 * Set an object that will be merged sent as tags data with the event.
 *
 * @param {Object} tags Tags context object to merge into current context.
 */
export const setTags = (tags) => {
  Sentry.setTags(tags);
};

export const ACCESS_FROM_HOME_PATH = '/access-from-home';
export const ADD_PATH = '/add';
export const PLACE_DETAIL_PATH = `/details/:id/:platform/:success?`;
export const PLACE_DETAIL_PATH_LEGACY = `/details/:id`;
export const SHARE_PATH = '/share';
export const RESET_PASSWORD_PATH = `/reset-password/:id?`;
export const FORGOT_PASSWORD_PATH = '/forgot-password/';
export const IMAGE_LIMIT = 10;

export const directoryPaths = [ACCESS_FROM_HOME_PATH];
export const addPaths = [ADD_PATH];
export const detailsPaths = [PLACE_DETAIL_PATH, SHARE_PATH];
export const passwordPaths = [RESET_PASSWORD_PATH, FORGOT_PASSWORD_PATH];

/* eslint-disable no-undef */
import { LinearProgress, makeStyles } from '@material-ui/core';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getLocationByIP } from '../../services/geolocation';
import { mapActions, searchActions } from '../../store/actions';
import mapStyles from '../../theme/map';
import { config } from '../../utils';
import Cluster from './Cluster';
import MyLocationButton from './MyLocationButton';
import SearchThisAreaButton from './SearchThisAreaButton';
import ZoomOutButton from './ZoomOutButton';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    // 73px is the height of the header
    height: 'calc(100vh - 73px)',
  },
  map: {
    flex: '1',
  },
  loader: {
    width: '100%',
  },
}));

const mapOptions = {
  panControl: false,
  mapTypeControl: false,
  scrollwheel: false,
  fullscreenControl: false,
  streetViewControl: false,
  minZoom: 3,
  controlSize: 32,
};

const INITIAL_CENTER = { lat: 5, lng: -15 };
const INITIAL_ZOOM = 15;

const Map = () => {
  const location = useLocation();
  const { themeMode } = useSelector((state) => state.app);
  const isLight = themeMode === 'light';
  const activeMapStyles = isLight ? mapStyles.light : mapStyles.dark;
  const lat = useSelector((state) => state.map.location.latitude);
  const lng = useSelector((state) => state.map.location.longitude);
  const selectedPlaceDetails = useSelector(
    (state) => state.map.selectedPlaceDetails
  );

  const initCenter =
    lat && lng
      ? {
          lat,
          lng,
        }
      : INITIAL_CENTER;

  const classes = useStyles();
  const [center, setCenter] = useState(initCenter);
  const [zoom, setZoom] = useState(INITIAL_ZOOM);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    const { latitude, longitude } = selectedPlaceDetails;
    const getLatLng = async () => {
      const location =
        latitude && longitude
          ? { latitude, longitude }
          : await getLocationByIP();
      if (isMounted) {
        dispatch(mapActions.updateLocation(location));
        dispatch(searchActions.updateLocation({ location }));
      }
    };

    if (location.pathname === '/') getLatLng();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  useEffect(() => {
    const isFromDetail = !!location.state?.from;
    setCenter({
      lat,
      lng,
    });
    setZoom(isFromDetail ? 18 : INITIAL_ZOOM);
  }, [dispatch, lat, lng, location.state]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.google.apiKey,
    preventGoogleFonts: true,
  });

  return (
    <>
      <div className="visually-hidden">
        World map containing visual of all pins added.
      </div>

      <div className={classes.container}>
        {!isLoaded ? (
          <LinearProgress color="primary" className={classes.loader} />
        ) : (
          <GoogleMap
            aria-hidden="true"
            tabIndex="-1"
            id="map-container"
            mapContainerClassName={classes.map}
            zoom={zoom}
            center={center}
            options={{ ...mapOptions, styles: activeMapStyles }}
          >
            <Cluster />

            <SearchThisAreaButton position="TOP_CENTER" />

            <ZoomOutButton
              position="RIGHT_BOTTOM"
              initialCenter={initCenter}
              initialZoom={INITIAL_ZOOM}
            />

            <MyLocationButton position="RIGHT_BOTTOM" />
          </GoogleMap>
        )}
      </div>
    </>
  );
};

export default Map;

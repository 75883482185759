import overrides from './overrides';
import { lightPalette } from './palette';
import typography from './typography';

const lightTheme = {
  palette: lightPalette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
};

export default lightTheme;

import dayjs from 'dayjs';
let duration = require('dayjs/plugin/duration');

const differenceBetweenTwoDates = (startTime, endTime) => {
  dayjs.extend(duration);
  const startMomentDate = dayjs(new Date(startTime));
  const endMomentDate = dayjs(new Date(endTime));
  const durationT = dayjs.duration(endMomentDate.diff(startMomentDate));
  const mins = durationT.asMinutes();
  return mins;
};

export { differenceBetweenTwoDates };

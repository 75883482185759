export default {
  extraLarge: 24,
  largest: 22,
  larger: 20,
  large: 18,
  base: 16,
  small: 14,
  smaller: 12,
  smallest: 10,
  tiny: 8,
  tinier: 6,
  tiniest: 3,
};

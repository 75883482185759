import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import isEqual from 'lodash.isequal';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  container: {
    border: '1px solid red',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 16,
    marginBottom: -16,
  },
  imageContainer: {
    position: 'relative',
  },
  iconContainer: {
    position: 'absolute',
    top: '-20px',
    right: '-6px',
    zIndex: 2,
  },
});
const ANGrid = ({ images, handleRemoveImage, gridStyle }) => {
  const classes = useStyles();
  const { flaggedImages } = useSelector((state) => state.add);
  const [gridState, setGridState] = useState(images);
  const [cachedImagesCount, setCachedImagesCount] = useState(images.length);

  useEffect(() => {
    if (!isEqual(images, gridState)) {
      setGridState(images);
    }
  }, [gridState, images]);

  useEffect(() => {
    setCachedImagesCount(gridState.length);
  }, [cachedImagesCount, gridState.length]);

  const handleRenderItemImage = (item, index) => {
    const hasError = !!flaggedImages.filter((image) => {
      return image.includes(item.name);
    }).length;
    return (
      <div key={index} className={classes.imageContainer}>
        <img
          alt="added to gallery"
          style={{
            border: hasError ? '4px solid red' : 'none',
            width: 90,
            height: 90,
            borderWidth: 4,
            borderColor: 'transparent',
          }}
          src={item.uri || item.url}
        />
        <div className={classes.iconContainer}>
          <IconButton
            aria-label="Remove photo"
            onClick={() => handleRemoveImage(item)}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </div>
      </div>
    );
  };

  const handleRenderItem = (item, index) => {
    return handleRenderItemImage(item, index);
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '24% 24% 24% 24%',
        gridGap: '1%',
        width: '100%',
        ...gridStyle,
      }}
    >
      {gridState.map((item, index) => handleRenderItem(item, index))}
    </div>
  );
};

export default ANGrid;

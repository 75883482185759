import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(1, 2.5),
    minHeight: '73px',
  },
  centered: {
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  iconButton: {
    marginRight: theme.spacing(2),
  },
  divider: {
    backgroundColor: theme.palette.border.main,
  },
}));

const DrawerHeader = ({ title, onClick, noBackButton, isCentered }) => {
  const classes = useStyles();

  const backButton = !noBackButton ? (
    <IconButton
      size="small"
      onClick={() => {
        onClick && onClick();
      }}
      aria-label="back to search"
      className={classes.iconButton}
    >
      <ChevronLeftIcon fontSize="large" color="primary" />
    </IconButton>
  ) : null;

  return (
    <>
      <div className={clsx(classes.header, isCentered && classes.centered)}>
        {backButton}
        <div className={classes.title}>
          <Typography variant="h4" color="primary" noWrap>
            {title}
          </Typography>
        </div>
      </div>
      <Divider className={classes.divider} style={{ marginTop: -1 }} />
    </>
  );
};

export default DrawerHeader;

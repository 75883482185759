import { Box, Divider, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  FeatureSummary,
  PinSummary,
  SearchBar,
  SearchResultsList,
} from '../components';
import { MapLayout } from '../layouts';
import { track } from '../utils';

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: theme.palette.border.main,
  },
}));

const SearchView = () => {
  const classes = useStyles();
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const isLoading = useSelector((state) => state.search.isLoading);
  const { t } = useTranslation();

  useEffect(() => {
    track.screen('SearchView');
  }, []);

  const handleSubmit = React.useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <MapLayout>
      <div className="visually-hidden">
        <FeatureSummary />
      </div>
      <form role="search" onSubmit={handleSubmit}>
        <Box pl={3} pr={3} mt={2} mb={2}>
          <SearchBar placeholder={t('messages.findPlaceByName')} />
        </Box>
        <Divider
          className={classes.divider}
          style={{ width: '100%' }}
          aria-hidden="true"
        />
      </form>
      {isLoading && <LinearProgress color="primary" />}
      {searchQuery.length ? (
        <SearchResultsList />
      ) : (
        <div aria-hidden="true">
          <PinSummary />
          <FeatureSummary />
        </div>
      )}
    </MapLayout>
  );
};

export default SearchView;

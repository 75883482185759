import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { config } from '../utils';
import { getOperatingSystem } from '../utils/appInit';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.grey[900],
    position: 'absolute',
    zIndex: 22000,
    height: '12%',
    right: 0,
    left: 0,
    transition: 'top 1s',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    padding: '0 5%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: 42,
    height: 42,
    marginRight: theme.spacing(2),
  },
  appName: {
    color: theme.palette.white,
    fontSize: '1rem',
    fontWeight: 500,
  },
  appDesc: {
    color: theme.palette.grey[500],
    fontSize: '0.75rem',
    fontWeight: 400,
    paddingTop: '2%',
  },
  btn: {
    background: 'none',
    border: 'none',
    padding: '0.5rem',
    fontSize: '0.9rem',
    cursor: 'pointer',
    color: theme.palette.white,
    borderRadius: '0.5rem',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),
    '&:active': {
      transform: 'scale(1.1)',
    },
    '&:hover, &:focus': {
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
  },
  btnClose: {
    position: 'absolute',
  },
  btnInstall: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const AppBanner = (isLight) => {
  const classes = useStyles();
  const [appBannerVisible, setAppBannerVisible] = useState(false);
  const storeLink = `market://details?id=${config.appId}`;

  useEffect(() => {
    const OS = getOperatingSystem(window);

    const triggerAppOpen = async () => {
      if (OS === 'Android') {
        await navigator.getInstalledRelatedApps().then((relatedApps) => {
          if (relatedApps?.length < 1) {
            setAppBannerVisible(true);
          }
        });
      }
    };
    triggerAppOpen(OS);
  }, []);

  return (
    <div
      className={classes.mainContainer}
      style={{
        top: appBannerVisible ? 0 : '-15%',
      }}
    >
      <button
        className={`${classes.btn} ${classes.btnClose}`}
        onClick={() => setAppBannerVisible(false)}
      >
        <CloseIcon fontSize="small" />
      </button>
      <div className={classes.contentContainer}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            className={classes.logo}
            src="/icons/android-icon-192x192.png"
            alt=""
          />
          <h2 className={classes.appName}>
            AccessNow App
            <p className={classes.appDesc}>Find + Rate Accessible Places</p>
          </h2>
        </div>
        <button
          className={`${classes.btn} ${classes.btnInstall}`}
          onClick={() => document.location.replace(storeLink)}
        >
          Install
        </button>
      </div>
    </div>
  );
};

export default AppBanner;

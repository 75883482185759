import isEqual from 'lodash.isequal';
import { useEffect, useState, useRef } from 'react';
import { serverErrorsl10n } from '../l10n/common';
import * as api from '../services/api';

export default ({ service, method, payload }) => {
  const [state, setState] = useState({
    data: null,
    isLoading: false,
    errorMessage: '',
  });

  const useDeepCompareMemoize = (value) => {
    const ref = useRef();
    if (!isEqual(value, ref.current)) {
      ref.current = value;
    }
    return ref.current;
  };

  const updatedPayload = useDeepCompareMemoize(payload);

  useEffect(() => {
    let isMounted = true;
    setState((state) => ({ data: state.data, isLoading: true }));
    (async () => {
      try {
        const data = await api[service][method](updatedPayload);
        isMounted && setState({ data, isLoading: false, errorMessage: '' });
      } catch (error) {
        const errorMessage =
          serverErrorsl10n.en[error.message] ||
          serverErrorsl10n.en.GENERIC_ERROR;
        isMounted && setState({ isLoading: false, errorMessage });
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [method, service, updatedPayload]);
  return state;
};

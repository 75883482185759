import { LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Review } from '.';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    position: 'relative',
    padding: theme.spacing(0, 3, 1, 3),
  },
  titleContainer: {
    padding: theme.spacing(2, 3, 1, 3),
  },
  infoText: {
    color: theme.palette.text.pinText,
  },
}));

const PlaceDetailReviews = () => {
  const byPlace = useSelector((state) => state.reviews.byPlace);
  const isLoading = useSelector((state) => state.reviews.isLoading);
  const classes = useStyles();

  return (
    <>
      {isLoading && <LinearProgress color="primary" />}
      <div className={classes.titleContainer}>
        <Typography className={classes.infoText} variant="h5">
          Reviews
        </Typography>
      </div>
      <div className={classes.container}>
        {!isLoading &&
          byPlace.map((review) => {
            return <Review review={review} key={review._id} />;
          })}
      </div>
    </>
  );
};

export default PlaceDetailReviews;

import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { DialogTitle } from '../';
import { useDirectoryContext } from '../../context';
import { track } from '../../utils';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    paddingBottom: theme.spacing(3),
    paddingTop: 0,
  },
  listRoot: {
    padding: 0,
  },
  button: {
    margin: theme.spacing(4, 0, 2, 0),
    minWidth: 180,
  },
}));

const ServicesDialog = () => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { state, dispatch } = useDirectoryContext();
  const { isServicesDialogVisible, servicesOptions, checkedServicesState } =
    state;

  const handleToggle = (value) => () => {
    const currentIndex = checkedServicesState.indexOf(value);
    const newChecked = [...checkedServicesState];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    dispatch({ type: 'SET_CHECKED_SERVICES_STATE', payload: newChecked });
  };

  const handleApply = () => {
    dispatch({ type: 'SET_SERVICES', payload: checkedServicesState });

    track.analytics({
      category: 'directory_filter',
      data: {
        services: checkedServicesState,
      },
    });
  };

  return (
    <Dialog
      fullWidth
      open={isServicesDialogVisible}
      onClose={handleApply}
      fullScreen={fullScreen}
      aria-label={'Services dialog'}
      maxWidth={'sm'}
    >
      <DialogTitle
        id="dialog-title"
        onClose={handleApply}
        aria-label={`Services dialog`}
      >
        {'Services'}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <List className={classes.listRoot}>
          <Grid container>
            {servicesOptions.map((service, i) => {
              const labelId = `service-list-label-${service.key}`;
              return (
                <Grid item xs={12} sm={6} key={i}>
                  <ListItem
                    role={undefined}
                    dense
                    button
                    onClick={handleToggle(service.key)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={
                          checkedServicesState.indexOf(service.key) !== -1
                        }
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={service.displayName} />
                  </ListItem>
                </Grid>
              );
            })}
            <Grid container item xs={12} justify="flex-end">
              <Button
                className={classes.button}
                color="secondary"
                size="large"
                type="submit"
                variant="contained"
                onClick={handleApply}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default ServicesDialog;

// Based on https://github.com/see;den/react-facebook
import config from '../utils/config';

export default class Facebook {
  constructor(options = {}) {
    this.options = {
      domain: 'connect.facebook.net',
      appId: config.facebook.appId,
      version: config.facebook.apiVersion,
      cookie: false,
      status: false,
      xfbml: false,
      language: 'en_US',
      frictionlessRequests: false,
      debug: false,
      chatSupport: false,
      ...options,
    };

    this.init();
  }

  getAppId() {
    return this.options.appId;
  }

  async init() {
    if (this.loadingPromise) {
      return this.loadingPromise;
    }

    this.loadingPromise = new Promise((resolve) => {
      const { domain, language, debug, chatSupport, ...restOptions } =
        this.options;

      window.fbAsyncInit = () => {
        window.FB.init({
          appId: restOptions.appId,
          version: restOptions.version,
          cookie: restOptions.cookie,
          status: restOptions.status,
          xfbml: restOptions.xfbml,
          frictionlessRequests: this.frictionlessRequests,
        });

        resolve(window.FB);
      };

      if (window.document.getElementById('facebook-jssdk')) {
        return resolve(window.FB);
      }

      const _chatsupport = chatSupport ? '/xfbml.customerchat' : '';
      const _debug = debug ? '/debug' : '';

      const js = window.document.createElement('script');
      js.id = 'facebook-jssdk';
      js.async = true;
      js.defer = true;
      js.src = `https://${domain}/${language}/sdk${_chatsupport}${_debug}.js`;

      window.document.body.appendChild(js);
    });

    return this.loadingPromise;
  }
}

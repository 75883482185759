import { HttpService } from './http-base.service';

export default class EventService extends HttpService {
  constructor(baseURL) {
    if (!EventService.instance) {
      super(baseURL);
      EventService.instance = this;
    }

    return EventService.instance;
  }

  subscribeUserToEvent(eventCode) {
    return this.post('api/event/subscribe', { eventCode })
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  // Add more event-related methods here if needed
}

import { produce } from 'immer';
import React, { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';

const DialogContext = createContext([null, () => null]);

const reducer = produce((draft, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'OPEN':
      draft.isVisible = true;
      return;
    case 'CLOSE':
      draft.isVisible = false;
      return;
    case 'OPEN_AS':
      draft.isVisible = true;
      draft.current = payload;
      return;
  }
});

const initialState = {
  isVisible: false,
  current: '',
};

export const DialogProvider = (props) => {
  const [dialogState, dialogDispatch] = useImmerReducer(reducer, initialState);

  return (
    <DialogContext.Provider value={{ dialogState, dialogDispatch }}>
      {props.children}
    </DialogContext.Provider>
  );
};

export const useDialogContext = () => useContext(DialogContext);

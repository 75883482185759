import { Fab, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { useCallback, useState } from 'react';
import Carousel from '../components/Carousel';
import { useGalleryContext } from '../context';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    '& img': {
      display: 'block',
      backgroundColor: 'transparent',
      boxShadow: '0px 0px 30px 0px rgb(0, 0, 0, 0.5)',
    },
  },
  mainImageContainer: {
    width: '100%',
    height: '80%',
    objectFit: 'contain',
    transition: 'transform 0.3s',
    whiteSpace: 'nowrap',
    display: 'flex',
  },
  mainImage: {
    height: '100%',
    minWidth: '100%',
    objectFit: 'contain',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1,
  },
  background: {
    backgroundColor: 'rgb(0, 0, 0, 0.75) !important',
  },
  lineItemRow: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    height: '20%',
  },
  btn: {
    background: 'none',
    border: 'none',
    padding: '0.5rem',
    fontSize: '2rem',
    cursor: 'pointer',
    color: theme.palette.white,
    borderRadius: '0.5rem',
    backgroundColor: 'rgba(0,0,0,0.5)',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),
    '&:active': {
      transform: 'scale(1.1)',
    },
    '&:hover, &:focus': {
      backgroundColor: 'rgba(0,0,0,0.8)',
    },
  },
  btnPrev: {
    position: 'absolute',
    top: '40%',
    left: '2%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  btnNext: {
    position: 'absolute',
    top: '40%',
    right: '2%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

const SWIPE_VALUE = 0.05;

const Gallery = () => {
  const classes = useStyles();
  const { galleryState, galleryDispatch } = useGalleryContext();
  const { isVisible, images, imageFocus } = galleryState;
  const [swipeInfo, setSwipeInfo] = useState({});
  const handleClose = () => {
    galleryDispatch({ type: 'CLOSE' });
  };
  const handleSwipeStart = useCallback((e) => {
    const touch = e.touches[0];
    setSwipeInfo({ x: touch.clientX });
  }, []);

  const handleSwipeMove = useCallback(
    (e) => {
      const touch = e.changedTouches[0];
      if (touch.clientX > swipeInfo.x) {
        galleryDispatch({
          type: 'SET_FOCUS',
          payload: imageFocus - SWIPE_VALUE,
        });
      } else {
        galleryDispatch({
          type: 'SET_FOCUS',
          payload: imageFocus + SWIPE_VALUE,
        });
      }
    },
    [galleryDispatch, imageFocus, swipeInfo.x]
  );

  const handleSwipeEnd = useCallback(
    (e) => {
      galleryDispatch({ type: 'SET_FOCUS', payload: Math.round(imageFocus) });
    },
    [galleryDispatch, imageFocus]
  );
  const handleChangeImage = useCallback(
    (direction) => {
      switch (direction) {
        case 'next':
          galleryDispatch({ type: 'SET_FOCUS', payload: imageFocus + 1 });
          break;
        case 'prev':
          galleryDispatch({ type: 'SET_FOCUS', payload: imageFocus - 1 });
          break;
        default:
          return;
      }
    },
    [galleryDispatch, imageFocus]
  );
  return (
    <Modal
      open={isVisible}
      onClose={handleClose}
      BackdropProps={{ className: classes.background }}
    >
      <div className={classes.container}>
        <div
          className={classes.mainImageContainer}
          style={{ transform: `translateX(-${imageFocus * 100}%)` }}
          onTouchStart={handleSwipeStart}
          onTouchMove={handleSwipeMove}
          onTouchEnd={handleSwipeEnd}
        >
          {images.map((image) => (
            <img
              key={`main-${image.url}`}
              alt={image.altText}
              src={image.url}
              className={classes.mainImage}
            />
          ))}
        </div>
        <button
          className={`${classes.btn} ${classes.btnNext}`}
          disabled={imageFocus === images.length - 1}
          onClick={() => handleChangeImage('next')}
        >
          <ChevronRightIcon fontSize="large" />
        </button>
        <button
          className={`${classes.btn} ${classes.btnPrev}`}
          disabled={imageFocus === 0}
          onClick={() => handleChangeImage('prev')}
        >
          <ChevronLeftIcon fontSize="large" />
        </button>
        {images.length > 1 && (
          <div className={classes.lineItemRow}>
            <Carousel images={images} isGallery />
          </div>
        )}
        <Fab
          aria-label="close gallery"
          className={classes.closeButton}
          onClick={handleClose}
          size="small"
        >
          <CloseIcon />
        </Fab>
      </div>
    </Modal>
  );
};

export default Gallery;

const initState = {
  isTagLoading: false,
  categories: [],
  tagErrorMsg: '',
};

const tagReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'GET_TAGS_IS_LOADING':
    case 'GET_TAGS_SUCCESS':
    case 'GET_TAGS_FAILURE':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default tagReducer;

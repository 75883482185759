import { locationl10n, serverErrorsl10n } from '../../l10n/common';
import * as api from '../../services/api';
import { initState } from '../reducers/autoCompleteReducer';
import * as searchActions from './searchActions';

export const clearAutoComplete = () => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_AUTOCOMPLETE',
    });
  };
};

export const updateAutoComplete = (query: string) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_AUTOCOMPLETE_IS_LOADING',
      payload: {
        isLoading: true,
      },
    });

    try {
      const response = await api.placeService.autocomplete(query);

      const results = response
        ? [locationl10n.en.CURRENT_LOCATION, ...response]
        : response;

      dispatch({
        type: 'UPDATE_AUTOCOMPLETE_SUCCESS',
        payload: {
          ...initState,
          results,
        },
      });
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;

      dispatch({
        type: 'UPDATE_AUTOCOMPLETE_FAILURE',
        payload: {
          ...initState,
          errorMessage,
        },
      });
    }
  };
};

export const updateAutoCompleteDetail = ({ id, locationName }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_AUTOCOMPLETE_DETAIL_IS_LOADING',
      payload: {
        isLoading: true,
      },
    });

    try {
      const response = await api.placeService.autocompleteDetail(id);

      dispatch({
        type: 'UPDATE_AUTOCOMPLETE_DETAIL_SUCCESS',
        payload: {
          ...initState,
          detail: response,
        },
      });

      const { searchQuery } = getState().search;
      searchActions.updateLocation({
        location: {
          name: locationName,
          latitude: response.location.lat,
          longitude: response.location.lng,
        },
        country: response.country,
      })(dispatch);

      if (searchQuery) {
        searchActions.updateSearch(searchQuery)(dispatch, getState);
      }
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;

      dispatch({
        type: 'UPDATE_AUTOCOMPLETE_DETAIL_FAILURE',
        payload: {
          ...initState,
          errorMessage,
        },
      });
    }
  };
};

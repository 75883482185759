export const setComponentVisible = (_id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_COMPONENT_VISIBLE',
      payload: {
        componentId: _id,
      },
    });
  };
};

import { HttpService } from './http-base.service';

export default class RatingService extends HttpService {
  constructor(baseURL) {
    if (!RatingService.instance) {
      super(baseURL);
      RatingService.instance = this;
    }

    return RatingService.instance;
  }

  list() {
    return this.get('/api/ratings')
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }
}

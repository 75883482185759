import { Grid, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Message } from '../../components';
import { track } from '../../utils';
import ChangeView from './ChangeView';
import ForgotView from './ForgotView';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  logo: {
    width: 60,
    height: 60,
    marginBottom: theme.spacing(2),
    alignSelf: 'flex-start',
  },
  messageContainer: {
    marginTop: theme.spacing(2),
  },
  continueLink: {
    marginTop: theme.spacing(2),
  },
}));

const ResetView = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const { id, token } = queryString.parse(location.search);
  const classes = useStyles();
  const [isChangePassword, setIsChangePassword] = useState(id && token);
  const successMessage = useSelector((state) => state.auth.successMessage);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const { t } = useTranslation();

  useEffect(() => {
    track.screen('ResetPasswordView');
  }, []);

  useEffect(() => {
    if (
      // show "forgot-password" if there was an error after attempting a reset
      (id && token && errorMessage) ||
      // show "forgot-password" if we attempted to visit "/reset-password/"" without an id or token
      (!id && !token && location.pathname !== '/forgot-password/')
    ) {
      history.push('/forgot-password/');
    }

    setIsChangePassword(id && token && !errorMessage);
  }, [errorMessage, history, id, location, params, token]);

  const displayMess = errorMessage || successMessage || '';
  const messageContainer = (
    <div className={classes.messageContainer}>
      <Message
        id="form-dialog-description"
        variant={errorMessage ? 'error' : 'success'}
        message={displayMess}
      />
    </div>
  );
  return (
    <div className={classes.root}>
      <img className={classes.logo} src="/images/logo/logo-blue.png" alt="" />
      <Grid
        container
        item
        direction="column"
        justify="flex-start"
        md={4}
        sm={7}
        xs={12}
      >
        <CssBaseline />
        <Typography variant="h2" noWrap>
          {isChangePassword ? 'Reset Password' : 'Forgot Password'}
        </Typography>
        {displayMess && messageContainer}
        {!successMessage &&
          ((isChangePassword && <ChangeView />) || <ForgotView />)}
        {id && token && successMessage ? (
          <Link
            className={classes.continueLink}
            href="/"
            color="primary"
            size="large"
            variant="contained"
            fullWidth
          >
            {t('messages.continue')}
          </Link>
        ) : null}
      </Grid>
    </div>
  );
};

export default ResetView;

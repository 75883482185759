export const reset = () => {
  return {
    type: 'EDIT_RESET',
  };
};
// Action Creators
export const selectImage = (images) => {
  return {
    type: 'SELECT_IMAGE',
    payload: images,
  };
};

export const deselectImage = (image) => ({
  type: 'DESELECT_IMAGE',
  payload: image,
});
export const clearImage = () => {
  return {
    type: 'IMAGE_RESET',
  };
};

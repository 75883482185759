import pickBy from 'lodash.pickby';
import { formatLatLng } from '../../utils/parse';
import { HttpService, CancelToken } from './http-base.service';
import {
  CreateArgs,
  DetailArgs,
  NearbyArgs,
  SearchArgs,
  UpdateArgs,
  UpdateReviewArgs,
} from './types';

export let cancelSearchRequest;

const buildParams = (
  args: SearchArgs | UpdateArgs | CreateArgs | UpdateReviewArgs
) => {
  const latitude = args.latitude || args.location.latitude || null;
  const longitude = args.longitude || args.location.longitude || null;
  const { top, right, bottom, left } = args.boundaries || {};
  const ratings = args.ratings || [];
  const tags = args.tags || [];
  const text = args.text;
  const labels = args.labels || [];
  const flags = args.flags || [];
  const parentId = args.parentId || null;
  const eventId = args.eventId || null;

  const params = {
    _id: args.id || args._id,
    servicePlaceId: args.servicePlaceId,
    filter: args.query,
    ratings: ratings.join(','),
    rating: args.rating,
    tag: tags.join(','), // for `search` and `nearby` call
    tags, // for `add a pin` call
    latitude: formatLatLng(latitude),
    longitude: formatLatLng(longitude),
    top: formatLatLng(top),
    right: formatLatLng(right),
    bottom: formatLatLng(bottom),
    left: formatLatLng(left),
    unit: args.unit,
    name: args.name,
    address: args.address,
    description: args.description,
    phone: args.phone,
    id: args.id,
    active: args.active,
    url: args.url,
    googleId: args.googleId,
    country: args.country,
    nextPageToken: args.nextPageToken,
    text,
    labels,
    flags,
    eventId,
    parentId,
  };

  // removes the parameters that are empty
  return pickBy(params);
};

export default class PlaceService extends HttpService {
  constructor(baseURL) {
    if (!PlaceService.instance) {
      super(baseURL);
      PlaceService.instance = this;
    }

    return PlaceService.instance;
  }

  list() {
    return this.get('/api/places')
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  create(args: CreateArgs) {
    const params = buildParams(args);

    const data = new FormData();
    // Loops though all the keys in `args`
    Object.keys(params).forEach((key) => {
      const value = params[key];
      // if it's an array, then loops through it
      // as in: tags, flags
      if (Array.isArray(value)) {
        value.forEach((_value) => data.append(`${key}[]`, _value));
      } else {
        data.append(key, value);
      }
    });
    args.images.forEach((image) =>
      data.append('images', image.blob, image.name)
    );
    return this.post('/api/places/v2', data)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }
  update(args: UpdateArgs) {
    const data = buildParams(args);
    return this.put('/api/places/v1', data)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  delete(id: string) {
    return this.delete(`/api/places/${encodeURIComponent(id)}`)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  googleDetail(_id) {
    return this.get(`/api/places/v1/${_id}/google`, {
      id: _id,
    })
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  detail(args: DetailArgs) {
    const config = {
      params: buildParams(args),
    };

    return this.get(
      `/api/places/v1/pins/${encodeURIComponent(args.id)}`,
      config
    )
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  nearby(args: NearbyArgs) {
    const config = {
      params: buildParams(args),
    };

    return this.get('/api/places/v1/nearby', config)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  search(args: SearchArgs) {
    if (cancelSearchRequest) {
      cancelSearchRequest?.cancel('Cancel previous request');
    }
    // init new cancel request token
    cancelSearchRequest = CancelToken.source();
    const config = {
      params: buildParams(args),
      cancelToken: cancelSearchRequest.token,
    };

    return this.get('/api/places/v1/search_opt', config)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  geojson(args: NearbyArgs) {
    const config = {
      params: buildParams(args),
    };

    return this.get('/api/places/v1/geojson', config)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  stats() {
    return this.get('/api/places/v1/stats')
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  autocomplete(filter: string) {
    const config = {
      params: { filter },
    };

    return this.get('/api/places/v1/autocomplete', config)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  autocompleteDetail(id: string) {
    return this.get(
      `/api/places/v1/autocomplete/detail/${encodeURIComponent(id)}`
    )
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  reviews(id: string) {
    return this.get(`/api/places/v1/${encodeURIComponent(id)}/reviews`)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  updateReview(args: UpdateReviewArgs) {
    const data = buildParams(args);

    return this.put(`/api/reviews/v1/${args._id}`, data)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }
}

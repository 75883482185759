import React, { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';
import reducer from './reducers/directoryReducer';
import initialState from './state/directoryState';

const DirectoryContext = createContext([null, () => null]);

export const DirectoryProvider = (props) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);

  return (
    <DirectoryContext.Provider value={{ state, dispatch }}>
      {props.children}
    </DirectoryContext.Provider>
  );
};

export const useDirectoryContext = () => useContext(DirectoryContext);

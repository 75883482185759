import { connectRouter } from 'connected-react-router';
import Cookies from 'cookies-js';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import addReducer from './addReducer';
import appReducer from './appReducer';
import authReducer from './authReducer';
import autoCompleteReducer from './autoCompleteReducer';
import { componentReducer } from './componentReducer';
import editReducer from './editReducer';
import filterReducer from './filterReducer';
import flagReducer from './flagReducer';
import imageReducer from './imageReducer';
import labelReducer from './labelReducer';
import mapReducer from './mapReducer';
import ratingReducer from './ratingReducer';
import reviewReducer from './reviewReducer';
import searchReducer from './searchReducer';
import statsReducer from './statsReducer';
import tagReducer from './tagReducer';
import toastReducer from './toastReducer';

const authPersistConfig = {
  key: 'auth',
  storage: new CookieStorage(Cookies),
  // save specific keys
  whitelist: [
    'thirdPartyAuth',
    'email',
    'name',
    'token',
    'isAuthenticated',
    'username',
    'usernameUpdatedAt',
    'avatar',
    '_id',
    'isProvider',
  ],
  // don't save specific keys
  blacklist: [''],
};

const createRootReducer = (history) => {
  const rootReducer = combineReducers({
    app: appReducer,
    router: connectRouter(history),
    auth: persistReducer(authPersistConfig, authReducer),
    map: mapReducer,
    filters: filterReducer,
    autoComplete: autoCompleteReducer,
    tags: tagReducer,
    ratings: ratingReducer,
    reviews: reviewReducer,
    toast: toastReducer,
    labels: labelReducer,
    add: addReducer,
    search: searchReducer,
    flags: flagReducer,
    stats: statsReducer,
    component: componentReducer,
    edit: editReducer,
    image: imageReducer,
  });

  return rootReducer;
};

export default createRootReducer;

import {
  AppBar,
  Box,
  Button,
  Container,
  Fab,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { Pagination } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import {
  AddBusinessDialog,
  ControlBox,
  Listing,
  ServicesDialog,
} from '../components/Directory';
import { useDirectoryContext } from '../context';
import { useService } from '../hooks';
import { MainLayout } from '../layouts';
import { config, track } from '../utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    color: theme.palette.text.primary,
    width: '100%',
    height: '100%',
  },
  linkRoot: {
    display: 'block',
  },
  paginationWrapper: {
    minHeight: '40px',
  },
  loader: {
    position: 'absolute',
    top: -theme.spacing(2),
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
    backgroundImage: `linear-gradient(to top left, ${theme.palette.secondary.dark}, ${theme.palette.secondary.main})`,
  },
  headerTitle: {
    textAlign: 'center',
    color: 'white',
    paddingTop: theme.spacing(2, 2, 0, 2),
  },
  headerSubTitle: {
    textAlign: 'center',
    color: 'white',
    maxWidth: 600,
    padding: theme.spacing(0, 2, 3, 2),
  },
  addBusinessButton: {
    width: '100%',
    marginTop: 14,
  },
}));

const DirectoryView = () => {
  const classes = useStyles();
  const [isScollButtonVisible, setIsScollButtonVisible] = useState(false);
  const pageCount = useRef(1);
  // global state
  const { state, dispatch } = useDirectoryContext();
  const {
    resultsPerPage,
    currentPage,
    sortBy,
    orderBy,
    query: queryContext,
    location: locationContext,
    categoryId,
    services,
  } = state;
  // list async request
  const {
    data: listData,
    isLoading,
    errorMessage,
  } = useService({
    service: 'accessFromHomeService',
    method: 'getBusinesses',
    payload: {
      per_page: resultsPerPage,
      page: currentPage,
      sortBy,
      orderBy,
      query: queryContext,
      location: locationContext,
      services,
      category: categoryId,
    },
  });

  // category async request
  const { data: categoryData } = useService({
    service: 'accessFromHomeService',
    method: 'categories',
  });
  // services async request
  const { data: servicesData } = useService({
    service: 'accessFromHomeService',
    method: 'services',
  });

  useEffect(() => {
    track.screen('DirectoryView');
  }, []);

  useEffect(() => {
    dispatch({ type: 'SET_IS_LOADING', payload: isLoading });
  }, [isLoading, dispatch]);

  useEffect(() => {
    if (categoryData) {
      dispatch({ type: 'SET_CATEGORY_OPTIONS', payload: categoryData });
    }
  }, [categoryData, dispatch]);

  useEffect(() => {
    if (servicesData) {
      dispatch({ type: 'SET_SERVICES_OPTIONS', payload: servicesData });
    }
  }, [dispatch, servicesData]);

  const { count = 0, rows: listings = [] } = listData || {};

  useEffect(() => {
    pageCount.current = resultsPerPage ? Math.ceil(count / resultsPerPage) : 1;
  }, [count, resultsPerPage]);

  useEffect(() => {
    const hasVScroll =
      !!listings.length &&
      document.body.scrollHeight > document.body.clientHeight;
    setIsScollButtonVisible(hasVScroll);
  }, [listings]);

  // determine results per page based on default value and number of records
  useEffect(() => {
    const multiplier = Math.ceil(count / config.directory.resultsPerPage);
    const accum = [];
    if (multiplier) {
      let i = 0;
      while (i < multiplier) {
        i++;
        accum.push(i * config.directory.resultsPerPage);
      }
      dispatch({ type: 'SET_RESULTS_PER_PAGE_OPTIONS', payload: accum });
    }
  }, [count, dispatch]);

  const handleClickOpen = () => {
    dispatch({ type: 'SET_ADD_BUSINESS_DIALOG_VISIBLE', payload: true });
  };

  const handlePageChange = (event, value) => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: value });
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <MainLayout>
      <AppBar position="relative" className={classes.header} color="secondary">
        <Typography variant="h1" gutterBottom className={classes.headerTitle}>
          Access From Home
        </Typography>
        <Typography variant="body1" className={classes.headerSubTitle}>
          Connecting you to businesses accessible from home. Discover a universe
          of at home accessible services from groceries, education, healthcare,
          and more.
        </Typography>
      </AppBar>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          <Grid container item xs={12} justify="center">
            <Grid container item xs={8} sm={4} md={3}>
              <Box
                display="flex"
                flex="1"
                justifyContent="center"
                alignItems="center"
                height={200}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Add />}
                  size="large"
                  onClick={handleClickOpen}
                  className={classes.addBusinessButton}
                >
                  Add a business
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="center">
            <ControlBox errorMessage={errorMessage} />
          </Grid>
          <Grid container item xs={12}>
            <Box
              display="flex"
              position="relative"
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              width="100%"
              className={classes.paginationWrapper}
            >
              {isLoading && (
                <LinearProgress
                  color="primary"
                  variant="indeterminate"
                  className={classes.loader}
                />
              )}
              {!!listings.length && (
                <Pagination
                  count={pageCount.current}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              )}
            </Box>
          </Grid>
          {(!!listings.length &&
            listings.map((listing) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={listing._id} zeroMinWidth>
                  <Listing {...listing} key={listing._id} />
                </Grid>
              );
            })) ||
            (!isLoading && (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height={100}
                  pb={2}
                >
                  <Typography variant="h3" color="textSecondary" gutterBottom>
                    No Results
                  </Typography>
                  <Typography variant="body2" align="center">
                    Try adjusting your search to find what you're looking for.
                  </Typography>
                </Box>
              </Grid>
            ))}
          <Grid
            container
            item
            xs={12}
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Box display="flex" alignItems="center" height={120} pb={2}>
              {isScollButtonVisible && (
                <Fab
                  size="large"
                  color="secondary"
                  aria-label="back to top"
                  onClick={handleScrollToTop}
                >
                  <KeyboardArrowUp />
                </Fab>
              )}
            </Box>
            <Box
              display="flex"
              position="relative"
              alignItems="center"
              alignSelf="center"
              height="40"
              mt={2}
              mb={10}
            >
              <Typography variant="body1" style={{ marginRight: 24 }} noWrap>
                Supported by
              </Typography>
              <img
                alt="Canada"
                src={`/images/partners/canada-logo.png`}
                style={{ marginRight: 24, height: 40 }}
              />
              <img
                alt="CIBC"
                src={`/images/partners/cibc-logo.png`}
                height="40px"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <ServicesDialog />
      <AddBusinessDialog />
    </MainLayout>
  );
};

export default DirectoryView;

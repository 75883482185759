import { batch } from 'react-redux';
import { history } from '../';
import { serverErrorsl10n } from '../../l10n/common';
import * as api from '../../services/api';
import { track } from '../../utils';
import { initState } from '../reducers/authReducer';
import {
  statsActions,
  mapActions,
  componentAction,
  editActions,
  addActions,
} from '.';
export const login = ({ email, password }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'LOGIN_IS_LOADING',
      payload: {
        ...initState,
        isLoading: true,
      },
    });

    try {
      const response = await api.authService.login({ email, password });
      track.setUser({ email });
      track.setTag('login', 'email');
      // google analytics
      track.analytics({
        category: 'login',
        data: {
          email,
          method: 'email',
        },
      });
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: {
          isLoading: false,
          isAuthenticated: true,
          errorMessage: '',
          email,
          ...response,
        },
      });
      const { isProvider } = getState().auth;
      if (isProvider) {
        history.push(`/`);
        batch(() => {
          dispatch(statsActions.getStats());
          dispatch(mapActions.resetPlaceDetails());
        });
      } else {
        batch(() => {
          dispatch(statsActions.getStats());
          dispatch(mapActions.listPlacesRequest());
        });
      }
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;
      dispatch({
        type: 'LOGIN_FAILURE',
        payload: {
          isLoading: false,
          errorMessage: errorMessage,
          email,
        },
      });
    }
  };
};

export const fbAuth = () => {
  return async (dispatch) => {
    dispatch({
      type: 'FB_AUTH_IS_LOADING',
      payload: {
        ...initState,
        isLoading: true,
      },
    });
    // eslint-disable-next-line no-undef
    FB.login(
      ({ authResponse, status }) => {
        if (status === 'connected') {
          // eslint-disable-next-line no-undef
          FB.api('/me?fields=id,email,name', async ({ id, name, email }) => {
            // Logged into your webpage and Facebook.
            try {
              const response = await api.userService.createFB({
                id,
                name,
                email,
              });
              // sentry tracking
              track.setUser({ email });
              track.setTag('login', 'facebook');
              // google analytics
              track.analytics({
                category: 'login',
                data: {
                  email,
                  method: 'facebook',
                },
              });
              dispatch({
                type: 'FB_AUTH_SUCCESS',
                payload: {
                  isLoading: false,
                  isAuthenticated: true,
                  thirdPartyAuth: '1',
                  errorMessage: '',
                  ...response,
                },
              });
            } catch (error) {
              const errorMessage =
                serverErrorsl10n.en[error.message] ||
                serverErrorsl10n.en.GENERIC_ERROR;

              dispatch({
                type: 'FB_AUTH_FAILURE',
                payload: {
                  isLoading: false,
                  errorMessage: errorMessage,
                  email,
                },
              });
            }
          });
        } else {
          // The person is not logged into your webpage or we are unable to tell.
          const errorMessage =
            serverErrorsl10n.en[`FACEBOOK_${status.toUpperCase()}`];
          dispatch({
            type: 'FB_AUTH_FAILURE',
            payload: {
              isLoading: false,
              errorMessage: errorMessage,
            },
          });
        }
      },
      { scope: 'public_profile,email' }
    );
  };
};

export const appleAuth = () => {
  return async (dispatch) => {
    dispatch({
      type: 'APPL_AUTH_IS_LOADING',
      payload: {
        ...initState,
        isLoading: true,
      },
    });
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch({
      type: 'LOGOUT_IS_LOADING',
      payload: {
        ...initState,
        isLoading: true,
      },
    });
    try {
      await api.authService.logout();
      dispatch({
        type: 'LOGOUT_SUCCESS',
        payload: initState,
      });
      batch(() => {
        dispatch(statsActions.getStats());
        dispatch(mapActions.listPlacesRequest());
        dispatch(componentAction.setComponentVisible(''));
        dispatch(addActions.reset());
        dispatch(editActions.reset());
      });
    } catch (error) {
      dispatch({
        type: 'LOGOUT_FAILURE',
        payload: {
          isLoading: false,
        },
      });
    }
  };
};
export const subscribeToEvent = (eventCode) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'CLEAR_MESSAGING',
      });
      const response = await api.eventService.subscribeUserToEvent(eventCode);

      // Assuming the response contains the updated user data with eventId
      const { user, message } = response;

      dispatch({
        type: 'SUBSCRIBE_TO_EVENT_SUCCESS',
        payload: {
          eventId: user.eventId,
          event: response.user.event,
          // Assuming you want to store the message in the state as well
          successMessage: message,
        },
      });
    } catch (error) {
      // Handle subscription failure
      console.error('Subscription to event failed:', error);
      dispatch({
        type: 'SUBSCRIBE_TO_EVENT_FAILURE',
        payload: {
          errorMessage:
            serverErrorsl10n.en[error.message] ||
            serverErrorsl10n.en.GENERIC_ERROR,
        },
      });
    }
  };
};

import capitalize from 'lodash.capitalize';
import { flagsl10n, serverErrorsl10n } from '../../l10n/common';
import * as api from '../../services/api';

export const getFlags = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'GET_FLAGS_IS_LOADING',
      payload: {
        isFlagLoading: true,
      },
    });

    try {
      const response = await api.flagService.list();

      const flags = response
        .map((flag) => ({
          key: flag.key,
          displayName: flagsl10n.en[flag.key] || capitalize(flag.key),
        }))
        .filter((flag) => flag.displayName)
        .sort((a, b) => a.displayName.localeCompare(b.displayName));

      dispatch({
        type: 'GET_FLAGS_SUCCESS',
        payload: {
          isFlagLoading: false,
          values: flags,
        },
      });
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;

      dispatch({
        type: 'GET_FLAGS_FAILURE',
        payload: {
          isFlagLoading: false,
          flagErrorMsg: errorMessage,
        },
      });
    }
  };
};

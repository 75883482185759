export const initialState = {
  places: [],
  location: {
    latitude: 5,
    longitude: -25,
  },
  boundaries: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  selectedPlaceDetails: {
    _id: '',
    name: '',
    address: '',
    distanceIn: {},
    phone: '',
    url: '',
    latitude: 0,
    longitude: 0,
    tags: [],
    labels: [],
    flags: [],
    rating: '',
    description: '',
    descriptionTranslated: '',
    urlDisplay: '',
    placeType: '',
    images: [],
    servicePlaceId: null,
  },
  isLoading: false,
  errorMessage: '',
};

const mapReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'UPDATE_PLACE_DETAILS_IS_LOADING':
    case 'UPDATE_PLACE_DETAILS_SUCCESS':
    case 'UPDATE_PLACE_DETAILS_FAILURE':
      return {
        ...state,
        ...payload,
      };
    case 'RESET_PLACE_DETAILS':
      return {
        ...state,
        selectedPlaceDetails: initialState.selectedPlaceDetails,
      };
    case 'UPDATE_MAP_LOCATION':
      console.log('LOCATION - ', payload);
      return {
        ...state,
        location: payload,
      };
    case 'UPDATE_MAP_BOUNDARIES':
      return {
        ...state,
        boundaries: payload,
      };
    case 'LIST_PLACES_REQUEST':
      return {
        ...state,
        isLoading: true,
        done: false,
      };
    case 'LIST_PLACES_SUCCESS':
      return {
        ...state,
        isLoading: false,
        done: true,
        places: payload,
      };
    case 'LIST_PLACES_FAILURE':
      return {
        ...state,
        isLoading: false,
        places: [],
        ...payload,
      };
    default:
      return state;
  }
};

export default mapReducer;

const initState = {
  componentId: '',
};

export const componentReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_COMPONENT_VISIBLE':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

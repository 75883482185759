import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import DialogForm from '../components/DialogForm';
import { accountActions } from '../store/actions';

const validationSchema = (t) => {
  return yup.object().shape({
    newPassword: yup
      .string()
      .required(t('messages.requiredField'))
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, {
        message: t('messages.passwordFormatError'),
        excludeEmptyString: true,
      }),
    confirmPassword: yup
      .string()
      .required(t('messages.requiredField'))
      .oneOf([yup.ref('newPassword'), null], t('messages.passwordMatchError')),
  });
};

const ChangePasswordDialog = (props) => {
  const { scrollContentToTop } = props;
  const dispatch = useDispatch();
  const successMessage = useSelector((state) => state.auth.successMessage);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const { t } = useTranslation();

  const { register, handleSubmit, getValues, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema(t)),
    submitFocusError: true,
  });

  const onSubmit = () => {
    if (!isLoading) {
      dispatch(accountActions.changePassword(getValues()));
    }
  };

  return (
    <DialogForm
      onSubmit={handleSubmit(onSubmit)}
      successMessage={successMessage}
      errorMessage={errorMessage}
      isLoading={isLoading}
      hideFormOnSuccess
      scrollContentToTop={scrollContentToTop}
    >
      <TextField
        id="password"
        type="password"
        label={t('messages.newPassword')}
        name="newPassword"
        margin="dense"
        error={!!errors.newPassword}
        helperText={errors.newPassword?.message}
        inputRef={register}
        fullWidth
      />
      <TextField
        id="confirmPassword"
        type="password"
        label={t('messages.confirmPassword')}
        name="confirmPassword"
        margin="dense"
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword?.message}
        inputRef={register}
        fullWidth
      />
      <Box mt={2}>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          {t('messages.updatePassword')}
        </Button>
      </Box>
    </DialogForm>
  );
};

export default ChangePasswordDialog;

const initState = {
  images: [],
};

const imageReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SELECT_IMAGE':
      return {
        ...state,
        images: [...state.images, ...payload].slice(0, 10),
      };
    case 'DESELECT_IMAGE':
      return {
        ...state,
        images: state.images.filter((image) => image.name !== payload),
      };
    case 'IMAGE_RESET':
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default imageReducer;

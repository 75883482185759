import { config } from '../../utils';
import AccessFromHomeService from './access-from-home-service';
import AuthService from './auth-service';
import EventService from './event-service';
import FlagService from './flag-service';
import LabelService from './label-service';
import PlaceService from './place-service';
import RatingService from './rating-service';
import TagService from './tag-service';
import UserService from './user-service';

const authService = new AuthService(config.apiHost);
const labelService = new LabelService(config.apiHost);
const placeService = new PlaceService(config.apiHost);
const ratingService = new RatingService(config.apiHost);
const tagService = new TagService(config.apiHost);
const userService = new UserService(config.apiHost);
const accessFromHomeService = new AccessFromHomeService(config.apiHost);
const flagService = new FlagService(config.apiHost);
const eventService = new EventService(config.apiHost);

export {
  authService,
  labelService,
  placeService,
  ratingService,
  tagService,
  userService,
  accessFromHomeService,
  flagService,
  eventService,
};

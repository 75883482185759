import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { TextButton, ThirdPartyButton } from '../components';
import DialogForm from '../components/DialogForm';
import { useDialogContext } from '../context';
import * as accountActions from '../store/actions/accountActions';
import { config } from '../utils';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginLeft: -12,
  },
  checkboxError: {
    color: theme.palette.error.main,
  },
  checkboxErrorText: {
    marginTop: -4,
  },
  buttons: {
    '& button': {
      marginBottom: theme.spacing(2),
    },
  },
  policyText: {
    marginBottom: theme.spacing(1),
  },
}));

const validationSchema = (t) => {
  return yup.object().shape({
    name: yup.string().required(t('messages.requiredField')),
    username: yup
      .string()
      .required(t('messages.requiredField'))
      .matches(/^([0-9a-zA-Z]){6,50}$/, {
        message: t('messages.usernameFormatError'),
      }),
    email: yup
      .string()
      .required(t('messages.requiredField'))
      .email(t('messages.emailFormatError')),
    password: yup
      .string()
      .required(t('messages.requiredField'))
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, {
        message: t('messages.passwordFormatError'),
        excludeEmptyString: true,
      }),
    confirmPassword: yup
      .string()
      .required(t('messages.requiredField'))
      .oneOf([yup.ref('password'), null], t('messages.passwordMatchError')),
  });
};

const CreateAccountDialog = (props) => {
  const { scrollContentToTop } = props;
  const dispatch = useDispatch();
  const { dialogDispatch } = useDialogContext();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const classes = useStyles();
  const recaptchaRef = useRef();
  const { t } = useTranslation();

  const { register, handleSubmit, getValues, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema(t)),
    submitFocusError: true,
  });
  useEffect(() => {
    if (isAuthenticated) {
      dialogDispatch({ type: 'CLOSE' });
    }
  }, [dialogDispatch, isAuthenticated]);
  const onReCaptchaChallenge = () => {
    const cyCookie = document.cookie.split(';').find((row) => {
      return row.includes('cypress');
    });

    if (!!cyCookie) {
      onRecaptchaSubmit('cyCookie123');
    } else {
      recaptchaRef.current.execute();
    }
  };

  const onRecaptchaSubmit = (token) => {
    if (token && !isLoading) {
      const reqObj = {
        ...getValues(),
        recaptcha: token,
      };
      dispatch(accountActions.createAccount(reqObj));
      recaptchaRef.current.reset();
    }
  };

  const onLoginPasswordDialog = () =>
    dialogDispatch({ type: 'OPEN_AS', payload: 'login' });

  return (
    <DialogForm
      onSubmit={handleSubmit(onReCaptchaChallenge)}
      errorMessage={errorMessage}
      isLoading={isLoading}
      scrollContentToTop={scrollContentToTop}
    >
      <TextField
        id="name"
        label={t('messages.name')}
        name="name"
        margin="dense"
        error={!!errors.name}
        helperText={errors.name?.message}
        inputRef={register}
        fullWidth
      />
      <TextField
        id="userName"
        label={t('messages.username')}
        name="username"
        margin="dense"
        error={!!errors.username}
        helperText={errors.username?.message}
        inputRef={register}
        fullWidth
      />
      <TextField
        id="email"
        type="email"
        label={t('messages.email')}
        name="email"
        margin="dense"
        error={!!errors.email}
        helperText={errors.email?.message}
        inputRef={register}
        fullWidth
      />
      <TextField
        id="password"
        type="password"
        label={t('messages.password')}
        name="password"
        margin="dense"
        error={!!errors.password}
        helperText={errors.password?.message}
        inputRef={register}
        fullWidth
      />
      <TextField
        id="confirmPassword"
        type="password"
        label={t('messages.confirmPassword')}
        name="confirmPassword"
        margin="dense"
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword?.message}
        inputRef={register}
        fullWidth
      />
      <Box display="flex" alignItems="center" mt={2}>
        <Typography
          className={classes.policyText}
          color="textSecondary"
          variant="body2"
        >
          {t('summaries.disclosure.partOne')}
          <Link
            color="primary"
            href="https://accessnow.com/terms-and-conditions"
            underline="always"
            variant="h6"
            rel="noopener"
            target="_blank"
          >
            {t('summaries.disclosure.termsAndConditions')}
          </Link>
          {t('summaries.disclosure.partTwo')}
          <Link
            color="primary"
            href="https://accessnow.com/privacy-policy"
            underline="always"
            variant="h6"
            rel="noopener"
            target="_blank"
          >
            {t('summaries.disclosure.privacyPolicy')}
          </Link>
          {t('summaries.disclosure.partThree')}
          <Link
            color="primary"
            href="https://www.iubenda.com/privacy-policy/93078816/cookie-policy?an=no&s_ck=false&newmarkup=yes"
            underline="always"
            variant="h6"
            rel="noopener"
            target="_blank"
          >
            {t('summaries.disclosure.cookiesPolicy')}
          </Link>
          .
        </Typography>
      </Box>
      <Box mt={2} className={classes.buttons}>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={handleSubmit(onReCaptchaChallenge)}
          fullWidth
        >
          {t('messages.createAccount')}
        </Button>
        <ThirdPartyButton
          variant="facebook"
          text={t('messages.continueWithFacebook')}
          fullWidth
        />
      </Box>
      <TextButton
        label={t('messages.alreadyHaveAnAccount')}
        onClick={onLoginPasswordDialog}
      />
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={config.recaptcha.siteKey}
        onChange={onRecaptchaSubmit}
      />
    </DialogForm>
  );
};

export default CreateAccountDialog;

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConnectedRouter } from 'connected-react-router';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import validate from 'validate.js';
import AppBanner from './components/AppBanner';
import { RootProvider } from './context';
import Routes from './Routes';
import { history } from './store';
import theme from './theme';
import { FacebookProvider, validators } from './utils';
import { appInit } from './utils/appInit';
import config from './utils/config';

// initialize app
appInit();

validate.validators = {
  ...validate.validators,
  ...validators,
};

Sentry.init({
  dsn: 'https://54cdd92a16ed4d8abf0298e1647b2668@sentry.io/1761239',
  environment: config.environment,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1,
  debug: true,
});

Sentry.setTags({
  host: config.host,
  apiHost: config.apiHost,
});

const App = () => {
  const { themeMode } = useSelector((state) => state.app);
  const isLight = themeMode === 'light';
  const activeTheme = isLight ? theme.light : theme.dark;
  const muiTheme = createTheme(activeTheme);

  dayjs.extend(utc);
  dayjs.extend(relativeTime);

  useEffect(() => {
    const addFacebookScript = async () => {
      await new FacebookProvider();
    };

    addFacebookScript();
  }, []);

  return (
    <ThemeProvider theme={muiTheme}>
      <RootProvider>
        <AppBanner isLight={isLight} />
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </RootProvider>
    </ThemeProvider>
  );
};

export default Sentry.withProfiler(App);

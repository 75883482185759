import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import { config } from '../utils';
import createRootReducer from './reducers';
import rootSaga from './sagas';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const configureStore = (initialState = {}) => {
  const enhancers = [
    Sentry.createReduxEnhancer(),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (noop) => noop,
  ];
  const middlewares = [
    thunkMiddleware,
    sagaMiddleware,
    routerMiddleware(history),
  ];

  if (config.environment !== 'production') {
    const { createLogger } = require('redux-logger');

    const logger = createLogger({
      collapsed: true,
    });

    middlewares.push(logger);
  }

  const composedEnhancers = compose(
    applyMiddleware(...middlewares),
    ...enhancers
  );

  const store = createStore(
    createRootReducer(history),
    initialState,
    composedEnhancers
  );

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { persistor, store };
};

export default configureStore;

import {
  Breadcrumbs,
  Divider,
  Link,
  Paper,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Phone, Public } from '@material-ui/icons';
import React from 'react';
import { categoriesl10n } from '../../l10n/common';
import { formatServices, track } from '../../utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    height: '100%',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  linkRoot: {
    display: 'block',
  },
  lineItemRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    width: '80%',
  },
  lineItemIcon: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-start',
    color: grey[400],
  },
  servicesWrapper: {
    marginTop: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(0.7, 0, 1, 0),
  },
  breadcrumb: {
    color: theme.palette.primary.main,
  },
  breadcrumbText: {
    color: theme.palette.primary.main,
  },
}));

const Listing = ({
  name,
  phone,
  phoneExt,
  url,
  urlDisplay,
  services,
  category,
}) => {
  const classes = useStyles();

  const servicesParagraph = formatServices(services)
    .map((service) => service.displayName)
    .join(', ');

  const parentCategory =
    category.parent &&
    (categoriesl10n.en[category.parent.key] || category.parent.key);
  const subCategory = categoriesl10n.en[category.key] || category.key;

  const handlePhoneClick = () => {
    track.analytics({
      category: 'directory_phone_clicked',
      data: {
        phone,
      },
    });
  };

  const handleUrlClick = () => {
    track.analytics({
      category: 'directory_url_clicked',
      data: {
        url,
      },
    });
  };

  return (
    <Paper className={classes.paper}>
      <div>
        <Breadcrumbs
          className={classes.breadcrumb}
          separator="›"
          aria-label="breadcrumb"
        >
          {parentCategory && (
            <Typography className={classes.breadcrumbText} variant="body2">
              {parentCategory}
            </Typography>
          )}
          <Typography className={classes.breadcrumbText} variant="body2">
            {subCategory}
          </Typography>
        </Breadcrumbs>
        <Typography variant="h4" className={classes.title}>
          {name}
        </Typography>
        {phone && (
          <div className={classes.lineItemRow}>
            <Phone fontSize="small" className={classes.lineItemIcon} />
            <Link
              classes={{
                root: classes.linkRoot,
              }}
              href={phoneExt ? `tel:${phone},${phoneExt}` : `tel:${phone}`}
              variant="body2"
              noWrap
              onClick={handlePhoneClick}
              color="secondary"
            >
              {phoneExt ? `${phone} ext.${phoneExt}` : phone}
            </Link>
          </div>
        )}
        {url && (
          <div className={classes.lineItemRow}>
            <Public fontSize="small" className={classes.lineItemIcon} />
            <Link
              classes={{
                root: classes.linkRoot,
              }}
              href={url}
              variant="body2"
              noWrap
              target="_blank"
              rel="noopener"
              onClick={handleUrlClick}
              color="secondary"
            >
              {urlDisplay}
            </Link>
          </div>
        )}
      </div>
      {!!services.length && (
        <div className={classes.servicesWrapper}>
          <Typography display="block" variant="h5">
            Services
          </Typography>
          <Divider variant="fullWidth" className={classes.divider} />
          <Typography variant="body2" display="inline">
            {servicesParagraph}
          </Typography>
        </div>
      )}
    </Paper>
  );
};

export default Listing;

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreHorizRounded } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useDialogContext } from '../context';
import {
  reviewActions,
  addActions,
  editActions,
  mapActions,
  componentAction,
} from '../store/actions';
import { date } from '../utils';
import getQueryStringLanguage from '../utils/helpers';

const ReviewMenu = ({ deleteReview, review }) => {
  const dispatch = useDispatch();
  const {
    _id,
    text,
    updatedAt,
    createdAt,
    promoted,
    tags,
    rating,
    place,
    images,
  } = review;
  const location = useLocation();
  const { t } = useTranslation();
  const { isProvider } = useSelector((state) => state.auth);
  const history = useHistory();
  const authUser = useSelector((state) => state.auth);
  const selectedPlaceDetails = useSelector(
    (state) => state.map.selectedPlaceDetails
  );
  const { dialogDispatch } = useDialogContext();

  const [reviewOptionsAnchorEl, setReviewOptionsAnchorEl] = useState(null);
  const open = Boolean(reviewOptionsAnchorEl);

  const EDIT_REVIEW_TIME_CONSTRAINT = 90; //in minutes
  const onClickReviewOptionsIcon = (event) => {
    setReviewOptionsAnchorEl(event.currentTarget);
  };
  const onClickEditReview = async () => {
    dispatch(
      editActions.update({
        _id: _id,
        rating: rating,
        tags: tags,
        text: text,
        images: images,
      })
    );
    if (place) {
      try {
        await dispatch(mapActions.placeDetails({ _id: place._id }));
      } catch (error) {
        console.log(error);
      }
      dispatch(
        addActions.update({
          ...place,
          tags: tags,
          description: '',
          text: text,
          rating: rating,
          images: images,
          eventId: authUser.eventId,
        })
      );
      dialogDispatch({ type: 'CLOSE' });
      history.push('/add');
    } else {
      dispatch(
        addActions.update({
          ...selectedPlaceDetails,
          tags: tags,
          description: '',
          text: text,
          rating: rating,
          images: images,
          eventId: authUser.eventId,
        })
      );
      history.push('/add');
    }
  };
  const renderReviewMenuOptions = (option) => {
    let dateNow = new Date().toISOString();

    let difference = date.differenceBetweenTwoDates(
      updatedAt !== null ? updatedAt : createdAt,
      dateNow
    );
    const lngQueryParam = getQueryStringLanguage();
    const handleEditClick = () => {
      if (difference >= EDIT_REVIEW_TIME_CONSTRAINT && promoted !== true) {
        dispatch(
          editActions.editReviewError({
            _id: _id,
            errorMessage: t('messages.editReviewError'),
          })
        );
      } else {
        if (isProvider && (!rating || rating === 'unrated')) {
          dispatch(componentAction.setComponentVisible(''));

          dispatch(
            editActions.update({
              _id: _id,
              text: text,
            })
          );
          dispatch(
            addActions.update({
              ...selectedPlaceDetails,
              description: '',
            })
          );
          setReviewOptionsAnchorEl(null);
          if (
            place &&
            location.pathname ===
              `/details/${selectedPlaceDetails._id}/local/${lngQueryParam}`
          ) {
            dialogDispatch({ type: 'CLOSE' });
          } else if (place) {
            history.push(`/details/${place._id}/local/${lngQueryParam}`);
            dialogDispatch({ type: 'CLOSE' });
          }
        } else {
          onClickEditReview();
        }
      }
    };
    if (
      option === 'Edit' &&
      difference <= EDIT_REVIEW_TIME_CONSTRAINT &&
      promoted !== true
    ) {
      return (
        <MenuItem
          key={option}
          value={option}
          onClick={() => {
            handleEditClick();
            setReviewOptionsAnchorEl(null);
          }}
        >
          {t('options.Edit')}
        </MenuItem>
      );
    }
    if (promoted !== true && option === 'Remove') {
      return (
        <MenuItem
          key={option}
          value={option}
          onClick={() => {
            dispatch(reviewActions.deleteReview(_id));
            setReviewOptionsAnchorEl(null);
          }}
        >
          {t('options.Remove')}
        </MenuItem>
      );
    }
  };

  return (
    <div>
      <IconButton
        style={{ padding: 0 }}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={onClickReviewOptionsIcon}
      >
        <MoreHorizRounded htmlColor="grey" />
      </IconButton>
      <Menu
        id="review-options-menu"
        anchorEl={reviewOptionsAnchorEl}
        keepMounted
        open={open}
        onClose={() => setReviewOptionsAnchorEl(null)}
        PaperProps={{
          style: {
            width: '15ch',
          },
        }}
      >
        {['Edit', 'Remove'].map((option) => {
          return renderReviewMenuOptions(option);
        })}
      </Menu>
    </div>
  );
};

export default ReviewMenu;

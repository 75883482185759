import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      background: 'none',
    },
  },
  label: {
    textTransform: 'none',
  },
}));

const TextButton = ({ label, onClick, ...other }) => {
  const classes = useStyles();
  return (
    <Button
      color="primary"
      size="large"
      onClick={() => onClick && onClick()}
      classes={{
        root: classes.root,
        label: classes.label,
        startIcon: classes.icon,
      }}
      {...other}
    >
      {label}
    </Button>
  );
};

export default TextButton;

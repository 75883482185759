import {
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import {
  Menu as MenuIcon,
  Brightness7 as SunIcon,
  Brightness4 as MoonIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useFetch } from '../hooks';
import { setTheme } from '../store/actions/globalActions';
import { config } from '../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
    height: 73,
    width: '100%',
    backgroundColor: theme.palette.background.main,
    padding: '0px 30px',
  },
  greyBorderBottom: {
    borderBottom: `solid 1px ${theme.palette.border.main}`,
  },
  logoLink: {
    marginLeft: 6,
    lineHeight: 0,
  },
  logo: {
    width: 38,
    height: 38,
  },
  ul: {
    listStyle: 'none',
    padding: 0,
  },
  li: {
    display: 'inline',
    marginLeft: 26,
  },
  link: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.navLink,
    '&:hover': {
      color: theme.palette.grey.light,
    },
  },
  activeBlue: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  menuIcon: {
    color: theme.palette.primary.main,
  },
}));

const StyledMenu = withStyles((theme) => ({
  paper: {
    borderRadius: '0px 0px 4px 4px',
    borderTop: `4px solid ${theme.palette.primary.main}`,
    width: '100%',
    padding: theme.spacing(2, 4, 4, 4),
  },
  list: {
    padding: 0,
  },
}))((props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 73,
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
}))(MenuItem);

const Header = ({ location, position = 'fixed' }) => {
  const classes = useStyles();
  const [activeTitle, setActiveTitle] = useState('');
  const [isMenuVisible, setMenuVisible] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const anchorRef = useRef(null);
  const { themeMode } = useSelector((state) => state.app);
  const isLight = themeMode === 'light';
  const { data } = useFetch({
    url: `${config.wpApiHost}/wp-json/menus/v1/menus/accessnow`,
  });
  const items = (data && data.items) || [];
  const { t } = useTranslation();

  useEffect(() => {
    switch (location.pathname) {
      case '/access-from-home':
        setActiveTitle('Access From Home');
        break;
      default:
        setActiveTitle('Map');
        break;
    }
  }, [location.pathname]);

  const handleClick = (event) => {
    setMenuVisible(true);
  };

  const handleClose = () => {
    setMenuVisible(false);
  };

  const toggleTheme = useCallback(() => {
    dispatch(setTheme(isLight ? 'dark' : 'light'));
  }, [dispatch, isLight]);

  return (
    <div
      className={clsx(
        classes.container,
        location.pathname !== '/access-from-home' && classes.greyBorderBottom
      )}
      style={{ position }}
      ref={anchorRef}
    >
      {items.map(({ ID, title, url }) => {
        return (
          title === 'Home' && (
            <a href={url} className={classes.logoLink} key={ID}>
              <img
                alt="AccessNow"
                src={`/images/logo/arrow-blue.svg`}
                className={classes.logo}
              />
            </a>
          )
        );
      })}
      {!isMobile ? (
        <nav>
          <ul className={classes.ul}>
            <IconButton aria-controls="theme-switcher" onClick={toggleTheme}>
              {isLight ? (
                <MoonIcon className={classes.menuIcon} />
              ) : (
                <SunIcon className={classes.menuIcon} />
              )}
            </IconButton>
            {items.map(({ ID, title, url }) => {
              return (
                <li className={classes.li} key={ID}>
                  <a
                    href={url}
                    className={clsx(
                      classes.link,
                      title === activeTitle && classes.activeBlue
                    )}
                  >
                    {t(`titles.${title}`)}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      ) : (
        <div>
          <IconButton aria-controls="theme-switcher" onClick={toggleTheme}>
            {isLight ? (
              <MoonIcon className={classes.menuIcon} />
            ) : (
              <SunIcon className={classes.menuIcon} />
            )}
          </IconButton>
          <IconButton
            aria-controls="mobile-menu"
            onClick={handleClick}
            aria-haspopup="true"
          >
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
          <StyledMenu
            id="mobile-menu"
            anchorEl={anchorRef.current}
            keepMounted
            open={isMenuVisible}
            onClose={handleClose}
            transitionDuration={150}
          >
            {items.map(({ ID, title, url }) => {
              return (
                <StyledMenuItem
                  onClick={() => {
                    window.location = url;
                    handleClose();
                  }}
                  key={ID}
                >
                  <ListItemText
                    primary={t(`titles.${title}`)}
                    className={clsx(
                      title === activeTitle && classes.activeBlue
                    )}
                    primaryTypographyProps={{
                      className: clsx(
                        title === activeTitle && classes.activeBlue
                      ),
                    }}
                  />
                </StyledMenuItem>
              );
            })}
          </StyledMenu>
        </div>
      )}
    </div>
  );
};

export default withRouter(Header);

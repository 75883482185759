import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { TextButton, ThirdPartyButton } from '../components';
import DialogForm from '../components/DialogForm';
import { useDialogContext } from '../context';
import * as authActions from '../store/actions/authActions';

const useStyles = makeStyles((theme) => ({
  buttons: {
    '& button': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const validationSchema = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .required(t('messages.requiredField'))
      .email(t('messages.emailFormatError')),
    password: yup.string().required(t('messages.requiredField')),
  });
};

const LoginDialog = (props) => {
  const { scrollContentToTop } = props;
  const dispatch = useDispatch();
  const { dialogDispatch } = useDialogContext();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const classes = useStyles();
  const { t } = useTranslation();

  const { register, handleSubmit, getValues, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema(t)),
    submitFocusError: true,
  });

  useEffect(() => {
    if (isAuthenticated) {
      dialogDispatch({ type: 'CLOSE' });
    }
  }, [dialogDispatch, isAuthenticated]);

  const onSubmit = () => {
    !isLoading && dispatch(authActions.login(getValues()));
  };

  const onForgotPasswordDialog = () =>
    dialogDispatch({ type: 'OPEN_AS', payload: 'forgotPassword' });

  const onCreateAccountDialog = () =>
    dialogDispatch({ type: 'OPEN_AS', payload: 'createAccount' });

  return (
    <DialogForm
      onSubmit={handleSubmit(onSubmit)}
      errorMessage={errorMessage}
      isLoading={isLoading}
      scrollContentToTop={scrollContentToTop}
    >
      <TextField
        id="email"
        type="email"
        label={t('messages.email')}
        name="email"
        margin="dense"
        error={!!errors.email}
        helperText={errors.email?.message || ''}
        inputRef={register}
        fullWidth
      />
      <TextField
        id="password"
        type="password"
        label={t('messages.password')}
        name="password"
        margin="dense"
        error={!!errors.password}
        helperText={errors.password?.message || ''}
        inputRef={register}
        fullWidth
      />
      <Box mt={2} className={classes.buttons}>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          {t('messages.login')}
        </Button>
        <ThirdPartyButton
          variant="facebook"
          text={t('messages.continueWithFacebook')}
          fullWidth
        />
      </Box>
      <TextButton
        label={t('messages.forgotYourPassword')}
        onClick={onForgotPasswordDialog}
      />
      <br />
      <TextButton
        label={t('messages.needToCreateAnAccount')}
        onClick={onCreateAccountDialog}
      />
    </DialogForm>
  );
};

export default LoginDialog;

import capitalize from 'lodash.capitalize';
import { serverErrorsl10n, tagsl10n } from '../../l10n/common';
import * as api from '../../services/api';

export const getTags = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'GET_TAGS_IS_LOADING',
      payload: {
        isTagLoading: true,
      },
    });

    try {
      const response = await api.tagService.list();
      const categories = response.map((category) => ({
        category: category.category,
        tags: category.tags.map((tag) => ({
          key: tag,
          displayName: tagsl10n.en[tag] || capitalize(tag),
        })),
      }));

      dispatch({
        type: 'GET_TAGS_SUCCESS',
        payload: {
          isTagLoading: false,
          categories: categories,
        },
      });
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;

      dispatch({
        type: 'GET_TAGS_FAILURE',
        payload: {
          isTagLoading: false,
          tagErrorMsg: errorMessage,
        },
      });
    }
  };
};

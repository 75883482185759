import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDialogContext } from '../context';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minWidth: 360,
    minHeight: 16,
    width: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textField: {
    width: '100%', // Make the TextField full width
    marginBottom: theme.spacing(2),
  },
  text: {
    margin: 10,
    textAlign: 'center',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: 10,
    alignSelf: 'center',
  },
}));

const SuccessDialog = ({ desc1 = '', desc2 = '', buttonText }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { dialogDispatch } = useDialogContext();
  const onCancel = () => {
    dialogDispatch({ type: 'CLOSE' });
  };
  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.text} variant="body1">
          {t('dialogTitles.successDialog')}
        </Typography>
        <Typography className={classes.text} variant="body1">
          {desc1}
        </Typography>
        <Typography className={classes.text} variant="body1">
          {desc2}
        </Typography>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={onCancel}
        >
          {buttonText || t('messages.done')}
        </Button>
      </div>
    </>
  );
};

export default SuccessDialog;

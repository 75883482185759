import axios from 'axios';
import { useEffect, useState } from 'react';
import { serverErrorsl10n } from '../l10n/common';

export default ({ url }) => {
  const [state, setState] = useState({
    data: null,
    isLoading: false,
    errorMessage: '',
  });
  useEffect(() => {
    let isMounted = true;
    setState((state) => ({ data: state.data, isLoading: true }));
    axios({ url })
      .then(({ data = {} }) => {
        isMounted && setState({ data, isLoading: false, errorMessage: '' });
      })
      .catch((error) => {
        const errorMessage =
          serverErrorsl10n.en[error.message] ||
          serverErrorsl10n.en.GENERIC_ERROR;
        isMounted && setState({ isLoading: false, errorMessage });
      });
    return () => {
      isMounted = false;
    };
  }, [url]);
  return state;
};

/* eslint-disable no-shadow */
import { serverErrorsl10n } from '../../l10n/common';
import * as api from '../../services/api';
import { DetailArgs } from '../../services/api/types';
import { formatLabels } from '../../utils';
import * as reviewActions from './reviewActions';
import * as searchActions from './searchActions';

export const placeDetails = ({ _id, isGoogle = false }) => {
  return async (dispatch, getState) => {
    const { map } = getState();
    const { location } = map;

    const reqObj: DetailArgs = {
      location,
      id: _id,
    };

    dispatch({
      type: 'UPDATE_PLACE_DETAILS_IS_LOADING',
      payload: {
        isLoading: true,
      },
    });

    try {
      let response;
      if (isGoogle) {
        response = await api.placeService.googleDetail(_id);
      } else {
        response = await api.placeService.detail(reqObj);
      }

      dispatch({
        type: 'UPDATE_PLACE_DETAILS_SUCCESS',
        payload: {
          isLoading: false,
          selectedPlaceDetails: {
            ...response,
            labels: formatLabels(response.labels),
          },
        },
      });
      if (response.rating !== 'unrated' && !isGoogle) {
        // only make call if the place is rated (has reviews)
        reviewActions.reviewsByPlace(response._id)(dispatch);
      } else {
        reviewActions.resetReviewsByPlace()(dispatch);
      }
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;

      dispatch({
        type: 'UPDATE_PLACE_DETAILS_FAILURE',
        payload: {
          isLoading: false,
          errorMessage,
        },
      });
    }
  };
};

export const resetPlaceDetails = () => ({
  type: 'RESET_PLACE_DETAILS',
});

export const updateLocation = (payload) => ({
  type: 'UPDATE_MAP_LOCATION',
  payload,
});

export const updateBoundaries = (payload) => ({
  type: 'UPDATE_MAP_BOUNDARIES',
  payload,
});

export const listPlacesRequest = () => ({
  type: 'LIST_PLACES_REQUEST',
});

export const listPlacesSuccess = (payload) => ({
  type: 'LIST_PLACES_SUCCESS',
  payload,
});

export const listPlacesFailure = (payload) => ({
  type: 'LIST_PLACES_FAILURE',
  payload,
});

export const searchMapBoundaries = (map) => {
  return (dispatch) => {
    const getBounds = map.getBounds();

    if (getBounds) {
      const boundaries = {
        top: getBounds.getNorthEast().lat(),
        right: getBounds.getNorthEast().lng(),
        bottom: getBounds.getSouthWest().lat(),
        left: getBounds.getSouthWest().lng(),
      };

      const getLatLng = map.getCenter();
      const location = {
        latitude: getLatLng.lat(),
        longitude: getLatLng.lng(),
      };

      dispatch(updateBoundaries(boundaries));
      dispatch(searchActions.updateLocation({ location }));
      dispatch(listPlacesRequest());
    }
  };
};

import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { Message } from './';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: 360,
  },
  form: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(0.5),
    },
  },
  message: {
    margin: theme.spacing(1, 0),
  },
  loader: {
    position: 'absolute',
    width: '100%',
  },
}));

const DialogForm = (props) => {
  const {
    children,
    onSubmit,
    isLoading,
    successMessage,
    errorMessage,
    hideFormOnSuccess,
    scrollContentToTop,
  } = props;
  const classes = useStyles();
  useEffect(() => {
    if (successMessage || errorMessage) {
      scrollContentToTop();
    }
  }, [successMessage, errorMessage, scrollContentToTop]);

  return (
    <div className={classes.container}>
      {isLoading && (
        <LinearProgress color="primary" className={classes.loader} />
      )}
      {successMessage || errorMessage ? (
        <Message
          variant={(successMessage && 'success') || 'error'}
          message={successMessage || errorMessage}
          role="alert"
          className={classes.message}
          data-cy={(successMessage && 'successMessage') || 'errorMessage'}
        />
      ) : null}
      {hideFormOnSuccess ? (
        !successMessage && (
          <form onSubmit={onSubmit} className={classes.form}>
            {children}
          </form>
        )
      ) : (
        <form onSubmit={onSubmit} className={classes.form}>
          {children}
        </form>
      )}
    </div>
  );
};

export default DialogForm;

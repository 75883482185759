import MuiAutocomplete from './MuiAutocomplete';
import MuiButton from './MuiButton';
import MuiDialog from './MuiDialog';
import MuiIconButton from './MuiIconButton';
import MuiLinearProgress from './MuiLinearProgress';
import MuiList from './MuiList';
import MuiListItem from './MuiListItem';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiListItem,
  MuiList,
  MuiAutocomplete,
  MuiDialog,
  MuiLinearProgress,
};

export default {
  inputRoot: {
    '&[class*="root"]': {
      paddingRight: '0px !important',
    },
  },
  popper: {
    marginTop: -4,
  },
};

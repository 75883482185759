/* eslint-disable no-undef */
import MarkerClusterer from '@google/markerclustererplus';
import {
  LinearProgress,
  makeStyles,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useGoogleMap } from '@react-google-maps/api';
import Axios from 'axios';
import camelCase from 'lodash.camelcase';
import capitalize from 'lodash.capitalize';
import cloneDeep from 'lodash.clonedeep';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { mapActions, searchActions } from '../../store/actions';
import { config, isPlace } from '../../utils';
import getQueryStringLanguage from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  alertText: {
    color: theme.palette.primary.contrastText,
  },
}));

let markerClusterer = null;
let markers = [];
let amenities = [];
let infowindow;

const amenityList = {
  accessPoint: 'Access Point',
  bench: 'Bench',
  informationarea: 'Information Area',
  park: 'Park',
  parking: 'Parking',
  picnicTable: 'Picnic Table',
  washroom: 'Washroom',
  hazard: 'Hazard',
  closure: 'Closure',
  pointOfInterest: 'Point of Interest',
  waterFountain: 'Water Fountain',
};

const instantiateMarker = () => {
  if (infowindow) {
    infowindow.close();
  }
  infowindow = infowindow || new google.maps.InfoWindow();

  return new google.maps.Marker();
};

const getIconName = (placeType, rating) => {
  if (placeType === 'place') return rating;
  return `${placeType}_${rating}`;
};

const getTrailStyle = (feature) => {
  const trailColor = feature.getProperty('rateColor');
  return {
    strokeWeight: 4,
    strokeColor: trailColor,
  };
};

const cloneMarker = (place, baseMarker = instantiateMarker()) => {
  const placeType = place.placeType;
  const placeTypeCamelCase = camelCase(placeType);
  const isLocationPlace = isPlace(placeType);
  const height = isLocationPlace ? 30 : 21;
  const iconName = getIconName(placeTypeCamelCase, place.rating);
  const title = isLocationPlace
    ? place.name
    : amenityList?.[placeTypeCamelCase] || placeType;
  const marker = cloneDeep(baseMarker);
  marker.id = place._id;
  marker.setTitle(title);
  marker.setPosition(new google.maps.LatLng(place.latitude, place.longitude));
  marker.setZIndex(isLocationPlace ? 10 : 1);
  marker.setIcon({
    url: `/images/pins/${iconName}.svg`,
    scaledSize: new google.maps.Size(21, height),
  });

  return marker;
};

const openMarkerInfo = (map, marker, place) => {
  const lngQueryParam = getQueryStringLanguage();
  const placeType = place.placeType;
  const placeTypeCamelCase = camelCase(placeType);
  const iconName = getIconName(placeTypeCamelCase, place.rating);
  // Create infoWindow content
  const content = `
    <a href="/details/${place._id}/local/${lngQueryParam}" class="infowindow-content">
      <img class="infowindow-content__image" src="/images/pins/${iconName}.svg"/>
      <div class="infowindow-content__text">
        <span class="infowindow-content__text--header">${place.name}</span>
        <span>${place.address}</span>
      </div>
    </a>
  `;

  infowindow.close();

  const getBounds = map.getBounds();
  const markPos = marker.getPosition();

  if (getBounds && getBounds.contains(markPos)) {
    // Set infoWindow content
    infowindow.setContent(content);
    infowindow.open(map, marker);
  }
};

const setupMarker = (map, marker, place, history) => {
  const placeType = place.placeType;
  const placeTypeCamelCase = camelCase(placeType);
  const iconName = getIconName(placeTypeCamelCase, place.rating);
  const lngQueryParam = getQueryStringLanguage();
  // set infoWindow content
  let content = '';
  const renderAmenityDetails = async (place) => {
    try {
      const { data } = await Axios.get(
        `${config.apiHost}/api/places/v1/pins/${encodeURIComponent(place._id)}`
      );
      const reportProblemUri = `mailto:hello@accessnow.ca?subject=Report A Problem&body=${encodeURIComponent(
        `\n\n--- Please don't remove below this line ---\n${data.name}\n${data.address}\n${data._id}`
      )}`;
      content = `
      <div class="infowindow-amenity-content">
        <div class="infowindow-amenity-header">
          <img
            alt={place.placeType}
            class="infowindow-amenity-content__icon"
            // eslint-disable-next-line no-template-curly-in-string
            src="/images/pins/${iconName}.svg"
          />
          <span class="infowindow-amenity-content__text--header">${
            data.placeType
          }</span>
        </div>
        <div class="infowindow-amenity-content-details">
        ${
          placeType === 'Terrain' && !!data.tags.length
            ? `<div class="infowindow-tags-wrapper">${data.tags
                .map((tag) => {
                  const newTag = tag.replace(/^'(.*)'$/, '$1');

                  return `<div class="infowindow-tags">${capitalize(
                    newTag
                  )}</div>`;
                })
                .filter((tag) => tag)
                .join('')}</div>`
            : ''
        }
        ${
          !!data.images.length
            ? `<div class="infowindow-images-wrapper">
        ${data.images
          .map(
            (img) =>
              `
            <img alt={place.placeType}
            class="infowindow-amenity-content__image"
            src="${img.thumbnail}"/>
          `
          )
          .join('')}
        </div>`
            : ''
        }
        ${
          config.environment === 'production' &&
          placeType === 'Hazard' &&
          !!data.description.length
            ? `<span class="infowindow-amenity-content__text">${data.description}</span>`
            : config.environment !== 'production' && !!data.description.length
            ? `<span class="infowindow-amenity-content__text">${data.description}</span>`
            : ''
        }        
        <div class="infowindow-amenity-footer">
        <div class="infowindow-amenity-footer-left">
        <span class="infowindow-amenity-position">${data.latitude}</span>
        <span class="infowindow-amenity-position">${data.longitude}</span>
        </div>
        <a href="${reportProblemUri}" class="infowindow-amenity-footer-right">
          <img
            alt={report_problem.svg}
            class="infowindow-content__image"
            // eslint-disable-next-line no-template-curly-in-string
            src="/images/pins/report_problem.svg"
          />
        </a>
        </div>
        </div>
    </div>`;
    } catch (err) {
      console.log('renderAmenityDetails err', err);
    }
  };

  if (isPlace(placeType)) {
    content = `
    <a href="/details/${place._id}/local/${lngQueryParam}" class="infowindow-content">
      <img class="infowindow-content__image" src="/images/pins/${iconName}.svg"/>
      <div class="infowindow-content__text">
        <span class="infowindow-content__text--header">${place.name}</span>
        <span>${place.address}</span>
      </div>
    </a>
  `;
  }
  // When user hovers over the marker, show callout with place name
  marker.addListener('mouseover', (event) => {
    if (isPlace(place.placeType)) {
      infowindow.close();
      infowindow.setContent(content);
      infowindow.open(map, marker);
    }
  });

  // When user clicks the marker, zooms to the place
  marker.addListener('click', (event) => {
    if (isPlace(place.placeType) && place._id) {
      const lngQueryParam = getQueryStringLanguage();

      history.push(`/details/${place._id}/local/${lngQueryParam}`);
    }
    if (!isPlace(place.placeType)) {
      infowindow.close();
      renderAmenityDetails(place).then(() => {
        infowindow.setContent(content);
        infowindow.open(map, marker, place);
      });
    }
  });

  return marker;
};

const Clusterer = memo(() => {
  const map = useGoogleMap();
  const history = useHistory();
  const classes = useStyles();
  const { isLoading, places, done } = useSelector(
    (state) => state.map,
    shallowEqual
  );
  const selectedPlaceDetails = useSelector(
    (state) => state.map.selectedPlaceDetails,
    shallowEqual
  );
  const addState = useSelector((state) => state.add, shallowEqual);
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const [openNoResultsAlert, setOpenNoResultsAlert] = useState(false);
  const location = useLocation();
  const prevPlaceId = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    // this is triggered once for map's 1st load
    const tilesloaded = google.maps.event.addListenerOnce(
      map,
      'tilesloaded',
      () => {
        if (location.pathname === '/') {
          const getBounds = map.getBounds();
          const boundaries = {
            top: getBounds.getNorthEast().lat(),
            right: getBounds.getNorthEast().lng(),
            bottom: getBounds.getSouthWest().lat(),
            left: getBounds.getSouthWest().lng(),
          };
          dispatch(mapActions.updateBoundaries(boundaries));
          dispatch(mapActions.listPlacesRequest());
        }
      }
    );

    const dragEnd = map.addListener('dragend', () => {
      const getLatLng = map.getCenter();
      const location = {
        latitude: getLatLng.lat(),
        longitude: getLatLng.lng(),
      };
      dispatch(searchActions.updateLocation({ location }));
    });

    const zoomChanged = map.addListener('zoom_changed', () => {
      dispatch(mapActions.searchMapBoundaries(map));
    });

    return () => {
      google.maps.event.removeListener(tilesloaded);
      google.maps.event.removeListener(zoomChanged);
      google.maps.event.removeListener(dragEnd);
    };
  }, [dispatch, location, map]);

  // triggered when `places` changes
  // and then add the markers to the map
  useEffect(() => {
    const placeId = selectedPlaceDetails?._id || addState?._id;
    let selectedMarker = null;
    let selectedPlace = null;

    const baseMarker = instantiateMarker();
    markers = places
      .filter((item) => isPlace(item.placeType))
      .map((place, index) => {
        const clonedMarker = cloneMarker(place, baseMarker);

        const marker = setupMarker(map, clonedMarker, place, history);

        if (placeId && placeId === place?._id) {
          selectedMarker = marker;
          selectedPlace = place;
        }

        return marker;
      });

    amenities = places
      .filter((item) => !isPlace(item.placeType))
      .map((place) => {
        const clonedMarker = cloneMarker(place, baseMarker);
        const marker = setupMarker(map, clonedMarker, place);

        if (placeId && placeId === place?._id) {
          selectedMarker = marker;
          selectedPlace = place;
        }

        return marker;
      });

    places
      .filter(
        (item) =>
          item.placeType === 'trail' &&
          (placeId === null || placeId !== item._id)
      )
      .forEach((trail) => {
        map.data.addGeoJson(trail.shapeLocation);
      });

    map.data.setStyle(getTrailStyle);

    const zoomLevel = map.getZoom();
    const allMarkers = [...markers, ...(zoomLevel > 13 ? amenities : [])];

    markerClusterer = new MarkerClusterer(map, allMarkers, {
      gridSize: config.google.cluster.gridSize,
      minimumClusterSize: config.google.cluster.minimumClusterSize,
      batchSize: config.google.cluster.batchSize,
      batchSizeIE: config.google.cluster.batchSizeIE,
      clusterClass: 'custom-clustericon',
      styles: [
        {
          width: 30,
          height: 30,
          className: 'custom-clustericon-1',
        },
        {
          width: 40,
          height: 40,
          className: 'custom-clustericon-2',
        },
        {
          width: 50,
          height: 50,
          className: 'custom-clustericon-3',
        },
      ],
    });

    // open marker info for selected pin as default
    if (selectedMarker && selectedPlace) {
      openMarkerInfo(map, selectedMarker, selectedPlace);
    }

    return () => {
      if (markerClusterer) {
        markerClusterer.clearMarkers();
      }
    };
  }, [history, map, places, selectedPlaceDetails, addState._id]);

  useEffect(() => {
    if (location.pathname !== '/') {
      // either selectedPlaceDetails or addState
      const place =
        (selectedPlaceDetails._id && selectedPlaceDetails) ||
        (addState._id && addState);
      // tracking previous id prevents dom updates when
      // making changes to the addState
      if (place && place._id !== prevPlaceId.current) {
        prevPlaceId.current = place._id;

        const cloned = cloneMarker(place);
        const marker = setupMarker(map, cloned, place, history);

        map.setCenter(marker.getPosition());
        marker.setMap(map);
        if (place.placeType === 'trail') {
          map.data.addGeoJson(place.shape_location);
          map.data.setStyle(getTrailStyle);
        }
        map.setZoom(18);

        // search all places in the map boundaries
        dispatch(mapActions.searchMapBoundaries(map));
      }
    }
  }, [history, map, selectedPlaceDetails, addState, location, dispatch]);

  useEffect(() => {
    const open = isLoading && !done;
    // if it takes more than 3 seconds (3000 milliseconds) to load the markers,
    // a friendly message appears to the user
    const timer = setTimeout(() => setOpenAlert(open), 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [done, isLoading]);

  useEffect(() => {
    // if the api call is done and there are no places,
    // a friendly message appears to the user
    const open = done && !places.length;
    setOpenNoResultsAlert(open);
  }, [done, places]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleCloseNoResultsAlert = () => {
    setOpenNoResultsAlert(false);
  };

  return (
    <>
      {isLoading && !done && <LinearProgress color="primary" />}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openAlert}
        onClose={handleCloseAlert}
        message={t('clusters.waitToLoadResults')}
      />

      <Snackbar open={openNoResultsAlert} onClose={handleCloseNoResultsAlert}>
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleCloseNoResultsAlert}
          severity="info"
        >
          <Typography className={classes.alertText}>
            {t('clusters.nothingFound')}
          </Typography>
          <Typography className={classes.alertText}>
            {t('clusters.beFirstToAddPin')}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
});

export default Clusterer;

import { Button, Divider, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AddForm, DrawerHeader, Message } from '../components';
import { useDialogContext } from '../context';
import { serverErrorsl10n } from '../l10n/common';
import { MapLayout } from '../layouts';
import { addActions, editActions } from '../store/actions';
import { track } from '../utils';
import getQueryStringLanguage from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  header: {
    ...theme.typography.h5,
    padding: theme.spacing(2, 3, 0, 3),
  },
  outerContainer: {
    overflowY: 'auto',
  },
  container: {
    flex: 1,
  },
  button: {
    width: '100%',
  },
  buttonContainer: {
    padding: theme.spacing(0, 3, 3, 3),
  },
  errorMessageContainer: {
    padding: theme.spacing(3, 3, 2, 3),
  },
}));

const AddView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const outerContainerRef = useRef(null);
  const errorMessageContainerRef = useRef(null);
  const addState = useSelector((state) => state.add, shallowEqual);
  const editState = useSelector((state) => state.edit, shallowEqual);
  const { isLoading: isAddLoading, rating, isReviewValid } = addState;
  const { isLoading: isEditLoading } = editState;
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { dialogDispatch } = useDialogContext();
  const { t } = useTranslation();
  const selectedPlaceDetails = useSelector(
    (state) => state.map.selectedPlaceDetails
  );
  const isEdit = editState._id;
  const serverErrorMessage = isEdit
    ? editState.errorMessage
    : addState.errorMessage;
  const isLoading = isEdit ? isEditLoading : isAddLoading;
  useEffect(() => {
    track.screen('AddView');
  }, []);

  const handleScroll = (position) => {
    if (errorMessageContainerRef.current) {
      errorMessageContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  useEffect(() => {
    handleScroll();
  }, [serverErrorMessage]);

  useEffect(() => {
    // TODO: fix memory leak
    if (!addState.name) {
      history.replace('/');
    }
  }, [addState.name, history]);
  // show login dialog if not authenticated
  useEffect(() => {
    if (!isAuthenticated) {
      dialogDispatch({ type: 'OPEN_AS', payload: 'login' });
    }
  }, [isAuthenticated, dialogDispatch]);

  const onAddRequest = (data) => {
    if (!isLoading && isReviewValid) {
      setValidationErrorMessage('');
      dispatch(addActions.send(data));
    } else {
      setValidationErrorMessage(serverErrorsl10n.en.invalidReview);
    }
  };
  const onEditRequest = async (data) => {
    await dispatch(editActions.editReview(data));
  };
  const onAdd = () => {
    if (!isAuthenticated) {
      dialogDispatch({ type: 'OPEN_AS', payload: 'login' });
    } else {
      if (isEdit) {
        onEditRequest(editState);
      } else {
        onAddRequest(addState);
      }
    }
  };

  const onBackToSearch = () => {
    const lngQueryParam = getQueryStringLanguage();
    if (selectedPlaceDetails._id) {
      history.push(
        `/details/${selectedPlaceDetails._id}/local/${lngQueryParam}`
      );
    } else {
      history.push(`/${lngQueryParam}`);
    }

    dispatch(addActions.reset());
    dispatch(editActions.reset());
  };

  const disabled = rating || editState.rating ? true : false;

  return (
    <MapLayout>
      <div
        aria-live="assertive"
        className={classes.outerContainer}
        ref={outerContainerRef}
      >
        <DrawerHeader
          title={t('messages.rateThisPlace')}
          onClick={onBackToSearch}
        />
        <div className={classes.container}>
          {isLoading && <LinearProgress color="primary" />}
          {validationErrorMessage || serverErrorMessage ? (
            <>
              <div
                className={classes.errorMessageContainer}
                ref={errorMessageContainerRef}
              >
                <Message
                  id="form-dialog-description"
                  variant="error"
                  message={validationErrorMessage || serverErrorMessage}
                />
              </div>
              <Divider />
            </>
          ) : null}
          <AddForm />
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              size="large"
              onClick={onAdd}
              disabled={isLoading || !disabled}
            >
              {isLoading ? t('messages.loading') : t('messages.done')}
            </Button>
          </div>
        </div>
      </div>
    </MapLayout>
  );
};

export default AddView;

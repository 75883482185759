import { Button, Grid, Slider, Typography } from '@material-ui/core';
import {
  Image as ImageIcon,
  InsertPhoto as InsertPhotoIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Resizer from 'react-image-file-resizer';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { imageAction } from '../store/actions';
import ANGrid from './ANGrid';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#00000001',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'left',
    padding: theme.spacing(2, 0, 0, 0),
  },
  dropzone_root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    padding: theme.spacing(8, 2, 8, 2),
    border: '1px dashed ' + theme.palette.grey[500],
    borderRadius: 10,
  },
  zoomSlider: {
    width: 250,
    marginTop: 15,
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  actionButtons: {
    width: 250,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMsg: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2, 0, 0, 0),
  },
}));

const Dropzone = ({ setImage, variant, imagesNum, limit }) => {
  const classes = useStyles();

  const [errorMsg, setErrorMsg] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const resizeAndSetImage = useCallback(
    (image) => {
      try {
        if (limit - imagesNum <= 0) return;
        Resizer.imageFileResizer(
          image,
          1000, // max width
          1000, // max height
          'JPG', // output format
          100, // quality
          0, // rotation
          (resizedImage) => {
            const reader = new FileReader();
            reader.onload = () => {
              const dataURL = reader.result;
              const newImage = {
                file: resizedImage,
                uri: dataURL,
                name: image.name,
              };

              setImage((prevImages) => [...prevImages, newImage].slice(0, 10));
              dispatch(imageAction.selectImage([newImage]));
            };
            reader.readAsDataURL(resizedImage);
          },
          'blob' // output type: blob or base64
        );
      } catch (error) {
        console.log('Dropzone catch error', error);
      }
    },
    [dispatch, imagesNum, limit, setImage]
  );

  const onDrop = React.useCallback(
    (files) => {
      try {
        setErrorMsg(null);
        const selectedImages = files.filter((file) =>
          file.type.startsWith('image')
        );
        if (variant === 'avatar') {
          setImage(selectedImages[0]);
        } else if (variant === 'gallery') {
          selectedImages.forEach((image) => {
            resizeAndSetImage(image);
          });
        }
      } catch (err) {
        console.log('onDrop error', err);
      }
    },
    [variant, setImage, resizeAndSetImage]
  );

  const onDropRejected = React.useCallback(
    (error) => {
      if (error.length > limit - imagesNum) {
        setErrorMsg(
          t('messages.tooManyPhotosErr').replace('{limit}', limit - imagesNum)
        );
      }
    },
    [imagesNum, limit, t]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    maxFiles: limit - imagesNum,
    disabled: imagesNum >= limit,
  });

  return (
    <div>
      <div {...getRootProps()} className={classes.dropzone_root}>
        <input {...getInputProps()} />

        <InsertPhotoIcon style={{ fontSize: 36 }} />
        <Typography style={{ paddingBottom: 6, paddingTop: 12 }}>
          {t('messages.dragPhoto')}
          <Typography>{`(${imagesNum}/${limit})`}</Typography>
        </Typography>
        <em>{t('messages.dropPhotoRestriction')}</em>
      </div>
      {errorMsg ? (
        <Typography variant="body2" color="error" className={classes.errorMsg}>
          {errorMsg}
        </Typography>
      ) : (
        <></>
      )}
    </div>
  );
};

const ImagePicker = ({
  variant,
  onSave,
  onCancel,
  existingImagesNum,
  ...props
}) => {
  const classes = useStyles();
  const [scale, setScale] = useState(1);
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const editorRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const imageState = useSelector((state) => state.image, shallowEqual);
  const handleZoomSliderChange = (event, newValue) => {
    setScale(newValue);
  };
  const handleRemoveImage = (item) => {
    if (variant === 'gallery') {
      const updatedImages = images.filter((image) => image.name !== item.name);
      setImages(updatedImages);
      dispatch(imageAction.deselectImage(item.name));
    }
  };
  return (
    <div className={classes.root}>
      {variant === 'avatar' && image ? (
        <AvatarEditor
          ref={editorRef}
          image={image}
          height={400}
          width={400}
          borderRadius={1000}
          color={[0, 0, 0, 0.6]}
          scale={scale}
        />
      ) : variant === 'gallery' ? (
        <>
          <Grid container justifyContent="center">
            <Dropzone
              setImage={setImages}
              imagesNum={imageState.images.length}
              limit={10 - existingImagesNum}
              variant={'gallery'}
            />
          </Grid>
          <div className={classes.gridContainer}>
            <ANGrid images={images} handleRemoveImage={handleRemoveImage} />
          </div>
        </>
      ) : (
        <Dropzone setImage={setImage} variant={'avatar'} />
      )}

      {variant === 'avatar' && image ? (
        <div>
          <div className={classes.zoomSlider}>
            <Typography>{t('messages.zoom')}</Typography>
            <Grid
              container
              direction="row"
              alignContent="center"
              justify="center"
            >
              <Grid item xs={2} className={classes.gridItem}>
                {' '}
                <ImageIcon fontSize="small" />{' '}
              </Grid>

              <Grid item xs={8} className={classes.gridItem}>
                <Slider
                  step={0.00001}
                  min={1}
                  value={scale}
                  max={3}
                  onChange={handleZoomSliderChange}
                  color="primary"
                />
              </Grid>

              <Grid item xs={2} className={classes.gridItem}>
                {' '}
                <ImageIcon />{' '}
              </Grid>
            </Grid>
          </div>
          <div className={classes.actionButtons}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSave(editorRef)}
            >
              {t('messages.save')}
            </Button>

            <Button color="primary" onClick={onCancel}>
              {t('messages.cancel')}
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ImagePicker;

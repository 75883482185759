import { Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import validate from 'validate.js';
import { accountActions } from '../../store/actions';
import { config, constraints, track } from '../../utils';

const useStyles = makeStyles((theme) => ({
  caption: {
    margin: theme.spacing(2, 0),
  },
  button: {
    margin: theme.spacing(2, 0),
  },
}));

const ForgotView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const classes = useStyles();
  const recaptchaRef = React.createRef();
  const [formState, setFormState] = useState({
    isValid: true,
    values: {
      ...queryParams,
    },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    track.screen('ForgotPasswordView');
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, constraints.forgotPassword);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleFormSubmit = (token) => {
    const { values } = formState;
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      values.recaptcha = token;
    }
    dispatch(accountActions.forgotPassword(values));
  };

  const handleReCaptcha = (event) => {
    event.preventDefault();
    recaptchaRef.current.execute();
  };

  return (
    <>
      <Typography className={classes.caption}>
        Please enter the email address you used to create your account below to
        request a new password reset link.
      </Typography>
      <form onSubmit={handleReCaptcha}>
        <TextField
          margin="dense"
          id="email"
          name="email"
          label="Email"
          type="email"
          fullWidth
          error={hasError('email')}
          helperText={hasError('email') ? formState.errors.email[0] : null}
          onChange={handleChange}
          value={formState.values.email || ''}
          aria-label="required"
        />
        <Button
          className={classes.button}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={!formState.isValid}
          fullWidth
        >
          Send Email
        </Button>
      </form>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={config.recaptcha.siteKey}
        onChange={handleFormSubmit}
      />
    </>
  );
};

export default ForgotView;

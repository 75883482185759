import React from 'react';
import { DialogProvider, useDialogContext } from './DialogContext';
import { DirectoryProvider, useDirectoryContext } from './DirectoryContext';
import { GalleryProvider, useGalleryContext } from './GalleryContext';

const combineProviders = (providers) => {
  return ({ children }) => {
    return Object.values(providers)
      .reverse()
      .reduce((tree, Provider) => <Provider>{tree}</Provider>, children);
  };
};

export { useDirectoryContext, useDialogContext, useGalleryContext };

// add the providers in the order you want them to load
export const RootProvider = combineProviders({
  directory: DirectoryProvider,
  dialog: DialogProvider,
  gallery: GalleryProvider,
});

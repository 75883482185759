import capitalize from 'lodash.capitalize';
import pickBy from 'lodash.pickby';
import { labelsl10n } from '../l10n/common';

const formatLabels = (labels) => {
  return labels
    .map((label) => {
      const { _id, key, featured, verified } = label;

      return pickBy({
        _id,
        key,
        featured,
        verified,
        displayName: labelsl10n.en[label.key] || capitalize(label.key),
      });
    })
    .filter((label) => label.displayName)
    .sort((a, b) => a.featured - b.featured) // sort by featured first
    .sort((a, b) => a.displayName.localeCompare(b.displayName)); // sort alphabetically second
};

export default formatLabels;

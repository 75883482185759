import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import numeral from 'numeral';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { statsActions } from '../store/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 170,
  },
  pin: {
    width: 30,
  },
  pinText: {
    color: theme.palette.text.pinText,
  },
}));

const PinSummary = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const statsState = useSelector((state) => state.stats);

  useEffect(() => {
    dispatch(statsActions.getStats());
  }, [dispatch]);

  const ratings = Object.freeze({
    accessible: 'Accessible',
    partiallyAccessible: 'Partially Accessible',
    notAccessible: 'Not Accessible',
  });

  const listItems = () => {
    const listItems = [];
    for (const prop in ratings) {
      const displayName = t(`ratings.${prop}`);
      const stat = (statsState || {})[`${prop}PlaceCount`];
      if (displayName) {
        listItems.push(
          <ListItem
            role="listitem"
            key={displayName}
            aria-label={`${stat} ${displayName} pins`}
          >
            <ListItemIcon aria-hidden="true">
              <img
                alt={displayName}
                src={`/images/pins/${prop}.svg`}
                className={classes.pin}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography type="body1" className={classes.pinText}>
                  {displayName}
                </Typography>
              }
              style={{ width: '30%' }}
              aria-hidden="true"
            />
            {!!stat && (
              <ListItemText
                primary={
                  <Typography type="body1" className={classes.pinText}>
                    {numeral(stat).format('0,0')}
                  </Typography>
                }
                aria-hidden="true"
              />
            )}
          </ListItem>
        );
      }
    }
    return listItems;
  };

  return (
    <List
      aria-label="Total pin count"
      role="list"
      className={classes.container}
    >
      {listItems()}
    </List>
  );
};

export default PinSummary;

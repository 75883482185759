import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/remote-config';
import config from './config';

firebase.initializeApp(config.firebase);

export const analytics = firebase.analytics();
export const performance = firebase.performance();
export const remoteConfig = firebase.remoteConfig();

export default firebase;

const initState = {
  isLabelLoading: false,
  values: [],
  labelErrorMsg: '',
};

const labelReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'GET_LABELS_IS_LOADING':
    case 'GET_LABELS_SUCCESS':
    case 'GET_LABELS_FAILURE':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default labelReducer;

/**
 *  Represents optional values. Instances of `Option`
 *  are either an instance of `Option` or `None`.
 *
 *  The most idiomatic way to use an $option instance is to treat it
 *  as a collection or monad and use `map`,`flatMap`.
 *
 *  @memberof Utils
 *  @version 1.0
 */
export default class Option {
  /**
   * Constructor which creates `Some(x)` if the argument is not undefined & not null,
   * and `None` if it is undefined or null.
   *
   * @param  value - The value to express as an `Option`
   * @return {Utils.Option}
   */
  constructor(value) {
    this.value = value;
  }

  /**
   * Returns the result of applying `fn` to `this`
   * value if `this` value is non empty.  Otherwise, evaluates
   * expression `defaultFn`.
   *
   * @param  {Function} defaultFn - The function to apply if empty
   * @param  {Function} fn - the function to apply if non empty
   * @return {B}
   */
  fold(defaultFn, fn) {
    if (this.value === undefined) {
      return defaultFn();
    } else {
      return fn(this.value);
    }
  }

  /**
   * Returns the `this` value if the `this` value is non empty, otherwise
   * return the result of evaluating `fn`.
   *
   * @param {Function} fn - The function to apply, provides default behaviour
   * @return {A}
   */
  getOrElse(fn) {
    if (this.value === undefined) {
      return fn();
    } else {
      return this.value;
    }
  }

  /**
   * Returns an `Option` containing the result of applying `fn` to `this`
   * value if this `this` is non empty. Otherwise return `None`.
   *
   * @param {Function} fn - The function to apply
   * @return {Utils.Option} - Result is wrapped in `Option`
   */
  map(fn) {
    if (this.value === undefined) {
      return new Option(undefined);
    } else {
      return new Option(fn(this.value));
    }
  }

  /**
   *  Apply the given procedure $f to the option's value,
   *  if it is nonempty. Otherwise, do nothing.
   *
   *  @param {Function} fn - the procedure to apply.
   */
  foreach(fn) {
    if (this.value !== undefined) fn(this.value);
  }

  /**
   * Returns the result of applying `fn` to this `this` value if
   * `this` value is non empty. Returns `None` if this `this` value is empty.
   * Slightly different from `map` in that `fn` is expected to
   * return an `Option` (which could be `None`).
   *
   *  @param {Function} fn - The function to apply
   *  @return {*}
   */
  flatMap(fn) {
    if (this.value === undefined) {
      return new Option(undefined);
    } else {
      return fn(this.value);
    }
  }

  /**
   *  Use this to wrap risky operations
   *
   *  @return {Utils.Option}
   */
  static risky(fn) {
    const mbValue = (() => {
      try {
        return fn();
      } catch (_) {
        return undefined;
      }
    })();
    return new Option(mbValue);
  }
}

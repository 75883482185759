import { Button, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ImagePicker } from '../components';
import { useDialogContext } from '../context';
import { addActions, editActions, imageAction } from '../store/actions';
import { track } from '../utils';
import { IMAGE_LIMIT } from '../utils/constants';
import { resizeAndSaveImage } from '../utils/image';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#00000001',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  dropzone_root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
  },
  zoomSlider: {
    width: 250,
    marginTop: 15,
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  actionButtons: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
  },
  button: {
    marginLeft: 10,
    borderRadius: 20,
    padding: '3px 20px',
  },
});

const AddPhotosDialog = () => {
  const addState = useSelector((state) => state.add, shallowEqual);
  const editState = useSelector((state) => state.edit, shallowEqual);
  const imageState = useSelector((state) => state.image, shallowEqual);
  const isLoading = editState.isLoading || addState.isLoading || false;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const { dialogDispatch } = useDialogContext();

  const onCancel = () => {
    dispatch(imageAction.clearImage());
    dialogDispatch({ type: 'CLOSE' });
  };
  const onSave = async () => {
    try {
      const selectedImages = imageState.images;
      if (!selectedImages || selectedImages.length === 0) {
        dispatch(imageAction.clearImage());
        dialogDispatch({ type: 'CLOSE' });
        return;
      }

      // Show loading indicator for the entire process
      // Set individual loading states for each image
      const resizedImages = await Promise.all(
        selectedImages.map(async (image) => {
          try {
            return await resizeAndSaveImage(image);
          } catch (error) {
            track.error(error);
            return null;
          }
        })
      );

      // Filter out any potential null values in case of errors during resizing
      const filteredResizedImages = resizedImages.filter((img) => img !== null);

      // Fetch and save the images as blobs
      const imagePromises = filteredResizedImages.map((image) => {
        return fetch(image.uri)
          .then((res) => res.blob())
          .then((blob) => {
            const uri = window.URL.createObjectURL(blob);
            const type = blob.type;
            const filename = `${uri.substring(
              uri.lastIndexOf('/') + 1
            )}.${type.substring(type.lastIndexOf('/') + 1)}`;
            return { name: filename, blob: blob, uri: uri, isLocalImage: true };
          });
      });

      // Wait for all the imagePromises to resolve using Promise.all
      const newImageArr = await Promise.all(imagePromises);

      const imagesToUpdate = [
        ...(editState._id ? editState.images : addState.images),
        ...newImageArr,
      ];

      if (editState._id) {
        dispatch(editActions.update({ images: imagesToUpdate }));
      } else {
        dispatch(addActions.update({ images: imagesToUpdate }));
      }
      dispatch(imageAction.clearImage());
      dialogDispatch({ type: 'CLOSE' });
    } catch (error) {
      // Handle any other potential errors here
      track.error(error);
    }
  };

  return (
    <>
      {!!isLoading && <LinearProgress color="primary" />}
      <ImagePicker
        variant="gallery"
        multiple={true}
        existingImagesNum={
          editState._id ? editState.images.length : addState.images.length
        }
      />
      <div className={classes.actionButtons}>
        <div className={classes.actionButtons}>
          <Button
            variant="outlined"
            color="grey"
            onClick={onCancel}
            className={classes.button}
          >
            {t('messages.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            disabled={
              !imageState.images.length ||
              imageState.images.length > IMAGE_LIMIT
            }
            className={classes.button}
          >
            {t('messages.attach')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddPhotosDialog;

import { LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Review } from '../components';
import { reviewActions } from '../store/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minWidth: 360,
    minHeight: 16,
  },
  loader: {
    position: 'absolute',
    width: '100%',
  },
}));

const MyReviewsDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const byUser = useSelector((state) => state.reviews.byUser);
  const isLoading = useSelector((state) => state.reviews.isLoading);
  const { t } = useTranslation();
  const { isProvider } = useSelector((state) => state.auth);

  const loadData = useCallback(() => {
    dispatch(reviewActions.reviewsByUser());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <div className={classes.container}>
        {isLoading && (
          <LinearProgress color="primary" className={classes.loader} />
        )}
        {!byUser.length && !isLoading && (
          <Typography variant="body1">
            {isProvider ? t('messages.noReplies') : t('messages.noReviews')}
          </Typography>
        )}
      </div>
      {byUser.map((review) => {
        return <Review review={review} key={review._id} />;
      })}
    </>
  );
};

export default MyReviewsDialog;

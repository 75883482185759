import { serverErrorsl10n } from '../../l10n/common';
import * as api from '../../services/api';
import * as mapActions from './mapActions';

export const resetReviewsByPlace = () => {
  return async (dispatch) => {
    dispatch({
      type: 'RESET_REVIEWS_BY_PLACE',
    });
  };
};

export const reviewsByPlace = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'REVIEWS_BY_PLACE_IS_LOADING',
      payload: {
        isLoading: true,
      },
    });

    try {
      const response = await api.placeService.reviews(data);

      dispatch({
        type: 'REVIEWS_BY_PLACE_SUCCESS',
        payload: {
          isLoading: false,
          byPlace: response,
        },
      });
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;

      dispatch({
        type: 'REVIEWS_BY_PLACE_FAILURE',
        payload: {
          isLoading: false,
          errorMessage,
        },
      });
    }
  };
};

export const reviewsByUser = () => {
  return async (dispatch) => {
    dispatch({
      type: 'REVIEWS_BY_USER_IS_LOADING',
      payload: {
        isLoading: true,
      },
    });

    try {
      const response = await api.userService.reviews();

      dispatch({
        type: 'REVIEWS_BY_USER_SUCCESS',
        payload: {
          isLoading: false,
          byUser: response,
        },
      });
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;

      dispatch({
        type: 'REVIEWS_BY_USER_FAILURE',
        payload: {
          isLoading: false,
          errorMessage,
        },
      });
    }
  };
};

export const deleteReview = (id) => {
  return async (dispatch, getState) => {
    const { selectedPlaceDetails } = getState().map;

    dispatch({
      type: 'DELETE_REVIEW_IS_LOADING',
      payload: {
        isLoading: true,
      },
    });

    try {
      await api.userService.deleteReview(id);

      const { reviews } = getState();
      const byUser = reviews.byUser.filter((review) => id !== review._id);

      dispatch({
        type: 'DELETE_REVIEW_SUCCESS',
        payload: {
          isLoading: false,
          byUser,
        },
      });

      // update place details if selectedPlaceDetails state is populated
      // TODO: update map pin as well when it doesn't take so long
      if (selectedPlaceDetails._id) {
        dispatch(mapActions.placeDetails({ _id: selectedPlaceDetails._id }));
      }
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;

      dispatch({
        type: 'DELETE_REVIEW_FAILURE',
        payload: {
          isLoading: false,
          errorMessage,
        },
      });
    }
  };
};

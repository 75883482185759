import pickBy from 'lodash.pickby';
import { HttpService } from './http-base.service';

export default class UserService extends HttpService {
  constructor(baseURL) {
    if (!UserService.instance) {
      super(baseURL);
      UserService.instance = this;
    }
    return UserService.instance;
  }

  current() {
    return this.get('/api/users/me')
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  create(data) {
    return this.post('/api/users', data)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  createFB(data) {
    return this.post('/api/users/fb', data)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  update(data) {
    return this.put('/api/users', data)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  updateUserName(data) {
    return this.put('/api/users', data)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  list() {
    return this.get('/api/users')
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  detail(id: string) {
    return this.get(`/api/users/${encodeURIComponent(id)}`)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  changePasswordWithToken({ id, token, newPassword, newPasswordConfirm }) {
    return this.put(`/api/users/${encodeURIComponent(id)}/password-token`, {
      id,
      token,
      newPassword,
      newPasswordConfirm,
    })
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  forgotPassword(data) {
    return this.post('/api/users/reset', data)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  places() {
    return this.get('/api/users/places')
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  uploadAvatar(filename: string, image: Blob) {
    const data = new FormData();
    data.append('image', image, filename);

    return this.post('/api/users/avatar', data)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  reviews() {
    return this.get('/api/users/v1/reviews')
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  deleteReview(id: string) {
    return this.delete(`/api/reviews/v1/${encodeURIComponent(id)}`)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  undoDeleteReview(id: string) {
    return this.put(`/api/reviews/v1/${encodeURIComponent(id)}/undo-delete`)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  editReviewPost(data) {
    const params = pickBy({
      _id: data._id,
      text: data.text,
      tags: data.tags,
      rating: data.ratings,
    });
    const editData = new FormData();

    Object.keys(params).forEach((key) => {
      const value = params[key];

      if (Array.isArray(value)) {
        value.forEach((_value) => editData.append(`${key}[]`, _value));
      } else {
        editData.append(key, value);
      }
    });
    data.images.forEach((image) => {
      if (image.isLocalImage) {
        editData.append('images', image.blob, image.name);
      }
    });
    data.removedImages.forEach((image) => {
      image.name = image.url;
      image.uri = image.url;
      image.blob = new Blob([image], { type: 'image/jpeg' });

      editData.append('deletedImages[]', image.blob, image.name);
    });

    return this.put(
      `/api/reviews/v1/${encodeURIComponent(params._id)}`,
      editData,
      { headers: { 'content-type': 'multipart/form-data' } }
    )
      .then((response) => response)
      .catch((err) => {
        console.log('editReviewPost', err);
        throw err;
      });
  }
}

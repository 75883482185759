import * as constraints from './constraints';

const checked = (value, options) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};

const isNumberApproximatelyEqual = (a, b, error = 0.001) => {
  return Math.abs(a - b) - error < Number.EPSILON;
};

export { constraints, isNumberApproximatelyEqual };

export default {
  checked,
};

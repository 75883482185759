const initState = {
  isFlagLoading: false,
  values: [],
  flagErrorMsg: '',
};

const flagReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'GET_FLAGS_IS_LOADING':
    case 'GET_FLAGS_SUCCESS':
    case 'GET_FLAGS_FAILURE':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default flagReducer;

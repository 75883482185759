import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import NearMeIcon from '@material-ui/icons/NearMe';
import SearchIcon from '@material-ui/icons/Search';
import React, { useRef, useState } from 'react';
import { Message } from '../../components';
import { useDirectoryContext } from '../../context';
import { track } from '../../utils';
import { Controls } from '.';

const useStyles = makeStyles((theme) => ({
  paper: {
    flexWrap: 'nowrap',
    padding: theme.spacing(3),
    color: theme.palette.text.primary,
    width: '100%',
  },
  title: {
    marginRight: theme.spacing(2),
  },
  wrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {},
  },
  textFieldWrapper: {
    display: 'flex',
    flex: 2,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      '&:first-child': {
        marginBottom: theme.spacing(1),
      },
    },
    [theme.breakpoints.up('xs')]: {
      '&:first-child': {
        marginRight: theme.spacing(2),
      },
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-end',
      marginLeft: theme.spacing(2),
    },
  },
  button: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      padding: '7px 19px',
    },
    '&:first-child': {
      marginRight: theme.spacing(2),
    },
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  errorMessage: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
}));

const ControlBox = React.memo(({ errorMessage, isLoading }) => {
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const [location, setLocation] = useState('');
  const { dispatch } = useDirectoryContext();
  // track focus of query and/or location inputs
  const [queryLocationFocus, setQueryLocationFocus] = useState(false);
  const queryRef = useRef();
  const locationRef = useRef();

  const handleOnKeyDown = (event) => {
    if (event.keyCode === 13 && queryLocationFocus) {
      handleSearchSubmit({
        query,
        location,
      });
    }
  };

  const handleSearchSubmit = (payload) => {
    dispatch({
      type: 'SET_QUERY',
      payload,
    });
    track.analytics({
      category: 'directory_search',
      data: {
        search_term: query,
        location: location,
      },
    });
  };

  const handleSearchChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSearchButtonSubmit = () => {
    handleSearchSubmit({
      query,
      location,
    });
  };

  const handleClearQuery = (event) => {
    queryRef.current.focus();
    handleSearchSubmit({
      query: '',
      location,
    });
    setQuery('');
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleClearLocation = (event) => {
    locationRef.current.focus();
    handleSearchSubmit({
      query,
      location: '',
    });
    setLocation('');
  };

  const handleFocusChange = () => {
    setQueryLocationFocus(true);
  };

  const handleBlurChange = () => {
    setQueryLocationFocus(false);
  };

  return (
    <Paper className={classes.paper}>
      <Box className={classes.wrapper}>
        <Typography variant="h4" color="secondary" className={classes.title}>
          Find a business
        </Typography>
        <Box className={classes.textFieldWrapper}>
          <TextField
            inputRef={queryRef}
            className={classes.textField}
            fullWidth
            margin="none"
            id="search"
            label="Search"
            onChange={handleSearchChange}
            onKeyDown={handleOnKeyDown}
            onFocus={handleFocusChange}
            onBlur={handleBlurChange}
            value={query}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!!query.length && (
                    <IconButton
                      size="small"
                      onClick={handleClearQuery}
                      aria-label="Clear search term"
                    >
                      <CloseIcon color="primary" />
                    </IconButton>
                  )}
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          <TextField
            inputRef={locationRef}
            className={classes.textField}
            fullWidth
            margin="none"
            id="location"
            label="Location"
            onChange={handleLocationChange}
            onKeyDown={handleOnKeyDown}
            onFocus={handleFocusChange}
            onBlur={handleBlurChange}
            value={location}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!!location.length && (
                    <IconButton
                      size="small"
                      onClick={handleClearLocation}
                      aria-label="Clear location"
                    >
                      <CloseIcon color="primary" />
                    </IconButton>
                  )}
                  <NearMeIcon />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
        </Box>
        <Box className={classes.buttonWrapper}>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleSearchButtonSubmit}
          >
            Search
          </Button>
          <Button
            className={classes.button}
            variant="outlined"
            color="default"
            size="large"
            href="mailto:hello@accessnow.ca?subject=Feature Request"
          >
            Feature Request
          </Button>
        </Box>
      </Box>
      <Divider variant="fullWidth" className={classes.divider} />
      <Controls />
      {!!errorMessage && (
        <Message
          id="form-dialog-description"
          variant="error"
          message={errorMessage}
          role="alert"
          className={classes.errorMessage}
        />
      )}
    </Paper>
  );
});

export default ControlBox;

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Header } from '../components';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  headerContainer: {
    width: '100%',
    height: 73,
  },
}));

const MainLayout = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.headerContainer}>
        <Header position="relative" />
      </div>
      {props.children}
    </div>
  );
};

export default MainLayout;

import axios from 'axios';
import { locationl10n } from '../l10n/common';
import { config, parse, track } from '../utils';

export const getCurrentLocation = async () => {
  if (!navigator.geolocation) {
    alert(locationl10n.en.NOT_SUPPORTED);
  } else {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position.coords),
        (error) => {
          let errorMessage = locationl10n.en.UNKNOWN_ERROR;

          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = locationl10n.en.PERMISSION_DENIED;
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = locationl10n.en.POSITION_UNAVAILABLE;
              break;
            case error.TIMEOUT:
              errorMessage = locationl10n.en.POSITION_UNAVAILABLE;
              break;
            default:
              errorMessage = locationl10n.en.UNKNOWN_ERROR;
          }

          return reject(errorMessage);
        }
      );
    });
  }
};

export const getLocationByIP = async () => {
  return axios
    .get('https://ipinfo.io/ip')
    .then((response) =>
      axios.get(`${config.apiHost}/api/geolocation/${response.data}`)
    )
    .then((response) => {
      return {
        latitude: parse.formatLatLng(response.data.latitude),
        longitude: parse.formatLatLng(response.data.longitude),
      };
    })
    .catch((error) => {
      track.error(error);

      // in case `https://jsonip.com/` doesn't return anything,
      // we fallback to a default lat/lng so the map doesn't crash
      return {
        latitude: -79.3436,
        longitude: 43.821,
      };
    });
};

const initState = {
  isRatingoading: false,
  values: [],
  ratingErrorMsg: '',
};

const ratingReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'GET_RATINGS_IS_LOADING':
    case 'GET_RATINGS_SUCCESS':
    case 'GET_RATINGS_FAILURE':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default ratingReducer;

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { TextButton } from '../components';
import DialogForm from '../components/DialogForm';
import { useDialogContext } from '../context';
import { accountActions } from '../store/actions';

const validationSchema = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .required(t('messages.requiredField'))
      .email(t('messages.emailFormatError')),
  });
};

const ForgotPasswordDialog = (props) => {
  const { scrollContentToTop } = props;
  const dispatch = useDispatch();
  const { dialogDispatch } = useDialogContext();
  const successMessage = useSelector((state) => state.auth.successMessage);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const { t } = useTranslation();

  const { register, handleSubmit, getValues, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema(t)),
    submitFocusError: true,
  });

  const onSubmit = () => {
    if (!isLoading) {
      dispatch(accountActions.forgotPassword(getValues()));
    }
  };

  const onLoginDialog = () =>
    dialogDispatch({ type: 'OPEN_AS', payload: 'login' });

  const onCreateAccountDialog = () =>
    dialogDispatch({ type: 'OPEN_AS', payload: 'createAccount' });

  return (
    <DialogForm
      onSubmit={handleSubmit(onSubmit)}
      successMessage={successMessage}
      errorMessage={errorMessage}
      isLoading={isLoading}
      hideFormOnSuccess
      scrollContentToTop={scrollContentToTop}
    >
      <TextField
        id="email"
        type="email"
        label={t('messages.email')}
        name="email"
        margin="dense"
        error={!!errors.email}
        helperText={errors.email?.message || ''}
        inputRef={register}
        fullWidth
      />
      <Box mt={2} mb={2}>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          {t('messages.sendEmail')}
        </Button>
      </Box>
      <TextButton
        label={t('messages.alreadyHaveAnAccount')}
        onClick={onLoginDialog}
      />
      <br />
      <TextButton
        label={t('messages.needToCreateAnAccount')}
        onClick={onCreateAccountDialog}
      />
    </DialogForm>
  );
};

export default ForgotPasswordDialog;

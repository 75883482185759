import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  Slide,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import copy from 'clipboard-copy';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { ListItem } from '../components';
import { track } from '../utils';
import config from '../utils/config';
import getQueryStringLanguage from '../utils/helpers';

const SharePinDialog = ({
  isNewPin,
  sharer,
  isOpen,
  onClose,
  title,
  placeDetails,
  isGoogle,
  ...props
}) => {
  const [snackbarIsVisible, setSnackbarIsVisible] = useState(false);

  const { t } = useTranslation();

  const lngQueryParam = getQueryStringLanguage();
  const shareableLink = isGoogle
    ? `${config.host}/details/${placeDetails.servicePlaceId}/google/${lngQueryParam}`
    : `${config.host}/details/${placeDetails._id}/local/${lngQueryParam}`;
  const { name, rating } = placeDetails;
  const displayName = t(`ratings.${rating}`);

  const shareableDescription = isNewPin
    ? i18next.t('summaries.shareDescriptionNew', {
        name,
        displayName,
      })
    : i18next.t('summaries.shareDescriptionOld', {
        name,
        displayName,
      });

  const shareableMessage = `${shareableDescription}\n${shareableLink}`;
  const copyLink = (link) => {
    setSnackbarIsVisible(true);
    copy(link);
    logPinSharedEventInFirebaseAnalytics('direct');
  };

  const logPinSharedEventInFirebaseAnalytics = (socialType) => {
    track.analytics({
      category: 'pin_shared',
      data: {
        shared_id: sharer._id,
        pin_id: placeDetails._id,
        sharing_method: socialType,
      },
    });
  };

  const hideSnackbar = () => {
    setSnackbarIsVisible(false);
  };

  return (
    <Dialog
      open={isOpen}
      onBackdropClick={(e) => {
        onClose(false);
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Grid container>
          <Grid container item xs={6} justify="flex-start" alignItems="center">
            <Typography style={{ fontSize: '20px' }}>{title}</Typography>
          </Grid>
          <Grid container item xs={6} justify="flex-end" alignItems="center">
            <IconButton
              onClick={() => {
                onClose(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <List style={{ padding: 0 }} role="article">
          <ListItem place={placeDetails} disableGutters />
        </List>
        <Grid
          container
          direction="column"
          style={{ paddingTop: '16px', paddingBottom: '16px' }}
        >
          <Grid item>
            <Typography variant="subtitle2" style={{ color: '#A3A3A3' }}>
              {t('messages.linkToShare')}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                style={{
                  flexGrow: 1,
                  paddingRight: '10px',
                }}
              >
                <TextField
                  fullWidth
                  value={shareableLink}
                  select={false}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  style={{ borderRadius: '5px' }}
                  color="primary"
                  variant="text"
                  onClick={() => {
                    copyLink(shareableLink);
                  }}
                >
                  {t('messages.copyLink')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: '24px' }}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item>
                <FacebookShareButton
                  url={shareableLink}
                  quote={shareableMessage}
                  beforeOnClick={() => {
                    logPinSharedEventInFirebaseAnalytics('facebook');
                  }}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </Grid>
              <Grid item>
                <TwitterShareButton
                  url={shareableLink}
                  title={shareableMessage}
                  beforeOnClick={() => {
                    logPinSharedEventInFirebaseAnalytics('twitter');
                  }}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </Grid>
              <Grid item>
                <LinkedinShareButton
                  url={shareableLink}
                  beforeOnClick={() => {
                    logPinSharedEventInFirebaseAnalytics('linkedin');
                  }}
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </Grid>

              <Grid item>
                <WhatsappShareButton
                  url={shareableMessage}
                  title={'Checkout this AccessNow Pin'}
                  separator=":: "
                  beforeOnClick={() => {
                    logPinSharedEventInFirebaseAnalytics('whatsapp');
                  }}
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </Grid>
              <Grid item>
                <EmailShareButton
                  url={shareableLink}
                  subject={'Checkout this AccessNow Pin'}
                  body={shareableMessage}
                  beforeOnClick={() => {
                    logPinSharedEventInFirebaseAnalytics('email');
                  }}
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <Snackbar
        open={snackbarIsVisible}
        message={t('messages.copiedToClipboard')}
        autoHideDuration={2000}
        onClose={() => hideSnackbar()}
        action={
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              setSnackbarIsVisible(false);
            }}
          >
            <CloseIcon />
          </Button>
        }
        TransitionComponent={Slide}
      />
    </Dialog>
  );
};

export default SharePinDialog;

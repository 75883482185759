import dayjs from 'dayjs';
import EN from 'dayjs/locale/en';
import FR from 'dayjs/locale/fr';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import config from '../utils/config';
import { en, fr } from './index';

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};
const locales = { EN, FR };

i18n.on('languageChanged', function (lng) {
  dayjs.locale(lng);
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      detection: {
        order: ['querystring'],
      },
      debug: config.environment === 'production' ? false : true,
      fallbackLng: 'en',
      nonExplicitSupportedLngs: true,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        format: (value, format, lng) => {
          if (value instanceof Date) {
            const locale = locales[lng];
            return dayjs(value).format(format).locale(locale);
          }
        },
      },
      supportedLngs: ['en', 'fr'],
      resources,
    },
    (err, t) => {
      if (err) console.error(err, t);
    }
  );

export default i18n;

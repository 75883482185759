import { all, fork, put, select, takeLatest } from 'redux-saga/effects';
import { serverErrorsl10n } from '../../l10n/common';
import { api } from '../../services';
import * as mapActions from '../actions/mapActions';

export function* getPlacesList() {
  yield takeLatest('LIST_PLACES_REQUEST', function* () {
    const { filters, map } = yield select((state) => state);

    const { location, boundaries } = map;

    const reqObj = {
      location,
      boundaries,
      ...filters,
    };

    const geojsonPromise = new Promise((resolve, reject) =>
      api.placeService
        .geojson(reqObj)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    );

    try {
      const { features } = yield geojsonPromise;
      const places = features.map((place) => place.properties);
      yield put(mapActions.listPlacesSuccess(places));
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;
      yield put(mapActions.listPlacesFailure(errorMessage));
    }
  });
}

export default function* mapRootSaga() {
  yield all([fork(getPlacesList)]);
}

import { Box, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useDialogContext } from '../context';
import { authActions, accountActions } from '../store/actions';
import SuccessDialog from './SuccessDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minWidth: 360,
    minHeight: 16,
    width: 150, // Set your desired width
    display: 'flex',
    alignContent: 'center',
  },
  textField: {
    width: '100%', // Make the TextField full width
    marginBottom: theme.spacing(2),
  },
  text: {
    margin: 30,
    textAlign: 'center',
  },
  errorMessage: {
    marginBottom: 30,
    textAlign: 'center',
    color: theme.palette.warning.main,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
  buttonCancel: {
    color: theme.palette.text.pinText,
    border: '1px solid ' + theme.palette.grey[500],
    width: '85px',
    height: '25px',
  },
  buttonJoin: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: '85px',
    height: '25px',
  },
  buttonCenter: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: 10,
    alignSelf: 'center',
  },
  paddedContainer: {
    padding: 10,
    textAlign: 'center',
  },
}));

const JoinEventDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [eventCode, setEventCode] = useState('');
  const { dialogDispatch } = useDialogContext();
  const { errorMessage, successMessage, event, token } = useSelector(
    (state) => state.auth,
    shallowEqual
  );

  useEffect(() => {
    if (token) {
      dispatch(accountActions.getUserDetails());
    }
    dispatch({ type: 'CLEAR_MESSAGING' });
  }, [dispatch, token]);
  const handleSubmit = useCallback(() => {
    try {
      dispatch(authActions.subscribeToEvent(eventCode));
    } catch (error) {
      console.error('Subscription failed:', error);
    }
  }, [dialogDispatch, dispatch, eventCode]);
  const onCancel = () => {
    dialogDispatch({ type: 'CLOSE' });
  };
  const handleEventCodeChange = (event) => {
    setEventCode(event.target.value);
  };
  if (successMessage.length) {
    return (
      <SuccessDialog
        title={t('dialogTitles.successDialog')}
        desc1={t('messages.joinEventSuccessDesc1')}
        desc2={t('messages.joinEventSuccessDesc2')}
        buttonText={t('messages.startMapping')}
      />
    );
  }
  if (event) {
    return (
      <>
        <div className={classes.container}>
          <Typography className={classes.paddedContainer} variant="body1">
            {t('messages.hasActiveMapMission')}
            <p>
              {`${moment(event.expiration_date).format(
                'DD-MMM-YYYY'
              )} (${moment(event.expiration_date).fromNow()}).`}
            </p>
          </Typography>
        </div>
        <div className={classes.paddedContainer}>
          <Typography variant="body3">
            {t('messages.mapMissionFooter')}
          </Typography>
        </div>
        <div className={classes.actionButtons}>
          <Button
            className={classes.buttonCenter}
            variant="contained"
            color="primary"
            onClick={onCancel}
          >
            {t('messages.startMapping')}
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.text} variant="body1">
          {t('messages.joinEventDesc')}
        </Typography>
      </div>
      <form role="search" onSubmit={handleSubmit}>
        <Box pl={3} pr={3} mt={2} mb={2}>
          {/* Use the TextField and the eventCode state */}
          <TextField
            id="standard-basic"
            variant="standard"
            value={eventCode}
            onChange={handleEventCodeChange}
            className={classes.textField}
            maxRows={1}
            inputProps={{
              style: { textAlign: 'center', padding: '10px 0' },
            }}
            InputProps={{
              style: {
                backgroundColor: '#E7F0FE', // Set the light blue background color
                borderRadius: 8, // Set the border radius for rounded corners
              },
              disableUnderline: true, // Remove the underline
            }}
          />
        </Box>
      </form>
      {errorMessage && (
        <Typography className={classes.errorMessage} variant="body2">
          {errorMessage}
        </Typography>
      )}
      <div className={classes.actionButtons}>
        <Button
          className={classes.buttonCancel}
          color="primary"
          onClick={onCancel}
        >
          {t('messages.cancel')}
        </Button>
        <Button
          className={classes.buttonJoin}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          {t('messages.join')}
        </Button>
      </div>
    </>
  );
};

export default JoinEventDialog;

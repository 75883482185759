// // The Number.EPSILON property represents
// // the difference between 1 and the
// // smallest floating point number greater than 1.
// // ~ source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/EPSILON
// const EPSILON = 'EPSILON' in Number ? Number.EPSILON : Math.pow(2, -52);

// constraints = {
//   complexAttribute: {
//     equality: {
//       attribute: "otherComplexAttribute",
//       message: "is not complex enough",
//       comparator: function(v1, v2) {
//         return JSON.stringify(v1) === JSON.stringify(v2);
//       }
//     }
//   }
// };

// const latLngValidation = {
//   equality: {
//     attribute: 'num2',
//     comparator: function(a, b) {
//       const error = 0.001;
//       return Math.abs(a - b) - error < EPSILON;
//     },
//   },
// };

const required = {
  presence: { allowEmpty: false, message: 'is required' },
};

const username = {
  ...required,
  length: {
    maximum: 50,
    minimum: 6,
  },
  format: {
    pattern: /^[0-9a-zA-Z]+$/,
    message:
      '^Please enter a valid username. A valid username must be 6-50 characters and only consist of letters and/or numbers.',
  },
};

const name = {
  ...required,
  length: {
    maximum: 32,
  },
};

const email = {
  ...required,
  email: {
    message: '^Please enter a valid email address.',
  },
};

const password = {
  ...required,
  length: {
    minimum: 8,
  },
  format: {
    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
    message:
      '^Password must be at least 8 characters, must include at least one upper case letter, one lower case letter, and one numeric digit.',
  },
};

const policy = {
  ...required,
  checked: true,
};

export const login = {
  email,
  password: {
    ...required,
  },
};

export const editProfile = {
  username,
};

export const createAccount = {
  name,
  username,
  email,
  password,
  confirmPassword: {
    equality: {
      attribute: 'password',
      message: '^Password and Confirm Password must match.',
    },
  },
  policy,
};

export const changePassword = {
  currentPassword: {
    ...required,
  },
  newPassword: password,
  confirmPassword: {
    equality: {
      attribute: 'newPassword',
      message: '^New Password and Confirm Password must match.',
    },
  },
};

export const forgotPassword = {
  email,
};

export const changePasswordWithToken = {
  newPassword: password,
  newPasswordConfirm: {
    equality: {
      attribute: 'newPassword',
      message: '^New Password and Confirm Password must match.',
    },
  },
};

export const addBusiness = {
  name: {
    ...required,
  },
  category: {
    ...required,
  },
};

import config from './config';

export const appInit = () => {
  // disable all console logs in the production
  const noop = () => {};
  if (config.environment !== 'development') {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
  }
};

export const getOperatingSystem = (window) => {
  let operatingSystem = 'Not known';
  if (window.navigator.appVersion.indexOf('Android') !== -1) {
    operatingSystem = 'Android';
  }
  if (window.navigator.appVersion.indexOf('iPhone') !== -1) {
    operatingSystem = 'iOS';
  }
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    operatingSystem = 'Windows OS';
  }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    operatingSystem = 'MacOS';
  }
  if (window.navigator.appVersion.indexOf('X11') !== -1) {
    operatingSystem = 'UNIX OS';
  }

  return operatingSystem;
};

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.lightest,
    borderRadius: 4,
  },
  error: {
    color: theme.palette.error.dark,
    backgroundColor: theme.palette.error.lightest,
    borderRadius: 4,
    '& a': {
      color: theme.palette.error.dark,
      textDecoration: 'underline',
    },
  },
  info: {
    color: theme.palette.info.dark,
    backgroundColor: theme.palette.info.lightest,
    borderRadius: 4,
  },
  warning: {
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.lightest,
    borderRadius: 4,
  },
  message: {
    display: 'block',
    padding: theme.spacing(2),
  },
}));

const Message = (props) => {
  const classes = useStyles();
  const { className, message, variant, ...other } = props;

  return (
    <Typography
      variant="h5"
      className={clsx(classes[variant], classes.margin, className)}
      {...other}
    >
      <span className={classes.message}>{parse(message)}</span>
    </Typography>
  );
};

Message.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

export default Message;

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(0, 3, 3, 3),
    color: theme.palette.text.pinText,
  },
  flagContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: theme.spacing(0, 3, 2, 3),
  },
}));

const FeatureSummary = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isProvider } = useSelector((state) => state.auth);

  return (
    <>
      {/* {!isProvider && (
        <div className={classes.flagContainer}>
          <FlagButton
            displayName={t('flags.accessibleFromHome')}
            isSelected={false}
            onClick={handleClick}
            variant="contained"
          />
        </div>
      )} */}
      <Typography className={classes.container} paragraph>
        {
          isProvider && t('summaries.featureSummaryProvider')
          // : t('summaries.featureSummary')
        }
      </Typography>
    </>
  );
};

export default FeatureSummary;

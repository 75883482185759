const initState = {
  accessiblePlaceCount: '',
  partiallyAccessiblePlaceCount: '',
  notAccessiblePlaceCount: '',
  totalPlaceCount: '',
  isLoading: false,
  errorMessage: '',
};

const statsReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'GET_STATS_IS_LOADING':
    case 'GET_STATS_SUCCESS':
    case 'GET_STATS_FAILURE':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default statsReducer;

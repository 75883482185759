import pickBy from 'lodash.pickby';
import { categoriesl10n } from '../l10n/common';

const addDisplayName = (value) => {
  const { _id, key, subCategories } = value;

  return pickBy({
    _id: _id,
    key: key,
    displayName: categoriesl10n.en[key] || key,
    subCategories,
  });
};

const formatCategories = (categories) => {
  return categories
    .map((category) => {
      let subCategories;

      if (!!category.subCategories) {
        subCategories = category.subCategories
          .map(addDisplayName)
          .filter((category) => category.displayName)
          .sort((a, b) => a.displayName.localeCompare(b.displayName)); // sort alphabetically second
      }

      return addDisplayName({ ...category, subCategories });
    })
    .filter((category) => category.displayName)
    .sort((a, b) => a.displayName.localeCompare(b.displayName)); // sort alphabetically second
};

export default formatCategories;

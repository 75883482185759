import { IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { mapActions, searchActions } from '../store/actions';
import { track } from '../utils';

const useStyles = makeStyles((theme) => ({
  input: {
    color: theme.palette.text.pinText,
    '&::placeholder': {
      color: theme.palette.text.pinText,
    },
  },
  underline: {
    '&::before': {
      borderBottom: `1px solid ${theme.palette.text.pinText}`,
    },
  },
  label: {
    color: theme.palette.text.pinText,
  },
  icon: {
    color: theme.palette.text.pinText,
  },
}));

const SearchBar = ({ placeholder }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const [query, setQuery] = useState(searchQuery);
  const [prevCall, setPrevCall] = useState(setTimeout(() => {}, 0));
  const { t } = useTranslation();

  const onChange = (event) => {
    const str = event.target.value;
    setQuery(str);

    if (str.length < 2) {
      dispatch(searchActions.updateSearchQuery(str));
    } else {
      // we track and clear the previous call to optimize performance
      clearTimeout(prevCall);
      setPrevCall(
        setTimeout(() => {
          dispatch(searchActions.updateSearch(str));
        }, 200)
      );
    }

    track.analytics({
      category: 'search',
      data: {
        search_term: str,
      },
    });
  };

  const onClear = () => {
    setQuery('');
    dispatch(mapActions.resetPlaceDetails());
    dispatch(searchActions.updateSearchQuery(''));
    track.analytics({
      category: 'clear_search_button',
    });
  };

  return (
    <TextField
      fullWidth
      margin="none"
      id="search"
      label={t('messages.searchForPlace')}
      placeholder={placeholder}
      onChange={onChange}
      value={query}
      InputLabelProps={{
        classes: {
          root: classes.label,
        },
        shrink: true,
      }}
      InputProps={{
        classes: {
          input: classes.input,
          underline: classes.underline,
        },
        endAdornment: (
          <InputAdornment position="end">
            {!!query && (
              <IconButton
                size="small"
                onClick={onClear}
                aria-label="Clear search term"
              >
                <CloseIcon color="primary" />
              </IconButton>
            )}
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        ),
      }}
      autoComplete="off"
    />
  );
};

export default SearchBar;

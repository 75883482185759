export const DialogEnum = {
  login: 'login',
  filter: 'filter',
  forgotPassword: 'forgotPassword',
  createAccount: 'createAccount',
  editProfile: 'editProfile',
  changePassword: 'changePassword',
  myReviews: 'myReviews',
  myReplies: 'myReplies',
  joinEvent: 'joinEvent',
  successDialog: 'successDialog',
};

const initState = {
  showToast: false,
  text: '',
  callback: () => {},
};

const toastReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SHOW_TOAST':
      return {
        ...state,
        ...payload,
      };
    case 'RESET_TOAST':
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default toastReducer;

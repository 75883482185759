export const initState = {
  errorMessage: '',
  isLoading: false,
  results: [],
  detail: {
    location: {
      lat: 0,
      lng: 0,
    },
    viewport: {
      northeast: {
        lat: 0,
        lng: 0,
      },
      southwest: {
        lat: 0,
        lng: 0,
      },
    },
  },
};

const autocompleteReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'UPDATE_AUTOCOMPLETE_IS_LOADING':
    case 'UPDATE_AUTOCOMPLETE_SUCCESS':
    case 'UPDATE_AUTOCOMPLETE_FAILURE':
      return {
        ...state,
        ...payload,
      };
    case 'UPDATE_AUTOCOMPLETE_DETAIL_IS_LOADING':
    case 'UPDATE_AUTOCOMPLETE_DETAIL_SUCCESS':
    case 'UPDATE_AUTOCOMPLETE_DETAIL_FAILURE':
      return {
        ...state,
        ...payload,
      };
    case 'CLEAR_AUTOCOMPLETE':
      return initState;
    default:
      return state;
  }
};

export default autocompleteReducer;

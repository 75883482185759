import * as mapActions from './mapActions';

export const update = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_FILTERS',
      payload,
    });
  };
};

export const clear = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'CLEAR_FILTERS',
    });

    dispatch(mapActions.listPlacesRequest());
  };
};

/**
 * trigger to tell the app to apply the filter
 */
export const apply = (payload) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'APPLY_FILTERS',
    });

    dispatch(mapActions.listPlacesRequest());
  };
};

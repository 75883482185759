import { serverErrorsl10n } from '../../l10n/common';
import * as api from '../../services/api';
import { SearchArgs } from '../../services/api/types';
import { formatLabels, isPlace } from '../../utils';

export const resetLocation = () => {
  return (dispatch) => {
    dispatch({
      type: 'RESET_SEARCH_LOCATION',
      payload: {
        location: {
          name: '',
          latitude: 0,
          longitude: 0,
        },
        country: '',
      },
    });
  };
};

export const updateLocation = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_SEARCH_LOCATION',
      payload: {
        location: payload.location,
        country: payload.country,
      },
    });
  };
};

export const updateSearchQuery = (payload: string) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_SEARCH_QUERY',
      payload: {
        searchQuery: payload,
      },
    });
  };
};

export const updateSearch = (query: string, pageToken: string) => {
  return async (dispatch, getState) => {
    const { filters, search, map } = getState();
    const { searchResults } = getState().search;
    const { location, country } = search;
    const { boundaries } = map;

    const reqObj: SearchArgs = {
      query,
      unit: 'km',
      location,
      country,
      boundaries,
      nextPageToken: pageToken,
      ...filters,
    };

    if (query) {
      dispatch({
        type: 'UPDATE_SEARCH_IS_LOADING',
        payload: {
          isLoading: true,
          searchQuery: query,
        },
      });

      try {
        const { results, nextPageToken } = await api.placeService.search(
          reqObj
        );

        let newSearchResults;

        if (pageToken) {
          newSearchResults = await searchResults.concat(
            results
              .filter((item) => isPlace(item.placeType))
              .map((result) => ({
                ...result,
                labels: formatLabels(result.labels),
              }))
          );
        } else {
          newSearchResults = await results
            .filter((item) => isPlace(item.placeType))
            .map((result) => ({
              ...result,
              labels: formatLabels(result.labels),
            }));
        }

        await dispatch({
          type: 'UPDATE_SEARCH_SUCCESS',
          payload: {
            isLoading: false,
            searchResults: newSearchResults,
            nextPageToken,
          },
        });
      } catch (error) {
        if (error.message.includes('metadata')) return;
        const errorMessage =
          serverErrorsl10n.en[error.message] ||
          serverErrorsl10n.en.GENERIC_ERROR;

        dispatch({
          type: 'UPDATE_SEARCH_FAILURE',
          payload: {
            isLoading: false,
            errorMessage,
          },
        });
      }
    }
  };
};

export const clearSearch = () => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_SEARCH',
      payload: {
        searchQuery: '',
        searchResults: [],
        nextPageToken: null,
      },
    });
  };
};

import Resizer from 'react-image-file-resizer';

export const resizeAndSaveImage = (image) => {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      image.file,
      1000, // max width
      1000, // max height
      'JPG' || 'JPEG' || 'PNG', // output format
      100, // quality
      0, // rotation
      (resizedImage) => {
        const { type, filename } = getFileNameAndType(image);
        resolve({
          uri: image.uri,
          name: filename,
          type: type,
        });
      }
    );
  });
};

export const getFileNameAndType = (image) => {
  const type = image.file.type || 'image/jpg';
  const splitType = type ? type.split('/') : [];
  const fileExtension = splitType.length === 2 ? splitType[1] : '';
  const filename = `${image.name.replace(/\.$/, '')}.${fileExtension}`;

  return { type, filename, fileExtension };
};

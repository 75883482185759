export const getDistanceUnit = (distanceUnit = 0) => {
  return Number(distanceUnit) === 0 ? 'km' : 'mi';
};

// https://gis.stackexchange.com/questions/60752/calculating-bounding-box-by-given-center-and-scale-in-android/60777#answer-60777
const resolutionFromScale = (scale = 1) => {
  const INCHES_PER_UNIT = 4374754; // https://github.com/openlayers/ol2/blob/master/lib/OpenLayers/Util.js
  const DOTS_PER_INCH = 160 * scale;
  const normalizedScale = 1 / 15000; //this is the map scale, 1:15000

  return 1 / (INCHES_PER_UNIT * DOTS_PER_INCH * normalizedScale);
};

export const getInitialBoundaries = (center, dimensions) => {
  const { latitude, longitude } = center;
  const { width, height, scale } = dimensions;
  const resolution = resolutionFromScale(scale);
  const halfWDeg = (width * resolution) / 1.5;
  const halfHDeg = (height * resolution) / 2.5;

  const top = latitude + halfHDeg;
  const right = longitude + halfWDeg;
  const bottom = latitude - halfHDeg;
  const left = longitude - halfWDeg;

  return { top, right, bottom, left };
};

export const formatLatLng = (number = 0) =>
  Number(parseFloat(number).toFixed(7));

/* eslint-disable no-undef */
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { useGoogleMap } from '@react-google-maps/api';
import React, { memo, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';

import { getCurrentLocation } from '../../services/geolocation';
import { mapActions } from '../../store/actions';

const useStyles = makeStyles((theme) => ({
  mapControl: {
    padding: 0,
    right: 16,
    bottom: 23,
    borderRadius: 2,
    minWidth: 32,
    minHeight: 32,
  },
  mapControlIcon: {
    color: theme.palette.text.secondary,
  },
}));

const MyLocationButton = memo(({ position }) => {
  const classes = useStyles();
  const map = useGoogleMap();
  const dispatch = useDispatch();
  const [container] = useState(document.createElement('div'));
  const [loading, setLoading] = useState(false);
  const blueDot = useRef();
  const isMounted = useRef();

  useEffect(() => {
    isMounted.current = true;
    if (map) {
      const controls = map.controls[google.maps.ControlPosition[position]];
      controls.push(container);
    }
    return () => {
      isMounted.current = false;
    };
  }, [container, map, position]);

  const handleClick = async () => {
    setLoading(true);

    try {
      const { latitude, longitude } = await getCurrentLocation();
      if (isMounted.current) {
        const myPosition = {
          lat: latitude,
          lng: longitude,
        };
        map.setCenter(myPosition);
        map.setZoom(16);

        // search map boundary places
        dispatch(mapActions.searchMapBoundaries(map));

        if (!blueDot.current) {
          blueDot.current = new google.maps.Marker({
            title: 'Current Position',
            position: myPosition,
            icon: {
              url: '/images/blue-dot.png',
              scaledSize: new google.maps.Size(40, 40),
            },
          });
          blueDot.current.setMap(map);
        } else {
          blueDot.current.position = myPosition;
        }
      }
    } catch (error) {
      alert(error);
    }
    if (isMounted.current) {
      setLoading(false);
    }
  };

  const button = (
    <Button
      size="small"
      title="Locate me"
      aria-label="Locate me"
      tabIndex="-1"
      className={classes.mapControl}
      variant="contained"
      onClick={handleClick}
    >
      {loading ? (
        <CircularProgress color="primary" size="1.5em" />
      ) : (
        <MyLocationIcon fontSize="small" className={classes.mapControlIcon} />
      )}
    </Button>
  );

  return createPortal(button, container);
});

export default MyLocationButton;

const initState = {
  _id: '',
  text: '',
  tags: [],
  rating: undefined,
  isLoading: false,
  errorMessage: '',
  images: [],
  removedImages: [],
  flaggedImages: [],
};

const editReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'EDIT_REVIEW_IS_LOADING':
    case 'EDIT_UPDATE':
    case 'EDIT_REVIEW_FAILURE':
    case 'EDIT_REVIEW_SUCCESS':
      return {
        ...state,
        ...payload,
      };
    case 'EDIT_RESET':
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default editReducer;

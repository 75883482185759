/* eslint-disable no-shadow */
import { history } from '../';
import { serverErrorsl10n } from '../../l10n/common';
import { api } from '../../services';
import { ADD_PATH, track } from '../../utils';
import getQueryStringLanguage from '../../utils/helpers';

export const reset = () => {
  return {
    type: 'ADD_RESET',
  };
};

export const update = (payload) => {
  return {
    type: 'ADD_UPDATE',
    payload: {
      errorMessage: '',
      ...payload,
    },
  };
};

export const send = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'ADD_IS_LOADING',
      payload: {
        isLoading: true,
        errorMessage: '',
        flaggedImages: [],
      },
    });

    try {
      const response = await api.placeService.create(data);
      const lngQueryParam = getQueryStringLanguage();

      history.push(`/details/${response._id}/local/success${lngQueryParam}`, {
        from: ADD_PATH,
      });

      track.analytics({
        category: 'add_pin',
        data: {
          success: response._id,
        },
      });

      dispatch({
        type: 'ADD_SUCCESS',
      });
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;
      dispatch({
        type: 'ADD_FAILURE',
        payload: {
          isLoading: false,
          errorMessage,
          flaggedImages: error.data?.flaggedImages || [],
        },
      });
    }
  };
};

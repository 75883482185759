const filterState = {
  categoryOptions: [],
  subCategoryOptions: [],
  servicesOptions: [],
  category: 'none',
  subCategory: 'none',
  checkedServicesState: [],
};

const pageState = {
  currentPage: 1,
  isServicesDialogVisible: false,
  isAddBusinessDialogVisible: false,
  selectedSortBy: 'mostRecent',
  selectedResultsPerPage: 12,
  isLoading: false,
};

const queryState = {
  resultsPerPage: 12,
  query: '',
  location: '',
  orderBy: 'DESC',
  sortBy: 'updated',
  categoryId: '',
  services: [],
};

export default {
  ...filterState,
  ...pageState,
  ...queryState,
};

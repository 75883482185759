export default {
  50: '#fbece5',
  100: '#f4d0be',
  200: '#edb092',
  300: '#e59066',
  400: '#e07946',
  500: '#da6125',
  600: '#d65921',
  700: '#d04f1b',
  800: '#cb4516',
  900: '#c2330d',
  A100: '#fff2ef',
  A200: '#ffc7bc',
  A400: '#ff9d89',
  A700: '#ff886f',
};

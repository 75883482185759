import { Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PhotoCamera } from '@material-ui/icons';
import xor from 'lodash.xor';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDialogContext } from '../context';
import { addActions, editActions } from '../store/actions';
import { track } from '../utils';
import { IMAGE_LIMIT } from '../utils/constants';
import ANGrid from './ANGrid';
import CustomAccordion from './CustomAccordion';
import { RatingButton } from './';

const useStyles = makeStyles((theme) => ({
  placeContainer: {
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  ratingContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 3, 0, 3),
  },
  tagContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1.5, 3, 0, 3),
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 3, 0, 3),
  },
  descriptionContainer: {
    flex: 1,
    padding: theme.spacing(2, 3, 0, 3),
    ...theme.typography.body1,
  },
  descriptionForm: {
    paddingBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
  },
  buttonContainer: {
    padding: theme.spacing(0, 3, 3, 3),
    margin: theme.spacing(3, 0, 0, 0),
  },
}));

const AddForm = () => {
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.tags.categories);
  const ratings = useSelector((state) => state.ratings.values);
  const addState = useSelector((state) => state.add, shallowEqual);
  const editState = useSelector((state) => state.edit, shallowEqual);
  const classes = useStyles();
  const isMounted = useRef(false);
  const previousRating = useRef(addState.rating || editState.rating || '');
  const [selectedRating, setSelectedRating] = useState(editState.rating || '');
  const [selectedTags, setSelectedTags] = useState(
    addState.tags || editState.tags || []
  );
  const addImages = useSelector((state) => state.add.images || []);
  const editImages = useSelector((state) => state.edit.images || []);
  const imagesToDisplay = editState._id ? editImages : addImages;
  const images = useSelector(
    (state) => state.add.images || state.edit.images || []
  );
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    setSelectedImages(images);
  }, [images]);

  const [isValidReview, setIsValidReview] = useState(addState.isReviewValid);
  const [reviewText, setReviewText] = useState(
    addState.text || editState.text || ''
  );
  const { t } = useTranslation();
  const { dialogDispatch } = useDialogContext();
  useEffect(() => {
    if (isMounted.current) {
      if (!previousRating.current) {
        track.analytics({
          category: 'rating_selected',
          data: {
            rating: selectedRating,
          },
        });
      } else {
        track.analytics({
          category: 'rating_changed',
          data: {
            from: previousRating.current,
            to: selectedRating,
          },
        });
      }
      previousRating.current = selectedRating;
      if (editState._id) {
        dispatch(
          editActions.update({
            rating: selectedRating,
          })
        );
      } else {
        dispatch(
          addActions.update({
            rating: selectedRating,
            isReviewValid: isValidReview,
          })
        );
      }
    }
    isMounted.current = true;
  }, [
    isMounted,
    selectedRating,
    previousRating,
    dispatch,
    isValidReview,
    editState._id,
  ]);

  // Function to remove an image from the state (both server and locally added images)
  const handleRemoveImage = (item) => {
    // Check if the image is locally added (has 'isLocalImage' property)
    if (item.isLocalImage) {
      // If the image is local, remove it from the local state only
      const updatedImages = selectedImages.filter(
        (image) => image.name !== item.name
      );
      setSelectedImages(updatedImages);
      // Also update the addState so that the images persist when navigating back from the ANGrid

      // If editState is available (not in add mode), remove the locally added image from editState as well
      if (editState._id) {
        const updatedEditImages = editState.images.filter(
          (image) => image.name !== item.name
        );
        dispatch(
          editActions.update({
            images: updatedEditImages,
          })
        );
        setSelectedImages(updatedImages);
      } else {
        dispatch(
          addActions.update({
            images: updatedImages,
          })
        );
        setSelectedImages(updatedImages);
      }
    } else {
      const updatedEditImages = editState.images.filter(
        (image) => image.url !== item.url
      );
      dispatch(
        editActions.update({
          images: updatedEditImages,
          removedImages: [...editState.removedImages, item],
        })
      );
      setSelectedImages(updatedEditImages);
    }
  };
  const updateSelectedTags = (value) => {
    const accum = xor(selectedTags, [value]);
    // analytics
    const data = {};
    data[accum.includes(value) ? 'added' : 'removed'] = value;
    track.analytics({
      category: 'tag_pin',
      data,
    });
    setSelectedTags(accum);
    // update global state
    if (editState._id) {
      dispatch(
        editActions.update({
          tags: accum,
        })
      );
    } else {
      dispatch(
        addActions.update({
          tags: accum,
        })
      );
    }
  };

  const listTags = () => {
    return tags.map((categoryObj, i) => {
      const { category, tags: categoryTags } = categoryObj;
      return (
        <CustomAccordion
          key={i}
          category={category}
          tags={categoryTags}
          selectedTags={selectedTags}
          updateSelectedTags={updateSelectedTags}
        />
      );
    });
  };

  const reviewTextLength = (reviewText && reviewText.length) || 0;
  const reviewTextCounter = `${1000 - reviewTextLength} ${t(
    'messages.charactersRemaining'
  )}`;

  const handleDescriptionChange = (event) => {
    const str = event.target.value;
    setReviewText(str);
    if (editState._id) {
      dispatch(
        editActions.update({
          text: str,
        })
      );
    } else {
      dispatch(
        addActions.update({
          text: str,
        })
      );
    }
  };

  const handleRatingChange = (key) => {
    setSelectedRating(key);
    setIsValidReview(true);
  };
  const onAddPhotos = () => {
    dialogDispatch({ type: 'OPEN_AS', payload: 'addPhotos' });
  };
  return (
    <>
      <div className={classes.placeContainer}>
        <Typography variant="h4">{addState.name}</Typography>
        <Typography variant="body2">{addState.address}</Typography>
      </div>
      <div className={classes.ratingContainer}>
        {ratings.map((rating, i) => {
          const selected = rating.key === selectedRating;
          return (
            <RatingButton
              variant="medium"
              title={t(`ratings.${rating.key}`)}
              selected={selected}
              rating={rating.key}
              onClick={() => {
                handleRatingChange(rating.key);
              }}
              key={i}
            />
          );
        })}
      </div>
      <div className={classes.tagContainer}>{listTags()}</div>
      <div className={classes.imageContainer}>
        {selectedImages && (
          <ANGrid
            images={imagesToDisplay}
            handleRemoveImage={handleRemoveImage}
          />
        )}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          size="large"
          onClick={onAddPhotos}
          disabled={addState.isLoading || imagesToDisplay.length >= IMAGE_LIMIT}
        >
          <PhotoCamera fontSize="small" /> {'      '}
          {` ${t('messages.addPhotos')} (${
            imagesToDisplay.length
          }/${IMAGE_LIMIT})`}
        </Button>
      </div>
      <div className={classes.descriptionContainer}>
        <form noValidate autoComplete="off" className={classes.descriptionForm}>
          <TextField
            id="outlined-multiline-static"
            multiline
            minRows="5"
            label={t('messages.pleaseWriteAReview')}
            variant="outlined"
            style={{ width: '100%' }}
            helperText={reviewTextCounter}
            inputProps={{ maxLength: 1000 }}
            value={reviewText}
            onChange={handleDescriptionChange}
          />
        </form>
      </div>
    </>
  );
};

export default AddForm;

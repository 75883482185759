/* eslint-disable no-undef */
import { Button, makeStyles } from '@material-ui/core';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import { useGoogleMap } from '@react-google-maps/api';
import React, { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const useStyles = makeStyles((theme) => ({
  mapControl: {
    padding: 0,
    right: 16,
    bottom: 12,
    borderRadius: 2,
    minWidth: 32,
    minHeight: 32,
  },
  mapControlIcon: {
    color: theme.palette.text.secondary,
  },
}));

const ZoomOutButton = memo(({ initialCenter, position }) => {
  const classes = useStyles();
  const map = useGoogleMap();
  const [container] = useState(document.createElement('div'));

  useEffect(() => {
    if (map) {
      const controls = map.controls[google.maps.ControlPosition[position]];
      controls.push(container);
    }
  }, [container, map, position]);

  const handleClick = () => {
    map.setCenter(initialCenter);
    map.setZoom(3);
  };

  const button = (
    <Button
      size="small"
      title="Full map"
      aria-label="Full map"
      className={classes.mapControl}
      variant="contained"
      onClick={handleClick}
    >
      <ZoomOutMapIcon fontSize="small" className={classes.mapControlIcon} />
    </Button>
  );

  return createPortal(button, container);
});

export default ZoomOutButton;

import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addActions, mapActions, searchActions } from '../store/actions';
import { track } from '../utils';
import getQueryStringLanguage from '../utils/helpers';
import { ListItem } from './';

const useStyles = makeStyles((theme) => ({
  noResultContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noResultIcon: {
    margin: theme.spacing(6, 0, 6, 0),
    width: '60%',
  },
  noResultMsg: {
    margin: theme.spacing(2, 0, 0, 0),
    width: '60%',
    textAlign: 'center',
    fontSize: 16,
  },
}));

const ResultsList = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { searchResults, searchQuery, nextPageToken, isLoading } = useSelector(
    (state) => state.search
  );
  const { t } = useTranslation();

  const onSearchResultClick = (place) => {
    const lngQueryParam = getQueryStringLanguage();

    const { _id, types, longitude, latitude } = place;
    const boundaries = {
      top: latitude + 0.05,
      bottom: latitude - 0.05,
      left: longitude - 0.1,
      right: longitude + 0.1,
    };

    if (!_id && !types.includes('establishment')) {
      dispatch(mapActions.updateBoundaries(boundaries));
      dispatch(mapActions.updateLocation({ latitude, longitude }));
      dispatch(
        searchActions.updateLocation({ location: { latitude, longitude } })
      );
      dispatch(mapActions.listPlacesRequest());
    } else if (!place._id) {
      dispatch(addActions.update(place));
      history.push(`/details/${place.servicePlaceId}/google/${lngQueryParam}`);
    } else {
      track.analytics({
        category: 'select_content',
        data: {
          content_type: 'pin_detail',
          item_id: place._id,
          origin: 'results_list',
        },
      });
      history.push(`/details/${place._id}/local/${lngQueryParam}`);
    }
  };

  useEffect(() => {
    ref.current.scrollTop = 0;
  }, [searchQuery]);

  const handleLoadMore = useCallback(
    (event) => {
      if (
        event.target.scrollTop ===
          event.target.scrollHeight - event.target.clientHeight &&
        nextPageToken
      ) {
        dispatch(searchActions.updateSearch(searchQuery, nextPageToken));
      }
    },
    [dispatch, nextPageToken, searchQuery]
  );

  return (
    <div style={{ flex: 1, opacity: isLoading ? 0.5 : 1 }}>
      <List
        ref={ref}
        style={{
          maxHeight: window.innerHeight - 160,
          overflowY: 'auto',
          padding: 0,
        }}
        aria-label="Search Results"
        role="list"
        onScroll={handleLoadMore}
      >
        {searchResults.length < 1 ? (
          <div className={classes.noResultContainer}>
            <img
              alt="no-result"
              src={`/images/noresults_icon.svg`}
              className={classes.noResultIcon}
            />
            <h3 className={classes.noResultMsg}>
              {t('messages.noResultTitle')}
            </h3>
            <span className={classes.noResultMsg}>
              {t('messages.noResultMsg')}
            </span>
          </div>
        ) : (
          searchResults.map((place, index) => {
            return (
              <ListItem
                place={place}
                onClick={!isLoading && onSearchResultClick}
                key={index}
              />
            );
          })
        )}
      </List>
    </div>
  );
};

export default ResultsList;

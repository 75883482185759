import { Avatar, Button, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Carousel, Message, ReviewMenu, Tags } from '../components';
import { useDialogContext } from '../context';
import { serverErrorsl10n } from '../l10n/common';
import {
  addActions,
  reviewActions,
  componentAction,
  editActions,
} from '../store/actions';
import getQueryStringLanguage from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  ratingIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing(1.5),
    height: theme.spacing(2.5),
    marginRight: theme.spacing(0.5),
  },
  ratingIcon: {
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
    borderRadius: '50%',
  },
  accessible: {
    backgroundColor: theme.palette.accessible,
  },
  partiallyAccessible: {
    backgroundColor: theme.palette.partiallyAccessible,
  },
  notAccessible: {
    backgroundColor: theme.palette.notAccessible,
  },
  unrated: {
    backgroundColor: 'white',
    border: '2px solid #BDBDBD',
  },
  container: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  profileIcon: {
    width: 40,
    height: 40,
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  lineItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 0, 0.25, 0),
  },
  infoText: {
    color: theme.palette.text.pinText,
  },
  threadedReviewsContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    borderLeft: `solid 2.5px ${theme.palette.grey.lighter}`,
  },
  replyContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    borderLeft: `solid 2.5px ${theme.palette.grey.lighter}`,
  },
  profileIconThreaded: {
    width: 28,
    height: 28,
    marginLeft: theme.spacing(2),
  },
  replyButton: {
    justifyContent: 'flex-start',
    padding: 0,
  },
  button: {
    width: '55px',
    height: '30px',
    marginRight: '3px',
    alignSelf: 'flex-end',
  },
  editReview: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    width: '100%',
    padding: 0,
    marginLeft: 0,
  },
}));

const Review = ({ review, threadedReview }) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth);
  const authAvatar = useSelector((state) => state.auth.avatar);
  const authId = useSelector((state) => state.auth._id);
  const { isProvider } = useSelector((state) => state.auth);
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { dialogDispatch } = useDialogContext();
  const { t } = useTranslation();
  const addState = useSelector((state) => state.add, shallowEqual);
  const editState = useSelector((state) => state.edit, shallowEqual);
  const componentState = useSelector((state) => state.component);
  const [reviewText, setReviewText] = useState(
    addState.text || editState.text || ''
  );
  const selectedPlaceDetails = useSelector(
    (state) => state.map.selectedPlaceDetails
  );
  const {
    _id,
    rating,
    createdAt,
    updatedAt,
    tags,
    text,
    place,
    user,
    images,
    children,
  } = review;
  const avatar =
    (user && user.avatar) || (place && authAvatar) || `/images/avatar.svg`;
  const { isLoading, isReviewValid } = addState;
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    setReviewText(editState.text);
  }, [editState.text]);

  const onClickPlaceDetails = () => {
    dialogDispatch({ type: 'CLOSE' });

    const lngQueryParam = getQueryStringLanguage();

    history.push(`/details/${place._id}/local/${lngQueryParam}`);
  };

  const handleDescriptionChange = (event) => {
    const str = event.target.value;
    setReviewText(str);
    if (editState._id) {
      dispatch(
        editActions.update({
          text: str,
        })
      );
    } else {
      dispatch(
        addActions.update({
          text: str,
        })
      );
    }
  };
  const onAddRequest = async (data) => {
    if (!isLoading && isReviewValid) {
      setValidationErrorMessage('');
      await dispatch(addActions.send(data));
      dispatch(addActions.reset());
      dispatch(componentAction.setComponentVisible(''));
      dispatch(reviewActions.reviewsByPlace(selectedPlaceDetails._id));
    } else {
      setValidationErrorMessage(serverErrorsl10n.en.invalidReview);
    }
  };
  const onAdd = async () => {
    if (!isAuthenticated) {
      dialogDispatch({ type: 'OPEN_AS', payload: 'login' });
    } else {
      await dispatch(
        addActions.update({ parentId: _id, eventId: authUser?.eventId })
      );
      onAddRequest(addState);
    }
  };

  const reviewTextLength = (reviewText && reviewText.length) || 0;
  const reviewTextCounter = `${1000 - reviewTextLength} ${t(
    'messages.charactersRemaining'
  )}`;

  const handleReply = () => {
    if (componentState.componentId === review._id) {
      dispatch(componentAction.setComponentVisible(''));
      dispatch(editActions.reset());
      dispatch(addActions.reset());
    } else {
      dispatch(editActions.reset());
      dispatch(componentAction.setComponentVisible(_id));
      dispatch(
        addActions.update({
          isReviewValid: true,
          ...selectedPlaceDetails,
          tags: [],
          description: '',
          text: '',
          parentId: _id,
          rating: 'unrated',
        })
      );
    }
  };
  const onEdit = async () => {
    await dispatch(editActions.editReview(editState));
    dispatch(editActions.reset());
    dispatch(addActions.reset());
    dispatch(reviewActions.reviewsByPlace(selectedPlaceDetails._id));
  };
  return (
    <>
      <div
        className={
          threadedReview ? classes.threadedReviewsContainer : classes.container
        }
      >
        {!!user && (
          <Avatar
            src={avatar}
            className={
              threadedReview ? classes.profileIconThreaded : classes.profileIcon
            }
          />
        )}
        <div className={clsx(classes.body, !!user && classes.marginLeft)}>
          {(place && place.name && (
            <Link
              href="#"
              className={classes.lineItem}
              variant="h5"
              noWrap
              onClick={onClickPlaceDetails}
            >
              {place.name}
            </Link>
          )) || (
            <Typography
              className={classes.infoText}
              variant="h5"
              style={{ marginBottom: !threadedReview && theme.spacing(0.75) }}
            >
              {(user && user.username) || ''}
            </Typography>
          )}

          {place && place.address && (
            <div className={classes.lineItem}>
              <Typography className={classes.infoText} variant="body2">
                {place.address}
              </Typography>
            </div>
          )}
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginBottom: theme.spacing(0.5) }}
          >
            {dayjs(
              new Date(updatedAt !== null ? updatedAt : createdAt)
            ).fromNow()}
          </Typography>
          {!threadedReview && (
            <>
              <div className={classes.lineItem}>
                {!(rating === 'unrated') && (
                  <>
                    <div className={classes.ratingIconContainer}>
                      <div
                        className={clsx(classes.ratingIcon, classes[rating])}
                      />
                    </div>
                    <div style={{ verticalAlign: 'text-bottom' }}>
                      <Typography
                        variant="caption"
                        component={'span'}
                        color="textSecondary"
                        style={{
                          marginBottom: theme.spacing(0.5),
                          fontSize: '12px',
                        }}
                      >
                        {t('messages.rated')} {t(`ratings.${rating}`)}
                      </Typography>
                    </div>
                  </>
                )}
              </div>
              <div className={classes.lineItem}>
                {!!tags.length && (
                  <Tags
                    {...{ tags }}
                    iconStyle={{
                      marginRight: theme.spacing(0.5),
                      fontSize: 'small',
                    }}
                    variant="caption"
                    style={{
                      marginBottom: theme.spacing(0.5),
                      fontSize: '12px',
                    }}
                  />
                )}
              </div>
            </>
          )}
          {editState._id === review._id &&
          isProvider &&
          editState.errorMessage === '' &&
          !componentState.componentId &&
          !place ? (
            <div className={classes.editReview}>
              <form noValidate autoComplete="off" className={classes.editForm}>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  minRows="5"
                  label={isProvider ? 'Edit comment' : 'Edit review'}
                  variant="outlined"
                  style={{ width: '100%' }}
                  helperText={reviewTextCounter}
                  inputProps={{ maxLength: 1000 }}
                  inputRef={(input) => input && input.focus()}
                  value={editState.text}
                  onChange={handleDescriptionChange}
                />
              </form>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                size="medium"
                onClick={onEdit}
                disabled={reviewText.length < 5}
              >
                {t('messages.done')}
              </Button>
            </div>
          ) : (
            <div
              className={classes.lineItem}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Typography className={classes.infoText} variant="body2">
                {text}
              </Typography>
              {editState._id === review._id && editState.errorMessage && (
                <Message
                  id="form-dialog-description"
                  variant="error"
                  message={editState.errorMessage}
                  style={{ flex: 1 }}
                />
              )}
            </div>
          )}
          <Carousel images={images} />
          {isProvider && !threadedReview && !place && (
            <Button
              style={{
                color:
                  componentState.componentId !== review._id
                    ? `${theme.palette.primary.main}`
                    : `${theme.palette.grey.main}`,
              }}
              className={classes.replyButton}
              onClick={handleReply}
            >
              {componentState.componentId === review._id
                ? t('messages.close')
                : t('messages.reply')}
            </Button>
          )}
        </div>
        {authId === user._id && <ReviewMenu {...{ review }} />}
      </div>
      {componentState.componentId === review._id && !editState._id && (
        <div className={classes.replyContainer}>
          <form
            noValidate
            autoComplete="off"
            className={classes.descriptionForm}
          >
            <TextField
              id="outlined-multiline-static"
              multiline
              minRows="5"
              label={t('messages.addComment')}
              variant="outlined"
              style={{ width: '93%', marginLeft: '25px' }}
              helperText={reviewTextCounter}
              inputProps={{ maxLength: 1000 }}
              value={reviewText}
              onChange={handleDescriptionChange}
            />
          </form>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            size="medium"
            onClick={onAdd}
            disabled={reviewText.length < 5}
          >
            {t('messages.reply')}
          </Button>
        </div>
      )}
      {children &&
        children.map((threadedReview) => {
          return (
            <>
              <Review
                review={threadedReview}
                threadedReview={true}
                key={threadedReview._id}
              />
            </>
          );
        })}
    </>
  );
};

export default Review;

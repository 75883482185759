import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LocalOffer } from '@material-ui/icons';
import capitalize from 'lodash.capitalize';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-start',
    color: theme.palette.text.pinText,
  },
}));

const Tags = ({ tags = [], iconStyle, variant, style }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const translatedTags = tags
    .map((tag) => {
      const newTag = tag.replace(/^'(.*)'$/, '$1').toLowerCase();

      return t(`tags.${newTag}`) || capitalize(newTag);
    })
    .filter((tag) => tag)
    .join(', ');

  return (
    <div className={classes.container}>
      <LocalOffer
        fontSize="small"
        className={classes.icon}
        style={{ ...iconStyle }}
      />
      <Typography variant={variant} color="textSecondary" style={style}>
        {translatedTags}
      </Typography>
    </div>
  );
};

export default Tags;

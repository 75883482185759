import { produce } from 'immer';
import config from '../../utils/config';
import formatCategories from '../../utils/format-categories';
import formatServices from '../../utils/format-services';

const logger = (type, payload) => {
  if (config.environment === 'local') {
    payload ? console.log(`${type}:`, payload) : console.log(`${type}`);
  }
};

export default produce((draft, action) => {
  const { type, payload } = action;
  logger(type, payload);
  switch (type) {
    case 'CLEAR_FILTERS':
      draft.currentPage = 1;
      draft.category = 'none';
      draft.categoryId = '';
      draft.subCategory = 'none';
      draft.subCategoryOptions = [];
      draft.services = [];
      draft.checkedServicesState = [];
      return;
    case 'SET_CHECKED_SERVICES_STATE':
      draft.checkedServicesState = payload;
      return;
    case 'SET_ADD_BUSINESS_DIALOG_VISIBLE':
      draft.isAddBusinessDialogVisible = payload;
      return;
    case 'SET_SERVICES_DIALOG_VISIBLE':
      draft.isServicesDialogVisible = payload;
      return;
    case 'SET_QUERY':
      draft.currentPage = 1;
      draft.query = payload.query || '';
      draft.location = payload.location || '';
      return;
    case 'SET_RESULTS_PER_PAGE':
      draft.currentPage = 1;
      draft.selectedResultsPerPage = payload;
      draft.resultsPerPage = payload === 'All' ? '' : payload;
      return;
    case 'SET_SERVICES_OPTIONS':
      draft.servicesOptions = formatServices(payload);
      return;
    case 'SET_CATEGORY_OPTIONS':
      draft.categoryOptions = formatCategories(payload);
      return;
    case 'SET_CATEGORY':
      draft.currentPage = 1;
      draft.category = payload.name;
      draft.categoryId = payload.id;
      draft.subCategoryOptions = [];
      draft.subCategory = 'none';
      return;
    case 'SET_SUB_CATEGORY_OPTIONS':
      draft.subCategoryOptions = payload;
      return;
    case 'SET_SUB_CATEGORY':
      draft.currentPage = 1;
      draft.subCategory = payload.name;
      draft.categoryId = payload.id;
      return;
    case 'SET_SERVICES':
      draft.currentPage = 1;
      draft.services = payload;
      draft.isServicesDialogVisible = false;
      return;
    case 'SET_SELECTED_SORT_BY':
      draft.selectedSortBy = payload;
      switch (payload) {
        case 'aZ':
          draft.sortBy = 'name';
          draft.orderBy = 'ASC';
          return;
        case 'zA':
          draft.sortBy = 'name';
          draft.orderBy = 'DESC';
          return;
        default:
          // mostRecent (we use the updated date since it's when it was approved/published)
          draft.sortBy = 'updated';
          draft.orderBy = 'DESC';
          return;
      }
    case 'SET_CURRENT_PAGE':
      draft.currentPage = payload;
      return;
    case 'SET_IS_LOADING':
      draft.isLoading = payload;
      return;
  }
});

import pickBy from 'lodash.pickby';
import { servicesl10n } from '../l10n/common';

const formatServices = (services) => {
  return services
    .map((service) => {
      const serviceId = service?._id;
      return pickBy({
        _id: serviceId,
        key: serviceId,
        displayName: servicesl10n.en[serviceId] || serviceId,
      });
    })
    .filter((service) => service.displayName)
    .sort((a, b) => a.displayName.localeCompare(b.displayName)); // sort alphabetically second
};

export default formatServices;

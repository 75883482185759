import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import csc from 'country-state-city';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DialogTitle, Message } from '../';
import { useDirectoryContext } from '../../context';
import { serverErrorsl10n } from '../../l10n/common';
import * as api from '../../services/api';
import { config, track } from '../../utils';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(1.5),
    },
  },
  loader: {
    width: '100%',
  },
  message: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  businessName: {
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControlWrapper: {
    display: 'flex',
    justifyContent: 'stretch',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  formControl: {
    flex: 1,
    marginTop: 8,
    marginBottom: 4,
  },
  lineItem: {
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  servicesLabel: {
    fontSize: '12px',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0),
  },
  row: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  phoneWrapper: {
    display: 'flex',
    flex: 0.76,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
  phone: {
    flex: 0.8,
    marginRight: theme.spacing(1),
  },
  phoneExt: {
    flex: 0.2,
    marginLeft: theme.spacing(1),
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
  },
  listItem: {
    width: '50%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  button: {
    alignSelf: 'flex-end',
    margin: theme.spacing(2, 0),
    minWidth: 180,
  },
}));

const validationSchema = () => {
  return yup.object().shape({
    name: yup.string().required('This is a required field'),
    address: yup.string(),
    address2: yup.string(),
    postalCode: yup.string(),
    phone: yup.string(),
    phoneExt: yup.string(),
    url: yup.string().url('This URL must be valid'),
    category: yup
      .string()
      .matches(/^(?!.*(none))/, 'This is a required field')
      .required('This is a required field'),
  });
};

const initialRequestState = {
  isLoading: false,
  errorMessage: '',
  isSuccessful: false,
};

const AddBusinessDialog = () => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const recaptchaRef = useRef();
  const dialogContentRef = useRef();
  const { state, dispatch } = useDirectoryContext();
  const { isAddBusinessDialogVisible } = state;
  const { categoryOptions, servicesOptions } = state;
  const [categoryState, setCategoryState] = useState({});
  const [servicesState, setServicesState] = useState([]);
  const [countryStateCity, setCountryStateCity] = useState({});
  const [requestState, setRequestState] = useState(initialRequestState);
  const {
    register,
    handleSubmit,
    errors,
    unregister,
    setValue,
    trigger,
    reset,
    getValues,
  } = useForm({
    mode: 'onblur',
    reValidateMode: 'onblur',
    resolver: yupResolver(validationSchema()),
  });

  useEffect(() => {
    const countries = csc.getAllCountries();
    setCountryStateCity({ countries });
  }, []);

  useEffect(() => {
    register({ name: 'category' });
    return () => {
      unregister({ name: 'category' });
    };
  }, [register, unregister]);

  const resetLocalState = () => {
    setCategoryState({});
    setServicesState([]);
    setCountryStateCity({
      countries: countryStateCity.countries,
    });
  };

  const handleClose = () => {
    setRequestState(initialRequestState);
    resetLocalState();
    dispatch({ type: 'SET_ADD_BUSINESS_DIALOG_VISIBLE', payload: false });
  };

  const onValidateCategory = async () => {
    await trigger('category');
  };

  const handleCategoryChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const prevState = {
      ...categoryState,
    };
    if (value && name === 'category') {
      delete prevState.subCategories;
      delete prevState.subCategory;
      setCategoryState({
        ...prevState,
        category: value,
        subCategories: value.subCategories || [],
      });
      setValue('category', value, true);
    }

    if (value && name === 'subCategory') {
      setCategoryState({
        ...prevState,
        subCategory: value,
      });
    }
  };

  const handleServicesToggle = (value) => () => {
    const currentIndex = servicesState.indexOf(value);
    const newChecked = [...servicesState];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setServicesState(newChecked);
  };

  const handleLocationChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const prevState = {
      ...countryStateCity,
    };
    let newState = {};
    if (name === 'country') {
      delete prevState.statesOfCountry;
      delete prevState.state;
      delete prevState.citiesOfState;
      delete prevState.city;
      newState = {
        ...prevState,
        country: value,
        statesOfCountry: csc.getStatesOfCountry(value.id) || [],
      };
    } else if (name === 'state') {
      delete prevState.citiesOfState;
      delete prevState.city;
      newState = {
        ...prevState,
        state: value,
        citiesOfState: csc.getCitiesOfState(value.id) || [],
      };
    } else if (name === 'city') {
      newState = {
        ...prevState,
        city: value,
      };
    }
    setCountryStateCity(newState);
  };

  const handleRecaptchaChallenge = () => {
    recaptchaRef.current && recaptchaRef.current.execute();
  };

  const handlePreSubmit = (token) => {
    const data = getValues();
    // grab captcha token
    if (recaptchaRef.current) {
      data.recaptcha = token;
      recaptchaRef.current.reset();
    }
    // massage the data for submission
    const city = (countryStateCity.city && countryStateCity.city.name) || '';
    // BE expects province not state
    const province =
      (countryStateCity.state && countryStateCity.state.name) || '';
    const country =
      (countryStateCity.country && countryStateCity.country.name) || '';
    // BE expect categoryId of either category or subCategory
    const categoryId =
      (categoryState.subCategory && categoryState.subCategory._id) ||
      categoryState.category._id;
    delete data.category;
    // categoryId is the only requirement, removing FE keys
    const reqObj = {
      ...data,
      city,
      province,
      country,
      categoryId,
      services: servicesState || [],
    };
    // so backend doesn't validate an empty string
    if (!reqObj.url) {
      delete reqObj.url;
    }
    onSubmit(reqObj);
  };

  const onSubmit = async (data) => {
    setRequestState({ isLoading: true, errorMessage: '', isSuccessful: false });
    try {
      await api.accessFromHomeService.addBusiness(data);
      setRequestState({
        isLoading: false,
        errorMessage: '',
        isSuccessful: true,
      });
      track.analytics({
        category: 'directory_business_added',
      });
      resetLocalState();
      reset();
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;
      setRequestState({ isLoading: false, errorMessage, isSuccessful: false });
    }
    dialogContentRef.current.scrollTop = 0;
  };

  return (
    <Dialog
      open={isAddBusinessDialogVisible}
      onClose={handleClose}
      fullScreen={fullScreen}
      aria-label={'Services dialog'}
      maxWidth={'sm'}
    >
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={config.recaptcha.siteKey}
        onChange={handlePreSubmit}
      />
      <DialogTitle
        id="dialog-title"
        onClose={handleClose}
        aria-label={`Add a business dialog`}
      >
        {'Add a business'}
      </DialogTitle>
      {requestState.isLoading && (
        <LinearProgress color="primary" className={classes.loader} />
      )}
      <DialogContent
        classes={{ root: classes.dialogContent }}
        ref={dialogContentRef}
      >
        {requestState.errorMessage && (
          <Message
            id="form-dialog-description"
            variant="error"
            message={requestState.errorMessage}
            role="alert"
            className={classes.message}
          />
        )}
        {requestState.isSuccessful && (
          <Message
            id="form-dialog-description"
            variant="success"
            message={
              'Thank you for your submission to the Access From Home directory. Your submission will be reviewed and posted shortly.'
            }
            aria-live="assertive"
            className={classes.message}
          />
        )}
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleRecaptchaChallenge)}
        >
          <TextField
            className={classes.businessName}
            name="name"
            label="Business Name *"
            placeholder="Business Name"
            margin="dense"
            type="text"
            error={!!errors.name}
            helperText={(errors.name && errors.name.message) || ''}
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register}
          />
          {/* <Autocomplete
            id="country-select"
            options={countryStateCity.countries}
            autoHighlight
            getOptionLabel={(option) => option.name}
            onChange={handleLocationChange}
            // renderOption={(option) => (
            //   <React.Fragment>
            //     {option.name} +{option.phonecode}
            //   </React.Fragment>
            // )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          /> */}
          <div className={classes.formControlWrapper}>
            <FormControl
              className={clsx(classes.formControl, classes.lineItem)}
            >
              <InputLabel id={'country-label'} shrink>
                Country
              </InputLabel>
              <Select
                labelId={'country-label'}
                name="country"
                label="Country"
                value={countryStateCity.country || 'none'}
                displayEmpty
                onChange={handleLocationChange}
              >
                <MenuItem value="none">None</MenuItem>
                {countryStateCity.countries &&
                  countryStateCity.countries.map((country) => {
                    return (
                      <MenuItem key={country.id} value={country}>
                        {country.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl
              className={clsx(classes.formControl, classes.lineItem)}
              disabled={
                !(
                  countryStateCity.statesOfCountry &&
                  countryStateCity.statesOfCountry.length
                )
              }
            >
              <InputLabel id={'state-label'} shrink>
                Province/State
              </InputLabel>
              <Select
                labelId={'state-label'}
                name="state"
                label="Province/State"
                value={countryStateCity.state || 'none'}
                onChange={handleLocationChange}
                displayEmpty
              >
                <MenuItem value="none">
                  {countryStateCity.statesOfCountry &&
                  !countryStateCity.statesOfCountry.length
                    ? 'NA'
                    : 'None'}
                </MenuItem>
                {countryStateCity.statesOfCountry &&
                  countryStateCity.statesOfCountry.map((state) => {
                    return (
                      <MenuItem key={state.id} value={state}>
                        {state.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl
              className={clsx(classes.formControl, classes.lineItem)}
              disabled={
                !(
                  countryStateCity.citiesOfState &&
                  countryStateCity.citiesOfState.length
                )
              }
            >
              <InputLabel id={'city-label'} shrink>
                City
              </InputLabel>
              <Select
                labelId={'city-label'}
                name="city"
                label="City"
                value={countryStateCity.city || 'none'}
                onChange={handleLocationChange}
                displayEmpty
              >
                <MenuItem value="none">
                  {countryStateCity.citiesOfState &&
                  !countryStateCity.citiesOfState.length
                    ? 'NA'
                    : 'None'}
                </MenuItem>
                {countryStateCity.citiesOfState &&
                  countryStateCity.citiesOfState.map((city) => {
                    return (
                      <MenuItem key={city.id} value={city}>
                        {city.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div className={classes.row}>
            <TextField
              className={classes.lineItem}
              name="address"
              label="Address"
              placeholder="123 Street N"
              margin="dense"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register}
            />
            <TextField
              className={classes.lineItem}
              name="address2"
              label="Address 2"
              placeholder="Unit 123"
              margin="dense"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register}
            />
            <TextField
              className={clsx(classes.lineItem, classes.postalCode)}
              name="postalCode"
              label="Postal/Zip Code"
              placeholder="K1K 1K1"
              margin="dense"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register}
            />
          </div>
          <div className={classes.row}>
            <div className={classes.phoneWrapper}>
              <TextField
                className={classes.phone}
                name="phone"
                label="Phone Number"
                placeholder="eg. 123-123-1234"
                margin="dense"
                type="text"
                // error={!!errorText}
                // helperText={errorText}
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={register}
              />
              <TextField
                className={classes.phoneExt}
                name="phoneExt"
                label="Ext."
                placeholder="4321"
                margin="dense"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={register}
              />
            </div>
            <TextField
              className={classes.lineItem}
              name="url"
              label="Website URL"
              placeholder="http://www.website.com"
              margin="dense"
              type="text"
              error={!!errors.url}
              helperText={(errors.url && errors.url.message) || ''}
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register}
            />
          </div>
          <div className={classes.formControlWrapper}>
            <FormControl
              className={clsx(classes.formControl, classes.lineItem)}
              disabled={!categoryOptions.length}
            >
              <InputLabel
                id={'category-label'}
                shrink
                error={!!errors.category}
              >
                Category *
              </InputLabel>
              <Select
                labelId={'category-label'}
                name="category"
                label="Category *"
                value={categoryState.category || 'none'}
                onChange={handleCategoryChange}
                onClose={onValidateCategory}
                displayEmpty
                error={!!errors.category}
              >
                <MenuItem value="none">None</MenuItem>
                {categoryOptions.map((category) => (
                  <MenuItem key={category._id} value={category}>
                    {category.displayName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {errors.category && errors.category.message}
              </FormHelperText>
            </FormControl>
            <FormControl
              className={clsx(classes.formControl, classes.lineItem)}
              disabled={
                !(
                  categoryState.subCategories &&
                  categoryState.subCategories.length
                )
              }
            >
              <InputLabel id={'subCategory-label'} shrink>
                Sub-Category
              </InputLabel>
              <Select
                labelId={'subCategory-label'}
                name="subCategory"
                label="Sub-Category"
                value={categoryState.subCategory || 'none'}
                onChange={handleCategoryChange}
                displayEmpty
              >
                <MenuItem value="none">
                  {categoryState.subCategories &&
                  !categoryState.subCategories.length
                    ? 'NA'
                    : 'None'}
                </MenuItem>
                {categoryState.subCategories &&
                  categoryState.subCategories.map((subCategory) => (
                    <MenuItem key={subCategory._id} value={subCategory}>
                      {subCategory.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          {!!servicesOptions.length && (
            <Box className={classes.servicesWrapper}>
              <Typography className={classes.servicesLabel}>
                Services
              </Typography>
              <List className={classes.list}>
                {servicesOptions.map((service, i) => {
                  const labelId = `service-list-label-${service.key}`;
                  return (
                    <ListItem
                      role={undefined}
                      dense
                      button
                      onClick={handleServicesToggle(service.key)}
                      key={i}
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={servicesState.indexOf(service.key) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={service.displayName}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          )}
          <Button
            className={classes.button}
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
            disabled={!isEmpty(errors) || requestState.isLoading}
            onClick={() => (dialogContentRef.current.scrollTop = 0)}
          >
            Submit
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddBusinessDialog;

import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import xor from 'lodash.xor';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RatingButton } from '../components';
import CustomAccordion from '../components/CustomAccordion';
import { useDialogContext } from '../context';
import { filterActions } from '../store/actions/';
import { track } from '../utils';

const useStyles = makeStyles((theme) => ({
  buttons: {
    '& button': {
      flex: 1,
    },
    '& button:first-child': {
      marginRight: theme.spacing(2),
    },
  },
}));

const FilterDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { dialogDispatch } = useDialogContext();
  const filterState = useSelector((state) => state.filters, shallowEqual);
  const tags = useSelector((state) => state.tags.categories);
  const ratings = useSelector((state) => state.ratings.values);
  const { t } = useTranslation();

  const _updateFilters = (key, value, array) => {
    const accum = xor(array, [value]);
    // analytics
    const data = {};
    data[accum.includes(value) ? 'added' : 'removed'] = value;
    track.analytics({
      category: `filter_${key}`,
      data,
    });
    // update global state
    dispatch(
      filterActions.update({
        [key + 's']: accum,
      })
    );
  };

  const updateSelectedRatings = (value) => {
    _updateFilters('rating', value, filterState.ratings);
  };

  const updateSelectedTags = (value) => {
    _updateFilters('tag', value, filterState.tags);
  };

  // const updateSelectedFlags = (value) => {
  //   _updateFilters('flag', value, filterState.flags);
  // };

  const listRatings = () => {
    return ratings.map((rating) => {
      const selected = filterState.ratings.includes(rating.key);
      return (
        <RatingButton
          variant="large"
          title={t(`ratings.${rating.key}`)}
          selected={selected}
          rating={rating.key}
          onClick={() => updateSelectedRatings(rating.key)}
          key={rating.key}
        />
      );
    });
  };

  const listTags = () => {
    if (!tags || tags.length === 0) {
      return null;
    }
    return tags.map((categoryObj, i) => {
      const { category, tags: categoryTags } = categoryObj;

      return (
        <CustomAccordion
          key={i}
          category={category}
          tags={categoryTags}
          selectedTags={filterState.tags}
          updateSelectedTags={updateSelectedTags}
        />
      );
    });
  };

  // const listFlags = () => {
  //   return flags.map((flag) => {
  //     const isSelected = filterState.flags.includes(flag.key);
  //     return (
  //       <FlagButton
  //         displayName={t(`flags.${flag.key}`)}
  //         key={flag.key}
  //         isSelected={isSelected}
  //         onClick={() => updateSelectedFlags(flag.key)}
  //         variant="outlined"
  //       />
  //     );
  //   });
  // };

  const onApply = () => {
    dispatch(filterActions.apply());
    dialogDispatch({ type: 'CLOSE' });
  };

  const onClear = () => {
    dispatch(filterActions.clear());
    dialogDispatch({ type: 'CLOSE' });
  };

  return (
    <>
      <Box
        mt={1}
        mb={1.5}
        display="flex"
        direction="row"
        alignItems="flex-start"
        justifyContent="space-around"
      >
        {listRatings()}
      </Box>
      {/* {!!flags.length && (
        <Box
          mb={2}
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          {listFlags()}
        </Box>
      )} */}
      <Box
        mb={0.5}
        display="flex"
        direction="row"
        justifyContent="center"
        flexWrap="wrap"
      >
        {listTags()}
      </Box>
      <Box
        mt={2.5}
        display="flex"
        direction="row"
        justifyContent="stretch"
        className={classes.buttons}
      >
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={onClear}
        >
          {t('messages.clearAll')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="large"
          type="submit"
          onClick={onApply}
        >
          {t('messages.apply')}
        </Button>
      </Box>
    </>
  );
};

export default FilterDialog;

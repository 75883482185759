import { history } from '../';
import { serverErrorsl10n } from '../../l10n/common';
import { api } from '../../services';
import getQueryStringLanguage from '../../utils/helpers';
import { editActions, mapActions, reviewActions } from '.';

export const reset = () => {
  return {
    type: 'EDIT_RESET',
  };
};

export const update = (payload) => {
  return {
    type: 'EDIT_UPDATE',
    payload: {
      errorMessage: '',
      ...payload,
    },
  };
};

export const editReview = (review) => {
  return async (dispatch, getState) => {
    const { selectedPlaceDetails } = getState().map;
    dispatch({
      type: 'EDIT_REVIEW_IS_LOADING',
      payload: {
        isLoading: true,
      },
    });

    try {
      const response = await api.userService.editReviewPost({ ...review });

      const lngQueryParam = getQueryStringLanguage();
      history.push(
        `/details/${selectedPlaceDetails._id}/local/${lngQueryParam}`
      );
      dispatch(editActions.reset());
      dispatch({
        type: 'EDIT_REVIEW_SUCCESS',
        payload: {
          isLoading: false,
          byUser: response,
        },
      });

      // update place details if selectedPlaceDetails state is populated
      // TODO: update map pin as well when it doesn't take so long
      if (selectedPlaceDetails._id) {
        dispatch(mapActions.placeDetails({ _id: selectedPlaceDetails._id }));
      } else {
        dispatch(reviewActions.reviewsByUser());
      }
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;
      dispatch({
        type: 'EDIT_REVIEW_FAILURE',
        payload: {
          isLoading: false,
          errorMessage,
          flaggedImages: error.data?.flaggedImages || [],
        },
      });
    }
  };
};

export const editReviewError = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: 'EDIT_REVIEW_FAILURE',
      payload: {
        ...payload,
      },
    });
  };
};

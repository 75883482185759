import axios from 'axios';
import merge from 'lodash.merge';
import { v4 as uuid } from 'uuid';
import { storeInst as store } from '../../';
import { Option, performance, track } from '../../utils';

const handleSuccessRequest = async (req) => {
  const { auth } = store.getState();
  const { token, email, thirdPartyAuth } = auth;
  const transactionId = Math.random().toString(36).substr(2, 9);
  const request_id = uuid();
  const authorization = token ? `Bearer ${token}` : '';
  const httpMetric = performance.trace('httpMetric');

  Option.risky(() =>
    httpMetric.putAttribute('url', `${req.method.toUpperCase()}_${req.url}`)
  ).getOrElse(() => {});

  merge(req.headers, {
    'X-Request-Id': request_id,
    'X-Transaction-ID': transactionId,
  });

  if (authorization) {
    merge(req.headers, {
      'cache-control': 'no-cache',
      authorization,
    });
  }

  if (email) {
    httpMetric.putAttribute('userEmail', email);
    httpMetric.putAttribute(
      'thirdPartyAuth',
      Boolean(thirdPartyAuth).toString()
    );
  }

  req.metadata = { httpMetric };

  await httpMetric.start();

  return req;
};

const handleSuccessResponse = async (res) => {
  const { httpMetric } = res.config.metadata;
  const contentType = res.headers['content-type'];
  const contentLength = res.headers['content-length']
    ? Number(res.headers['content-length'])
    : null;

  httpMetric.putAttribute('httpResponseCode', res.status.toString());
  if (contentLength) {
    httpMetric.putAttribute(
      'httpResponsePayloadSize',
      contentLength.toString()
    );
  }
  if (contentType) {
    httpMetric.putAttribute('httpResponseContentType', contentType.toString());
  }

  await httpMetric.stop();

  return res;
};

const handleError = async (error) => {
  const tags = {
    transaction_id: Option.risky(
      () => error.config.headers['X-Transaction-ID']
    ).getOrElse(() => undefined),
    request_id: Option.risky(
      () => error.config.headers['X-Request-Id']
    ).getOrElse(() => undefined),
  };

  track.error(error, tags);

  const { httpMetric } = error.config.metadata;
  const contentType = error.response.headers['content-type'];

  httpMetric.putAttribute('httpResponseCode', error.response.status.toString());
  if (contentType) {
    httpMetric.putAttribute('httpResponseContentType', contentType);
  }

  await httpMetric.stop();

  // The user doesn't have internet
  if (error.message === 'Network Error') {
    return Promise.reject('NETWORK_ERROR');
  }

  return Option.risky(() => {
    return Promise.reject(error.response.data);
  }).getOrElse(() => {
    return Promise.reject(error);
  });
};

export const CancelToken = axios.CancelToken;
export class HttpService {
  constructor(baseURL) {
    this.config = {
      baseURL: baseURL,
      responseType: 'json',
      params: {
        forceRefresh: true,
      },
      headers: {
        'content-type': 'application/json',
      },
    };

    this.instance = axios.create(this.config);

    this.instance.interceptors.request.use(handleSuccessRequest, handleError);

    this.instance.interceptors.response.use(handleSuccessResponse, handleError);
  }

  get(url, config) {
    const _config = merge({}, this.config, config);

    return this.instance
      .get(url, _config)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  }

  post(url, data, config) {
    const _config = merge({}, this.config, config);

    return this.instance
      .post(url, data, _config)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  }

  put(url, data, config = {}) {
    const _config = merge({}, this.config, config);

    return this.instance
      .put(url, data, _config)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  }

  patch(url, data, config = {}) {
    const _config = merge({}, this.config, config);

    return this.instance
      .patch(url, data, _config)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  }

  delete(url, config = {}) {
    const _config = merge({}, this.config, config);

    return this.instance
      .delete(url, _config)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  }
}

import {
  Avatar,
  Button,
  Fab,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Edit as EditIcon,
  ExitToAppRounded as LogoutIcon,
  RateReview as ReviewIcon,
  Update as UpdateIcon,
} from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDialogContext } from '../context';
import { authActions } from '../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    alignItems: 'center',
    top: theme.spacing(2) + 73,
    right: theme.spacing(2),
    zIndex: 11,
  },
  loginButton: {
    color: theme.palette.primary.main,
  },
  filterButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    marginTop: theme.spacing(2),
  },
  menuButton: {
    backgroundColor: 'white',
  },
  listItemIcon: {
    minWidth: 0,
    marginRight: theme.spacing(2),
  },
  profileIcon: {
    width: 57,
    height: 57,
  },
}));

const StyledMenu = withStyles((theme) => ({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: theme.spacing(1),
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -15,
    top: 0,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.lightest,
    color: theme.palette.black,
    padding: '0 4px',
  },
}))(Badge);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))(MenuItem);

const AccountMenu = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const avatar = useSelector((state) => state.auth.avatar);
  const filterState = useSelector((state) => state.filters, shallowEqual);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterCount, setFilterCount] = React.useState(0);
  const { dialogDispatch } = useDialogContext();
  const { t } = useTranslation();
  const { isProvider } = useSelector((state) => state.auth);
  React.useEffect(() => {
    const { ratings, labels, tags, flags } = filterState;
    const total = ratings.length + labels.length + tags.length + flags.length;

    setFilterCount(total);
  }, [filterState]);

  const onLogin = (event) => {
    if (isAuthenticated) {
      setAnchorEl(event.currentTarget);
    } else {
      dialogDispatch({ type: 'OPEN_AS', payload: 'login' });
    }
  };

  const onMyReviews = () => {
    closeMenu();
    dialogDispatch({ type: 'OPEN_AS', payload: 'myReviews' });
  };

  const onChangePassword = () => {
    closeMenu();
    dialogDispatch({ type: 'OPEN_AS', payload: 'changePassword' });
  };

  const onEditProfile = () => {
    closeMenu();
    dialogDispatch({ type: 'OPEN_AS', payload: 'editProfile' });
  };

  const onLogout = () => {
    closeMenu();
    dispatch(authActions.logout());
  };

  const onFilterClick = (event) => {
    closeMenu();
    dialogDispatch({ type: 'OPEN_AS', payload: 'filter' });
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };
  const onJoinEvent = () => {
    closeMenu();
    dialogDispatch({ type: 'OPEN_AS', payload: 'joinEvent' });
  };

  return (
    <>
      <div className={classes.root}>
        {isAuthenticated ? (
          <>
            <Fab
              className={classes.menuButton}
              aria-controls="customized-menu"
              aria-haspopup="true"
              onClick={onLogin}
              aria-label="account menu button"
            >
              <Avatar
                src={avatar || `/images/avatar.svg`}
                className={classes.profileIcon}
                alt="avatar"
              />
            </Fab>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeMenu}
            >
              <StyledMenuItem onClick={onEditProfile}>
                <ListItemIcon className={classes.listItemIcon}>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={t('messages.editProfile')}
                  role="button"
                  aria-label="Edit Profile"
                />
              </StyledMenuItem>
              <StyledMenuItem onClick={onMyReviews}>
                <ListItemIcon className={classes.listItemIcon}>
                  <ReviewIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    isProvider
                      ? t('messages.myReplies')
                      : t('messages.myReviews')
                  }
                  role="button"
                  aria-label={
                    isProvider
                      ? t('messages.myReplies')
                      : t('messages.myReviews')
                  }
                />
              </StyledMenuItem>
              <StyledMenuItem onClick={onJoinEvent}>
                <ListItemIcon className={classes.listItemIcon}>
                  <img
                    src="/images/pins/unrated.svg"
                    alt="Join a MapMission"
                    style={{ width: '20px', height: '20px' }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t('messages.joinEvent')}
                  role="button"
                  aria-label="Join a MapMission"
                />
              </StyledMenuItem>
              <StyledMenuItem onClick={onChangePassword}>
                <ListItemIcon className={classes.listItemIcon}>
                  <UpdateIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={t('messages.changePassword')}
                  role="button"
                  aria-label="Change Password"
                />
              </StyledMenuItem>
              <StyledMenuItem onClick={onLogout}>
                <ListItemIcon className={classes.listItemIcon}>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={t('messages.logOut')}
                  role="button"
                  aria-label="Logout"
                />
              </StyledMenuItem>
            </StyledMenu>
          </>
        ) : (
          <Button
            className={classes.loginButton}
            aria-haspopup="false"
            variant="contained"
            onClick={onLogin}
            size="medium"
            aria-label="Login and Create Account"
          >
            {t('messages.login')}
          </Button>
        )}

        <Button
          className={classes.filterButton}
          aria-haspopup="false"
          variant="contained"
          onClick={onFilterClick}
          size="medium"
          aria-label="Filter Pins"
        >
          <StyledBadge color="secondary" badgeContent={filterCount}>
            {t('messages.filter')}
          </StyledBadge>
        </Button>
      </div>
    </>
  );
};

export default AccountMenu;

import {
  Badge,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef } from 'react';
import { useDirectoryContext } from '../../context';
import { sortl10n } from '../../l10n/common';
import { track } from '../../utils';

const useStyles = makeStyles((theme) => ({
  controlWrapper: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-end',
    },
  },
  formControlWrapper: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('sm')]: {
      '&:first-of-type': {
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('md')]: {
      '&:first-of-type': {
        marginRight: theme.spacing(2),
      },
    },
  },
  formControl: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      '&:first-of-type': {
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('sm')]: {
      '&:first-child': {
        marginRight: theme.spacing(2),
      },
    },
  },
  badgeRoot: {
    [theme.breakpoints.down('sm')]: {
      width: `calc(50% - 8px)`,
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  badge: {
    top: 4,
    right: 4,
  },
  servicesButton: {
    flex: 1,
  },
}));

const sortByOptions = [];
for (const prop in sortl10n.en) {
  sortByOptions.push(prop);
}

const Controls = React.memo(() => {
  const classes = useStyles();
  const resultsPerPageOptions = useRef([12, 24, 48, 96, 'All']);
  const { state, dispatch } = useDirectoryContext();
  const {
    categoryOptions,
    category,
    subCategoryOptions,
    subCategory,
    selectedSortBy,
    selectedResultsPerPage,
    servicesOptions,
    services,
    isLoading,
  } = state;

  useEffect(() => {
    if (category && category !== 'none') {
      const subCategories = categoryOptions.filter((categoryOption) => {
        return categoryOption.key === category;
      })[0].subCategories;
      dispatch({ type: 'SET_SUB_CATEGORY_OPTIONS', payload: subCategories });
    }
  }, [dispatch, category, categoryOptions]);

  const handleResultsPerPageChange = (event) => {
    dispatch({ type: 'SET_RESULTS_PER_PAGE', payload: event.target.value });
  };

  const handleCategoryChange = (event, value) => {
    dispatch({
      type: 'SET_CATEGORY',
      payload: { name: event.target.value, id: value?.props?.id || '' },
    });

    track.analytics({
      category: 'directory_filter',
      data: {
        category: event.target.value || '',
      },
    });
  };

  const handleSubCategoryChange = (event, value) => {
    dispatch({
      type: 'SET_SUB_CATEGORY',
      payload: { name: event.target.value, id: value?.props?.id },
    });

    track.analytics({
      category: 'directory_filter',
      data: {
        subCategory: event.target.value,
      },
    });
  };

  const toggleServicesDialog = (event) => {
    dispatch({ type: 'SET_SERVICES_DIALOG_VISIBLE', payload: true });
  };

  const handleSelectedSortByChange = (event) => {
    dispatch({ type: 'SET_SELECTED_SORT_BY', payload: event.target.value });
  };

  return (
    <Box className={classes.controlWrapper}>
      <Badge
        badgeContent={services.length}
        color="secondary"
        classes={{
          root: classes.badgeRoot,
          badge: classes.badge,
        }}
      >
        <Button
          className={classes.servicesButton}
          variant="outlined"
          color="default"
          size="large"
          onClick={toggleServicesDialog}
          disabled={!servicesOptions.length}
        >
          Services
        </Button>
      </Badge>
      <Box className={classes.formControlWrapper}>
        <FormControl
          className={classes.formControl}
          disabled={!categoryOptions.length}
        >
          <InputLabel id="category-select-label">Category</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={category}
            onChange={handleCategoryChange}
            label="Category"
          >
            <MenuItem value="none">None</MenuItem>
            {categoryOptions.map(({ _id, key, displayName }) => (
              <MenuItem key={_id} id={_id} value={key}>
                {displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          className={classes.formControl}
          disabled={!subCategoryOptions.length || category === 'none'}
        >
          <InputLabel id="sub-category-select-label">Sub-Category</InputLabel>
          <Select
            labelId="sub-category-select-label"
            id="sub-category-select"
            value={subCategory}
            onChange={handleSubCategoryChange}
            label="Sub Category"
          >
            <MenuItem value="none">None</MenuItem>
            {subCategoryOptions.map(({ _id, key, displayName }) => (
              <MenuItem key={_id} id={_id} value={key}>
                {displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box className={classes.formControlWrapper}>
        <FormControl className={classes.formControl} disabled={isLoading}>
          <InputLabel id="sort-by-select-label">Sort by</InputLabel>
          <Select
            labelId="sort-by-select-label"
            id="sort-by-select"
            value={selectedSortBy}
            onChange={handleSelectedSortByChange}
            label="Sort by"
          >
            {sortByOptions.map((sortByOption, i) => (
              <MenuItem key={i} value={sortByOption}>
                {sortl10n.en[sortByOption] || sortByOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} disabled={isLoading}>
          <InputLabel id="results-per-page-select-label">Show</InputLabel>
          <Select
            labelId="results-per-page-select-label"
            id="results-per-page-select"
            value={selectedResultsPerPage}
            onChange={handleResultsPerPageChange}
            label="Show"
          >
            {resultsPerPageOptions.current.map((resultsPerPageOption, i) => (
              <MenuItem key={i} value={resultsPerPageOption}>
                {resultsPerPageOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
});

export default Controls;

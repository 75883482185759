/* eslint-disable no-undef */
import { Button, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useGoogleMap } from '@react-google-maps/api';
import React, { memo, useEffect, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { mapActions } from '../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  startIcon: {
    marginRight: theme.spacing(0.5),
  },
}));

const SearchThisAreaButton = memo(({ position }) => {
  const classes = useStyles();
  const map = useGoogleMap();
  const dispatch = useDispatch();
  const [container] = useState(document.createElement('div'));
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isVisible && map) {
      const controls = map.controls[google.maps.ControlPosition[position]];
      controls.push(container);

      return () => {
        const index = controls
          .getArray()
          .findIndex((node) => node === container);

        controls.removeAt(index);
      };
    }
  }, [container, isVisible, map, position]);

  useEffect(
    function effect() {
      map.addListener('tilesloaded', () => {
        // this is reached on 1st load
        map.addListener('tilesloaded', () => {
          // this is reached when the map is moved after the 1st load
          setIsVisible(true);
        });
      });
    },
    [map]
  );

  const handleClick = useCallback(() => {
    // search map boundary places
    dispatch(mapActions.searchMapBoundaries(map));

    setIsVisible(false);
  }, [dispatch, setIsVisible, map]);

  const button = (
    <Button
      size="medium"
      aria-hidden="true"
      tabIndex="-1"
      classes={{
        root: classes.root,
        startIcon: classes.startIcon,
      }}
      variant="contained"
      onClick={handleClick}
      startIcon={<SearchIcon color="primary" />}
    >
      {t('messages.searchThisArea')}
    </Button>
  );

  return createPortal(button, container);
});

export default SearchThisAreaButton;

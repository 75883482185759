import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useGalleryContext } from '../context';

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(255,255,255,0.5)',
      webkitBoxShadow: 'inset 0 0 6px rgba(255,255,255,0.5)',
      backgroundColor: theme.palette.white,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[500],
      borderRadius: '10px',
    },
  },
  carousel: {
    '& button': {
      display: 'inline-block',
      border: 'none',
      backgroundColor: 'transparent',
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      padding: '2px',
    },
    '& img': {
      display: 'block',
      width: 100,
      height: 100,
    },
  },
}));

const Carousel = ({ images, isGallery }) => {
  const classes = useStyles();
  const { galleryDispatch, galleryState } = useGalleryContext();
  const { images: galleryImages, imageFocus } = galleryState;
  const inputRef = React.useRef([]);

  const onSelect = (i) => {
    if (galleryImages.length <= 0) {
      galleryDispatch({
        type: 'OPEN_WITH',
        payload: { images, imageFocus: i },
      });
    } else {
      galleryDispatch({
        type: 'SET_FOCUS',
        payload: i,
      });
    }
  };

  useEffect(() => {
    if (Number.isInteger(imageFocus) & isGallery) {
      setTimeout(function () {
        inputRef?.current[imageFocus]?.scrollIntoView({
          behavior: 'smooth',
          incline: 'center',
        });
      }, 100);
    }
  }, [imageFocus, isGallery]);

  return (
    Array.isArray(images) &&
    !!images.length && (
      <div className={classes.container}>
        <div className={classes.carousel}>
          {images?.map((image, i) => {
            return (
              <button
                onClick={() => onSelect(i)}
                key={i}
                style={
                  isGallery && imageFocus === i
                    ? { border: '2px white solid', padding: 0 }
                    : {}
                }
                ref={(el) => (inputRef.current[i] = el)}
              >
                <img alt={image.altText} src={image.thumbnail} />
              </button>
            );
          })}
        </div>
      </div>
    )
  );
};

export default Carousel;

import {
  Grid,
  IconButton,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ShareIcon from '@material-ui/icons/ShareRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spacing } from '../styles';
import { Flag } from './';

const useStyles = makeStyles((theme) => ({
  searchItem: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  subtitle1: {
    ...theme.typography.subtitle1,
    color: theme.palette.primary.main,
  },
  roundIcons: {
    width: 38,
  },
  halfAccessbileIcons: {
    width: 38,
  },
  lineItemRow: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 3, 0, 3),
  },
  listItemIcon: {
    minWidth: 0,
    marginRight: theme.spacing(2),
  },
  listItemAction: {
    padding: theme.spacing(1),
  },
  infoText: {
    color: theme.palette.text.pinText,
  },
  verificationIcon: {
    marginLeft: Spacing.tinier,
    width: Spacing.large,
    height: Spacing.large,
    verticalAlign: 'middle',
    marginBottom: '1%',
    objectFit: 'contain',
  },
}));

const ListItem = ({
  withShareIcon,
  openShareDialog,
  place,
  onClick,
  disableGutters,
}) => {
  const classes = useStyles();
  const { address, name, rating, labels = [], flags = [], provider } = place;
  const { t } = useTranslation();
  const key = rating || t('messages.plus');
  const displayName = rating && t(`ratings.${rating}`);
  const ariaRating = key !== 'Plus' ? `Rated ${displayName}` : 'Not yet rated';
  const ariaCta = onClick
    ? key !== 'Plus'
      ? ', Select for more details'
      : ', Select to add a pin'
    : '';

  const isEstablishment = place?.types?.includes('establishment') || false;

  const verificationSign = (provider) => {
    if (provider) {
      const verificationAltTest = 'messages.verifiedPlace';
      const verificationIcon = `/images/partners/verifiedIcon.png`;
      const iconStyle = classes.verificationIcon;
      return (
        <>
          <img
            alt={verificationAltTest}
            src={verificationIcon}
            className={iconStyle}
          />
        </>
      );
    }

    return null;
  };

  return (
    <MuiListItem
      className={classes.searchItem}
      divider
      onClick={(event) => {
        return onClick && onClick(place);
      }}
      button={!!onClick}
      aria-label={`${name}, ${ariaRating}, ${address}${ariaCta}`}
      role="listitem"
      disableGutters={disableGutters}
    >
      <ListItemIcon className={classes.listItemIcon} role="presentation">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            {!isEstablishment && place.placeType === 'google' ? (
              <img
                alt=""
                src={`/images/roundIcons/city_pin.svg`}
                className={classes.halfAccessbileIcons}
              />
            ) : place.rating === 'unrated' &&
              place.wheelchairAccessibleEntrance ? (
              <img
                alt="half-accessible"
                src={`/images/roundIcons/incomplete_accessible_icon.svg`}
                className={classes.halfAccessbileIcons}
              />
            ) : (
              <img
                alt=""
                src={`/images/roundIcons/${
                  place.placeType === 'trail' ? `trail_${key}` : key
                }.svg`}
                className={classes.roundIcons}
              />
            )}
          </Grid>
        </Grid>
      </ListItemIcon>
      <div className={classes.container}>
        <ListItemText
          primary={
            <div className={classes.lineItemRowElement} style={{ flex: 1 }}>
              <Typography className={classes.subtitle1} variant="h3">
                {name}
                {verificationSign(provider)}
              </Typography>
            </div>
          }
          secondary={address}
          classes={{ primary: classes.subtitle1 }}
          role="presentation"
        />
        {flags.map((flag) => {
          return (
            <Flag verified key={key} {...flag}>
              {t(`flags.${flag}`) || flag}
            </Flag>
          );
        })}
        {labels.map((label) => {
          return (
            <Flag key={key} {...label}>
              {label.displayName}
            </Flag>
          );
        })}
      </div>
      {withShareIcon && (
        <ListItemSecondaryAction className={classes.listItemAction}>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => {
              openShareDialog();
            }}
          >
            <ShareIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </MuiListItem>
  );
};

export default ListItem;

import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5, 0),
    borderRadius: 20,
  },
}));

const FlagButton = ({
  isVerified,
  isSelected,
  displayName,
  variant,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Button
      variant={variant}
      color="secondary"
      classes={{
        root: classes.root,
      }}
      style={{
        ...(isSelected && {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.white,
        }),
        ...(variant === 'outlined' && {
          borderColor: theme.palette.secondary.main,
        }),
      }}
      size="medium"
      {...props}
    >
      {displayName}
    </Button>
  );
};

export default FlagButton;

export const initState = {
  name: '',
  email: '',
  password: '',
  token: '',
  username: '',
  usernameUpdatedAt: '',
  avatar: '',
  isLoading: false,
  isAuthenticated: false,
  successMessage: '',
  errorMessage: '',
  thirdPartyAuth: '0',
  _id: 0,
  isProvider: false,
  eventId: null,
  event: null,
};

const authReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'UPDATE_AUTH_MODAL':
    case 'LOGIN_IS_LOADING':
    case 'LOGIN_SUCCESS':
    case 'LOGIN_FAILURE':
    case 'GET_USER_IS_LOADING':
    case 'GET_USER_SUCCESS':
    case 'GET_USER_FAILURE':
    case 'FB_AUTH_IS_LOADING':
    case 'FB_AUTH_SUCCESS':
    case 'FB_AUTH_CANCELLED':
    case 'FB_AUTH_FAILURE':
    case 'APPL_AUTH_IS_LOADING':
    case 'APPL_AUTH_SUCCESS':
    case 'APPL_AUTH_CANCELLED':
    case 'APPL_AUTH_FAILURE':
    case 'LOGOUT_IS_LOADING':
    case 'LOGOUT_SUCCESS':
    case 'LOGOUT_FAILURE':
    case 'CREATE_ACCOUNT_IS_LOADING':
    case 'CREATE_ACCOUNT_SUCCESS':
    case 'CREATE_ACCOUNT_FAILURE':
    case 'FB_CREATE_ACCOUNT_IS_LOADING':
    case 'FB_CREATE_ACCOUNT_SUCCESS':
    case 'FB_CREATE_ACCOUNT_CANCELLED':
    case 'FB_CREATE_ACCOUNT_FAILURE':
    case 'APPL_CREATE_ACCOUNT_IS_LOADING':
    case 'APPL_CREATE_ACCOUNT_SUCCESS':
    case 'APPL_CREATE_ACCOUNT_CANCELLED':
    case 'APPL_CREATE_ACCOUNT_FAILURE':
    case 'USER_DETAILS_IS_LOADING':
    case 'USER_DETAILS_SUCCESS':
    case 'USER_DETAILS_FAILURE':
    case 'CHANGE_PASSWORD_IS_LOADING':
    case 'CHANGE_PASSWORD_SUCCESS':
    case 'CHANGE_PASSWORD_FAILURE':
    case 'FORGOT_PASSWORD_IS_LOADING':
    case 'FORGOT_PASSWORD_SUCCESS':
    case 'FORGOT_PASSWORD_FAILURE':
    case 'CHANGE_PASSWORD_WITH_TOKEN_IS_LOADING':
    case 'CHANGE_PASSWORD_WITH_TOKEN_SUCCESS':
    case 'CHANGE_PASSWORD_WITH_TOKEN_FAILURE':
    case 'EDIT_PROFILE_IS_LOADING':
    case 'EDIT_PROFILE_SUCCESS':
    case 'EDIT_PROFILE_FAILURE':
    case 'UPLOAD_USER_AVATAR_IS_LOADING':
    case 'UPLOAD_USER_AVATAR_SUCCESS':
    case 'UPLOAD_USER_AVATAR_ERROR':
    case 'SUBSCRIBE_TO_EVENT_SUCCESS':
    case 'SUBSCRIBE_TO_EVENT_FAILURE':
      return {
        ...state,
        ...payload,
      };
    case 'CLEAR_MESSAGING':
      return {
        ...state,
        ...{
          errorMessage: '',
          successMessage: '',
        },
      };
    default:
      return state;
  }
};

export default authReducer;

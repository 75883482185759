import {
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDialogContext } from '../context';
import {
  AddPhotosDialog,
  ChangePasswordDialog,
  CreateAccountDialog,
  EditProfileDialog,
  FilterDialog,
  ForgotPasswordDialog,
  JoinEventDialog,
  LoginDialog,
  MyReviewsDialog,
  SuccessDialog,
} from '../dialogs';
import { DialogEnum } from '../types/dialog.type';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  titleCentre: {
    margin: 0,
    textAlign: 'center',
    padding: theme.spacing(4, 2, 0, 3),
  },
  titleRoot: {
    margin: 0,
    padding: theme.spacing(2, 2, 0, 3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
  },
}));

const Dialog = ({ title, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const dialogContentRef = useRef();
  const { dialogState, dialogDispatch } = useDialogContext();
  const { isVisible, current } = dialogState;
  const { t } = useTranslation();
  const { isProvider } = useSelector((state) => state.auth);
  const handleClose = () => {
    dialogDispatch({ type: 'CLOSE' });
  };
  const scrollContentToTop = () => {
    dialogContentRef.current.scrollTop = 0;
  };

  return (
    <MuiDialog
      open={isVisible}
      onClose={handleClose}
      fullScreen={fullScreen}
      aria-labelledby="form-dialog-title"
    >
      <MuiDialogTitle
        disableTypography
        className={
          current === DialogEnum.joinEvent
            ? classes.titleCentre
            : classes.titleRoot
        }
        id="form-dialog-title"
      >
        <Typography variant="h4">
          {current === DialogEnum.myReviews && isProvider
            ? t(`dialogTitles.myReplies`)
            : t(`dialogTitles.${current}`)}
        </Typography>
        <IconButton
          aria-label="close dialog"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent classes={{ root: classes.root }} ref={dialogContentRef}>
        {{
          login: <LoginDialog scrollContentToTop={scrollContentToTop} />,
          createAccount: (
            <CreateAccountDialog scrollContentToTop={scrollContentToTop} />
          ),
          changePassword: (
            <ChangePasswordDialog scrollContentToTop={scrollContentToTop} />
          ),
          editProfile: (
            <EditProfileDialog scrollContentToTop={scrollContentToTop} />
          ),
          forgotPassword: (
            <ForgotPasswordDialog scrollContentToTop={scrollContentToTop} />
          ),
          filter: <FilterDialog />,
          myReviews: <MyReviewsDialog />,
          addPhotos: <AddPhotosDialog />,
          joinEvent: <JoinEventDialog />,
          successDialog: <SuccessDialog />,
        }[current] || null}
      </DialogContent>
    </MuiDialog>
  );
};

export default Dialog;

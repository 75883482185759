import { HttpService } from './http-base.service';

export default class LabelService extends HttpService {
  constructor(baseURL) {
    if (!LabelService.instance) {
      super(baseURL);
      LabelService.instance = this;
    }

    return LabelService.instance;
  }

  list() {
    return this.get('/api/labels')
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }
}

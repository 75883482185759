const initState = {
  _id: '',
  name: '',
  address: '',
  distanceIn: {},
  phone: '',
  latitude: 0,
  longitude: 0,
  tags: [],
  rating: undefined,
  description: '',
  isLoading: false,
  isReviewValid: false,
  errorMessage: '',
  url: '',
  urlDisplay: '',
  images: [],
  flaggedImages: [],
};

const addReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'ADD_IS_LOADING':
    case 'ADD_FAILURE':
    case 'ADD_UPDATE':
      return {
        ...state,
        ...payload,
      };
    case 'ADD_SUCCESS':
    case 'ADD_RESET':
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default addReducer;

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  directoryPaths,
  addPaths,
  detailsPaths,
  passwordPaths,
  PLACE_DETAIL_PATH_LEGACY,
} from './utils/constants';
import AddView from './views/AddView';
import DetailsView from './views/DetailsView';
import DirectoryView from './views/DirectoryView';
import { ResetView } from './views/Password';
import SearchView from './views/SearchView';

const Routes = () => {
  return (
    <Switch>
      <Route path={directoryPaths}>
        <DirectoryView />
      </Route>
      <Route path={addPaths}>
        <AddView />
      </Route>
      <Redirect exact from={PLACE_DETAIL_PATH_LEGACY} to="/details/:id/local" />
      <Route path={detailsPaths}>
        <DetailsView />
      </Route>
      <Route path={passwordPaths}>
        <ResetView />
      </Route>
      <Route path="/">
        <SearchView />
      </Route>
    </Switch>
  );
};

export default Routes;

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Avatar, ImagePicker } from '../components';
import DialogForm from '../components/DialogForm';
import { accountActions } from '../store/actions';

const useStyles = makeStyles((theme) => ({
  note1: {
    marginTop: 10,
  },
  note2: {
    marginTop: 15,
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    flexDirection: 'column',
  },
}));

const validationSchema = (t) => {
  return yup.object().shape({
    username: yup
      .string()
      .required(t('messages.requiredField'))
      .min(6, t('messages.usernameFormatError')),
  });
};

const EditProfileDialog = (props) => {
  const { scrollContentToTop } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const username = useSelector((state) => state.auth.username);
  const usernameUpdatedAt = useSelector(
    (state) => state.auth.usernameUpdatedAt
  );
  const avatar = useSelector((state) => state.auth.avatar);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [isEditing, setEditing] = useState(false);
  const [isUsernameUpdateable, setIsUsernameUpdateable] = useState(false);
  const { t } = useTranslation();
  const { register, handleSubmit, getValues, control, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema(t)),
    submitFocusError: true,
  });
  const errorMessage = useSelector((state) => state.auth.errorMessage);

  useEffect(() => {
    // is after 90 day period?
    const nowStr = dayjs(new Date()).format('YYYY-MM-DD');
    // 90 days subtract 1 day as to include the change date
    const laterStr = dayjs(usernameUpdatedAt)
      .add(89, 'day')
      .format('YYYY-MM-DD');
    // usernameUpdatedAt will be null if we've assigned a default username
    const isUpdateable = !usernameUpdatedAt || dayjs(nowStr).isAfter(laterStr);
    setIsUsernameUpdateable(isUpdateable);
  }, [usernameUpdatedAt]);

  const handleAvatarClick = () => {
    setEditing(true);
  };

  const onSave = (editor) => (event) => {
    const rawImageURL = editor.current.getImageScaledToCanvas().toDataURL();
    fetch(rawImageURL)
      .then((res) => res.blob())
      .then((blob) => {
        const uri = window.URL.createObjectURL(blob);
        const type = blob.type;
        const filename = `${uri.substring(
          uri.lastIndexOf('/') + 1
        )}.${type.substring(type.lastIndexOf('/') + 1)}`;
        dispatch(accountActions.uploadAvatar(filename, blob));
        setEditing(false);
      });
  };

  const onCancel = () => {
    setEditing(false);
  };

  const onSubmit = () => {
    !isLoading && dispatch(accountActions.editProfile(getValues()));
  };

  return (
    <DialogForm
      onSubmit={handleSubmit(onSubmit)}
      errorMessage={errorMessage}
      scrollContentToTop={scrollContentToTop}
    >
      <div className={classes.avatarContainer}>
        {!isEditing ? (
          <Avatar
            onClick={handleAvatarClick}
            source={avatar}
            loading={isLoading}
          />
        ) : (
          <ImagePicker variant={'avatar'} onSave={onSave} onCancel={onCancel} />
        )}
      </div>

      <Controller
        as={TextField}
        control={control}
        label={t('messages.username')}
        name="username"
        margin="dense"
        error={!!errors.username}
        helperText={errors.username?.message}
        inputRef={register}
        fullWidth
        disabled={!isUsernameUpdateable}
        defaultValue={username || ''}
      />

      <Typography className={classes.note1}>
        {t('messages.ninetyDayNotice')}
      </Typography>
      <Typography className={classes.note2}>
        {t('messages.firstWarn')}
      </Typography>
      {isUsernameUpdateable && (
        <Box mt={2}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth
          >
            {t('messages.save')}
          </Button>
        </Box>
      )}
    </DialogForm>
  );
};

export default EditProfileDialog;

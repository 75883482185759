import { HttpService } from './http-base.service';

export default class AuthService extends HttpService {
  constructor(baseURL) {
    if (!AuthService.instance) {
      super(baseURL);
      AuthService.instance = this;
    }

    return AuthService.instance;
  }

  login(data) {
    return this.post('/auth/local', data)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }

  logout() {
    return this.get('/auth/logout')
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  }
}

import { produce } from 'immer';
import React, { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';

const GalleryContext = createContext([null, () => null]);

const reducer = produce((draft, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'OPEN_WITH':
      draft.isVisible = true;
      draft.images = payload.images;
      draft.imageFocus = payload.imageFocus;
      return;
    case 'SET_FOCUS':
      draft.imageFocus = payload;
      return;
    case 'CLOSE':
      draft.isVisible = false;
      draft.images = [];
      draft.imageFocus = null;
      return;
  }
});

const initialState = {
  isVisible: false,
  images: [],
  imageFocus: null,
};

export const GalleryProvider = (props) => {
  const [galleryState, galleryDispatch] = useImmerReducer(
    reducer,
    initialState
  );

  return (
    <GalleryContext.Provider value={{ galleryState, galleryDispatch }}>
      {props.children}
    </GalleryContext.Provider>
  );
};

export const useGalleryContext = () => useContext(GalleryContext);

import { serverErrorsl10n } from '../../l10n/common';
import { api } from '../../services';
import { mapActions } from '.';

export const getStats = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'GET_STATS_IS_LOADING',
      payload: {
        isLoading: true,
        errorMessage: '',
      },
    });

    try {
      const response = await api.placeService.stats();
      const {
        map: { selectedPlaceDetails },
      } = getState();

      dispatch({
        type: 'GET_STATS_SUCCESS',
        payload: {
          accessiblePlaceCount: response.accessible,
          partiallyAccessiblePlaceCount: response.partiallyAccessible,
          notAccessiblePlaceCount: response.notAccessible,
          totalPlaceCount: response.total,
        },
      });
      if (selectedPlaceDetails.latitude)
        dispatch(mapActions.resetPlaceDetails());
    } catch (error) {
      const errorMessage =
        serverErrorsl10n.en[error.message] || serverErrorsl10n.en.GENERIC_ERROR;

      dispatch({
        type: 'GET_STATS_FAILURE',
        payload: {
          isLoading: false,
          errorMessage,
        },
      });
    }
  };
};

export default {
  50: '#e2f5fc',
  100: '#b5e6f8',
  200: '#86d5f3',
  300: '#5bc5ee',
  400: '#3eb8eb',
  500: '#2aace8',
  600: '#259eda',
  700: '#1e8bc7',
  800: '#1b7ab3',
  900: '#135a91',
  A100: '#86d5f3',
  A200: '#5bc5ee',
  A400: '#2aace8',
  A700: '#1b7ab3',
};

import {
  Avatar as MuiAvatar,
  Badge,
  CircularProgress,
} from '@material-ui/core';
import { CameraAlt as CameraIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({
  clickable: {
    cursor: 'pointer',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 140,
    height: 140,
  },
  badge: {
    color: 'white',
    borderColor: 'white',
    borderWidth: 4,
    borderRadius: 60,
    borderStyle: 'solid',
    width: 60,
    height: 60,
    padding: 6,
    backgroundColor: '#259EDA',
  },
});

const Avatar = ({ onClick, containerStyle, source, loading }) => {
  // source comes through as a number if it's from our asset library
  const image =
    typeof source === 'number'
      ? source
      : typeof source === 'string'
      ? source
      : '/images/avatar.svg';

  const classes = useStyles();

  return loading ? (
    <div className={`${classes.avatar} ${containerStyle}`}>
      <CircularProgress
        color="primary"
        aria-describedby="loading avatar"
        aria-busy="true"
      />
    </div>
  ) : (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={
        <CameraIcon
          onClick={onClick}
          className={`${classes.clickable} ${classes.badge}`}
        />
      }
    >
      <MuiAvatar
        variant="circular"
        className={`${classes.avatar} ${containerStyle}`}
        sizes=""
        alt="avatar"
        src={image}
      />
    </Badge>
  );
};

export default Avatar;
